import React from "react";
import "./style.scss";

const OrgHeader = ({ heading, onOrgTypeSelect, hideActions, children }) => {
  return (
    <div className="org-header">
      <div className="org-header__heading">{heading}</div>
      {!hideActions && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

export default OrgHeader;
