import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Helmet } from "react-helmet";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Form/Input/Input";
import { email, required } from "./../../../helpers/form-validations";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";
import { PAGE_TITLES } from "../../../constants/Titles";
import LfLogo from "./../../../assets/images/image/ic-header-logo.png";
import ImgArrowBack from "./../../..//assets/images/navigation/ic-arrow-back.svg"
import "./ForgotPassword.scss";

const formName = "ForgotPassword";

class ForgotPassword extends Component {

  componentWillMount() {
    this.props.resetForgotPassword();
  }

  renderSuccessContent() {
    const { email } = this.props.authForgotPassword;

    return <>
      <div className="header">
        <h1>Thank You!</h1>
        <div className="desc f-color-faded">
          If an account is associated with <span className="f-w-bold f-color-black">{email}</span>,
          you will receive an email with instructions to reset your password.
        </div>
      </div>
      <footer>
        Powered by <img src={LfLogo} alt="Locofast" />
      </footer>
    </>;
  }

  renderFormContent() {
    const {
      handleSubmit,
      pristine,
      onSubmit: submitForgotPassword,
      submitting,
      authForgotPassword: {
        inProgress,
        error
      }
    } = this.props;

    return <>
      <div className="header">
        <div className="heading">Recover password</div>
        <div className="desc f-color-faded">
          Enter your registered email id to receive instructions to reset your password.
        </div>
      </div>

      <form onSubmit={handleSubmit(submitForgotPassword)} disabled={inProgress}>
        <Field
          name="email"
          id="email"
          component={Input}
          validate={[email, required]}
          type="text"
          label="Email"
          placeholder="Enter registered email id"
        />
        <Button
          category="large"
          disabled={pristine || submitting}>
          Continue
        </Button>
        {
          error ? <div className="error">{error}</div> : null
        }
        <footer>
          Powered by <img src={LfLogo} alt="Locofast" />
        </footer>
      </form>
    </>;
  }

  render() {
    const {
      authForgotPassword: {
        success
      }
    } = this.props;
    const userRole = getCurrentUserRole();

    // if there is already someone logged in, redirect to the main landing page
    // instead of showing the forgot password form
    if (userRole) {
      return <Redirect to="/" />;
    }

    return (
      <div className="forgot-pwd">
        <Helmet
          bodyAttributes={{
            class: 'body_container'
          }}
          title={PAGE_TITLES.forgotPassword()}
        />
        <div className="wrapper">
          <Link to="/auth/login">
            <div className="navigation">
              <img src={ImgArrowBack} alt="" />
              <span>Login</span>
            </div>
          </Link>
          <div className="content">
            {
              success
                ? this.renderSuccessContent()
                : this.renderFormContent()
            }
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: formName
})(ForgotPassword);
