import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/UI/PrivateRoute/PrivateRoute";
import AuthContainer from "./containers/AuthContainer/AuthContainer";
import FlashMessageContainer from "./containers/FlashMessageContainer/FlashMessageContainer";
import UploadProgressContainer from "./containers/UploadProgressContainer/UploadProgressContainer";
import ModalContainer from "./containers/ModalContainer/ModalContainer";
import LoaderContainer from "./containers/LoaderContainer/LoaderContainer";
import FactoryDashboardContainer from "./containers/FactoryDashboardContainer/FactoryDashboardContainer";
import ReactTooltip from "react-tooltip";
import { getCurrentUserRole } from "./helpers/AuthHelper";
import {
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  BDE,
  FABRIC_SUPPLIER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT,
  ACCOUNT_MANAGER,
  SUPPLIER_MANAGER,
} from "./constants/Auth";
import PerfectScrollbar from "react-perfect-scrollbar";
import SessionerContainer from "containers/SessionerContainer";
// import history from "utilites/history";

const isPathMissingTrailingSlash = () =>
  !window.location.pathname.endsWith("/");

const Routes = () => {
  // Fix for react tooltip
  // https://github.com/wwayne/react-tooltip/issues/40#issuecomment-147552438
  setTimeout(() => {
    ReactTooltip.rebuild();
  }, 1500);

  // const isSessioner = history?.location?.pathname?.includes('sessioner') || false;
  const isSessioner = false;

  return (
    <PerfectScrollbar>
      <div className={`lf-application-container ${isSessioner ? 'sessionClass' : ''}`}>
        <FlashMessageContainer />
        <UploadProgressContainer />
        {
          // https://www.npmjs.com/package/react-tooltip
        }
        <ReactTooltip
          className="custom-tooltip"
          place="bottom"
          type="dark"
          effect="solid"
        />
        <Switch>
          {isPathMissingTrailingSlash() ? (
            <Route
              path="/:url*"
              exact
              strict
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `${props.location.pathname}/`,
                    state: props.location.state,
                  }}
                />
              )}
            />
          ) : null}
          <Route
            // path="/(login|signup|forgotPassword|signupSuccess|forgotPasswordSuccess|logout)/"
            path="/auth/"
            render={(routeProps) => {
              return <AuthContainer {...routeProps} />;
            }}
          />
            <Route
              path="/sessioner/"
              render={(routeProps) => <SessionerContainer {...routeProps} />}
              // exact
            />
          <PrivateRoute
            path="/factory/"
            render={(routeProps) => {
              let userRole = getCurrentUserRole();
              return userRole === LOCO_SUPER ||
                userRole === FACTORY_ADMIN ||
                userRole === FACTORY_MANAGER ||
                userRole === ACCOUNT_MANAGER ||
                userRole === SUPPLIER_MANAGER ? (
                <FactoryDashboardContainer />
              ) : (
                <Redirect to="/brand/collections/" />
              );
            }}
          />
          <Redirect to="/auth/login" />
        </Switch>
        <ModalContainer />
        <LoaderContainer />
      </div>
    </PerfectScrollbar>
  );
};

export default Routes;
