import React from "react";
import { connect } from "react-redux";
import "./LoaderContainer.scss";

const LoaderContainer = ({ isLoading }) => {
  return (
    <div className={`loader__container ${isLoading ? "" : "hide"}`}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
        className="lds-ring"
      >
        <div />
        <div />
        <div />
        <div />
      </div>
      {
        // <div
        //   style={{
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)"
        //   }}
        //   className="lds-roller"
        // >
        //   <div />
        //   <div />
        //   <div />
        //   <div />
        //   <div />
        //   <div />
        //   <div />
        //   <div />
        // </div>
      }
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.global.loader.isLoading
});

export default connect(
  mapStateToProps,
  null
)(LoaderContainer);
