import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";
import TopQualitiesForm from "./TopQualitiesForm.jsx";
import chevron from "assets/images/fabricQualities/chevron.svg";

import { columnsData, initialValues, transformToForm } from "./base";
import style from "./style.module.scss";
import {
  createNewTopQuality,
  getMaterialData,
  getTopSellingTableData,
  updateTopQuality,
} from "store/actions/FabricQualitiesActions.js";
import { connect } from "react-redux";

const TopQualities = ({
  getMaterialDataApi,
  createNewTopQualityForm,
  updateTopQualityForm,
  getTableData,
  tableData,
  disabledTableData,
  materialData,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [formData, setFormData] = useState({ ...initialValues });
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [disabledPage, setDisabledPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const reloader = () => {
    setLoader(!loader);
  };
  useEffect(() => {
    getMaterialDataApi();
  }, []);

  useEffect(() => {
    let params = {
      pageNumber: page,
      limit: 10,
      isDisable: 0,
    };
    getTableData(params);
  }, [page, loader]);

  useEffect(() => {
    let params = {
      pageNumber: disabledPage,
      limit: 10,
      isDisable: 1,
    };
    getTableData(params);
  }, [disabledPage, loader]);

  const edit = (record) => {
    const form = transformToForm(record);
    setFormData(form);
    setIsUpdate(true);
    setIsFormVisible(true);
  };

  return (
    <div className={style.page}>
      <div className={style.head}>
        <div className={style.headline}>Fabric qualities</div>
        <button
          className="btn btn--blue-bg"
          onClick={() => {
            setFormData({ ...initialValues });
            setIsUpdate(false);
            setIsFormVisible(true);
          }}
        >
          Add Top Qualities
        </button>
      </div>
      <div>
        <Table
          columns={columnsData(materialData, edit, reloader, updateTopQualityForm)}
          dataSource={tableData.records}
          pagination={{
            position: "bottom",
            defaultPageSize: 10,
            total: tableData?.paginate?.totalRecords,
            size: "small",
            showSizeChanger: false,
            onChange: (page) => {
              setPage(Number(page));
            },
          }}
          title={() => {
            return <div className={style.tableHeading}>Top qualities</div>;
          }}
        />
      </div>
      <div
        className={style.tableHeading}
        style={{ paddingBottom: "20px", paddingTop: "20px" }}
      >
        Disabled qualities
        <img
          src={chevron}
          alt="disabled qualities"
          className={style.chevron}
          onClick={() => setIsTable(!isTable)}
          style={{ transform: !isTable ? "rotate(180deg)" : null }}
        />
      </div>
      {isTable && (
        <div>
          <Table
            columns={columnsData(materialData, edit, reloader, updateTopQualityForm)}
            dataSource={disabledTableData.records}
            pagination={{
              position: "bottom",
              defaultPageSize: 10,
              total: disabledTableData?.paginate?.totalRecords,
              size: "small",
              showSizeChanger: false,
              onChange: (page) => {
                setDisabledPage(Number(page));
              },
            }}
            className={style.disabled}
          />
        </div>
      )}
      <div>
        <TopQualitiesForm
          isUpdate={isUpdate}
          initialFormData={formData}
          visible={isFormVisible}
          onClose={() => {
            setIsFormVisible(false);
          }}
          reload={reloader}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  tableData: state.fabricQualities.tableData,
  disabledTableData: state.fabricQualities.disabledTableData,
  materialData: state.fabricQualities.materialData,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialDataApi: () => dispatch(getMaterialData()),
  createNewTopQualityForm: () => dispatch(createNewTopQuality()),
  updateTopQualityForm: (data) => dispatch(updateTopQuality(data)),
  getTableData: (data) => dispatch(getTopSellingTableData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopQualities);
