import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { required, phoneNumber } from "./../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";

const formName = "UpdateUserPhoneForm";

class UpdateUserPhoneForm extends Component {
  render() {
    const {
      handleSubmit
    } = this.props;

    return (
      <div className="flex-col">
        <Form name={formName} id={formName} onSubmit={handleSubmit}>
          <Field
            component={Input}
            validate={[required, phoneNumber]}
            type="text"
            name="phone"
            label="Mobile number"
            placeholder="Mobile number"
            prefixText="+91"
            maxLength={10}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm({ form: formName }, mapStateToProps)(
  UpdateUserPhoneForm
);
