import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import qs from 'query-string';
import SuccessfullSignup from "../../components/Authentication/Signup/SuccessfullSignup";
import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import { CLIENT_SUPER, LOCO_SUPER, CLIENT, FABRIC_SUPPLIER, BDE, PRODUCTION_MERCHANT, PRODUCT_DEVELOPMENT, FACTORY_MANAGER, FACTORY_ADMIN } from "../../constants/Auth";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import actions from "../../store/actions";
import ForgotPassword from "./../../components/Authentication/ForgotPassword/ForgotPassword";
import CreatePassword from "./../../components/Authentication/CreatePassword/CreatePassword";
import Signup from "./../../components/Authentication/Signup/Signup";
import CustomLogin from "./../../components/Authentication/CustomLogin/CustomLogin";
import { isNotCustomHost, getHostBrand } from "./../../helpers/AuthHelper";
import "./AuthContainer.scss";

class AuthContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    signupClientSuper: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    createPassword: PropTypes.func.isRequired
  };

  computeRoute = (routeProps, hostBrand) => {
    const { history, userDetails } = this.props;

    // if user is logged in and redirect query param is present
    // then, redirect the user to that page
    if (userDetails && userDetails.id) {
      const queryParams = qs.parse(history.location.search);
      if (queryParams.redirect) {
        return <Redirect to={`${queryParams.redirect}${history.location.hash}`} />;
      }
    }

    switch (getCurrentUserRole()) {
      case FABRIC_SUPPLIER: return <Redirect to="/supply/swatchLibrary" />
      case BDE: return <Redirect to="/sales/swatchLibrary" />
      case LOCO_SUPER:
      case FACTORY_ADMIN:
      case FACTORY_MANAGER:
      return <Redirect to="/factory/settings/customers" />
      case CLIENT_SUPER:
      case CLIENT: return <Redirect to="/brand/" />
      case PRODUCT_DEVELOPMENT: return <Redirect to="/manager/brands" />
      case PRODUCTION_MERCHANT: return <Redirect to="/merchant/brands" />
      default: {
        return <CustomLogin
          {...routeProps} onSubmit={this.props.login}
          hostBrand={hostBrand}
        />;
      }
    }
  }

  render() {
    const { match,
      login,
      userDetails,
      authForgotPassword,
      authCreatePassword,
      authValidateCreatePasswordToken
    } = this.props;

    const hostBrand = getHostBrand();

    return (
      <div className="auth flex-col">
        <div className="auth__main flex">
          <div className="flex">
            <Route
              path={`${match.url}/login`}
              render={routeProps => {
                return this.computeRoute(routeProps, hostBrand);
              }}
            />
            <PrivateRoute
              path={`${match.url}/brands`}
              allowRoles={[LOCO_SUPER]}
              render={routeProps => <Signup {...routeProps} onSubmit={this.props.createNewBrand} />}
            />
            <Route
              path={`${match.url}/signupSuccess`}
              render={routeProps => <SuccessfullSignup {...routeProps} />}
            />
            <Route
              path={`${match.url}/forgotPassword`}
              render={routeProps => <ForgotPassword
                {...routeProps}
                authForgotPassword={authForgotPassword}
                onSubmit={this.props.forgotPassword}
                resetForgotPassword={this.props.resetForgotPassword}
                hostBrand={hostBrand} />}
            />
            <Route
              path={`${match.url}/createPassword`}
              render={routeProps => <CreatePassword
                {...routeProps}
                hostBrand={hostBrand}
                authLogin={login}
                userDetails={userDetails}
                authCreatePassword={authCreatePassword}
                authValidateCreatePasswordToken={authValidateCreatePasswordToken}
                validateCreatePasswordToken={this.props.validateCreatePasswordToken}
                onSubmit={this.props.createPassword}
              />}
            />
            <Route
              path={`${match.url}/logout`}
              render={() => {
                // fire logout request only if user is logged in
                if (userDetails && userDetails.id) {
                  this.props.logout();
                  return null;
                } else {
                  return <Redirect to={`${match.url}/login/`} />
                }
              }}
            />
          </div>
        </div>
      </div>
    );

  }
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails,
  authCreatePassword: state.auth.createPassword,
  authForgotPassword: state.auth.forgotPassword,
  authValidateCreatePasswordToken: state.auth.validateCreatePasswordToken,
});

const mapDispatchToProps = dispatch => ({
  login: creds => dispatch(actions.login(creds)),
  signup: creds => dispatch(actions.signup(creds)),
  logout: creds => dispatch(actions.logout(creds)),
  // HACK: To make new SUPER_LOCO users temporarily add the following function to <SignUp>
  // signupSuperLocoUser: creds => dispatch(actions.signupSuperLocoUser(creds)),
  signupClientSuper: creds => dispatch(actions.signupClientSuper(creds)),
  forgotPassword: data => dispatch(actions.forgotPassword(data)),
  resetForgotPassword: () => dispatch(actions.resetForgotPassword()),
  validateCreatePasswordToken: data => dispatch(actions.validateCreatePasswordToken(data)),
  createPassword: data => dispatch(actions.createPassword(data))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContainer));
