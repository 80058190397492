import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import Button from 'components/UI/Button/Button';
import {
  createNewLogiPartner as createNewLogisticPartner,
  updateLogiPartner as updateLogisticPartner,
} from '../../../../store/actions/LogisticActions';
import { STATUS_OPTIONS } from '../../../../constants/Quote';
import './PartnerDetailsModal.scss';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  baseUrl: Yup.string().nullable(),
  trackingIdConstraints: Yup.string().optional(),
  isActive: Yup.string().optional(),
});

const WithInput = ({
  label,
  value = '',
  type = 'text',
  onChange,
  id,
  isError,
  required,
  ...restProps
}) => (
  <Field name={id}>
    {({ field }) => (
      <div className='inputField'>
        <label className='label__class'>
          {label} {required ? <sup>*</sup> : null}
        </label>
        <input data-invalid={isError} {...restProps} type={type} {...field} />
      </div>
    )}
  </Field>
);

const SelectStatus = ({ label, required = false, isError }) => {
  return (
    <div className='inputField flex_column'>
      <label className='label__class'>
        {label} {required ? <sup>*</sup> : null}
      </label>
      <Field name='isActive' component='select' id='isActive' isError={isError}>
        {STATUS_OPTIONS.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Field>
    </div>
  );
};

const PartnerDetailsModal = ({
  initialValues,
  createNewLogiPartner,
  updateLogiPartner,
}) => {
  return (
    <div className='addPartner'>
      <Formik
        initialValues={initialValues || { isActive: 1 }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          initialValues?.id
            ? updateLogiPartner(values)
            : createNewLogiPartner(values);
        }}
      >
        {({ handleSubmit, touched, errors, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <PerfectScrollbar>
              <div className='formContent'>
                <WithInput
                  label='Courier partner name'
                  id='name'
                  required
                  disabled={initialValues?.id ? true : false}
                  isError={touched.name && errors.name}
                  placeholder='Eg. RCPL ltd'
                />
                <WithInput
                  label='Base URL'
                  id='baseUrl'
                  placeholder='E.g. www.rcpl.com/trackingId=123'
                  isError={touched.baseUrl && errors.baseUrl}
                />
                <SelectStatus
                  label='Status'
                  isError={touched.isActive && errors.isActive}
                />
                <WithInput
                  label='Tracking Id Constraints'
                  id='trackingIdConstraint'
                  isError={
                    touched.trackingIdConstraints &&
                    errors.trackingIdConstraints
                  }
                  placeholder='E.g. $[0-9]{9}'
                />
              </div>
            </PerfectScrollbar>

            <div className='modal__footer modal__footer-lfusers'>
              <Button
                category='blue-bg'
                role='submit'
                disabled={!dirty || Object.keys(errors).length !== 0}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createNewLogiPartner: (data) => dispatch(createNewLogisticPartner(data)),
  updateLogiPartner: (data) => dispatch(updateLogisticPartner(data)),
});

export default connect(null, mapDispatchToProps)(PartnerDetailsModal);
