import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "./Header.scss";

import Logo from "assets/images/navigation/header-logo.svg";
import ChevronImg from "assets/images/navigation/ic-chevron-right.svg";

import { BrandImage } from "../../UI/BrandImage";
import { getCurrentUserRole } from "helpers/AuthHelper";
import {
  CLIENT,
  CLIENT_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  LOCO_SUPER,
  FABRIC_SUPPLIER,
  BDE,
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT
} from "constants/Auth";
import { searchAll } from "store/actions/CommonActions";
import _ from "lodash";
import Popover from "components/UI/PopOver/PopOver";
import { computePath, getHeaderText } from "helpers/pathHelper";
import { ProfileAvatar } from "../ProfileAvatar";
import PrivateRoute from '../PrivateContainer/PrivateContainer';

let isClicked = false;

let results = {
  design: {
    data: []
  },
  collection: {
    data: []
  },
};

class Header extends Component {
  static defaultProps = {
    theme: "default"
  };

  searchTypes = [
    { label: "All", value: "all" },
    { label: "Collections", value: "collection" },
    { label: "Designs", value: "design" }
  ];

  state = {
    isClickedOnNotification: false,
    showResults: false,
    selectedSearchType: "all",
    searchQuery: "",
    results: results,
    isPopoverOpen: false
  };

  constructor(props) {
    super(props);
    // TODO : Add Brand Filter Once changes are made in backend
    if (
      getCurrentUserRole() !== CLIENT &&
      getCurrentUserRole() !== CLIENT_SUPER
    ) {
      this.searchTypes.push({ label: "Brands", value: "brand" });
    }
  }

  clickedOnNotificationHandler = () => {
    if (this.state.isClickedOnNotification) return;

    this.setState({ isClickedOnNotification: true });
  };

  onChangeSearchType = (category, shouldReload) => {
    const { showResults } = this.state;
    this.setState({ selectedSearchType: category.value }, () => {
      if (shouldReload || showResults) this.loadSearchResults();
    });
  };

  onChangeSearchQuery = event => {
    let query = event ? event.currentTarget.value : "";
    const showResults = query && query !== "";
    this.setState({ showResults, searchQuery: query }, () => {
      if (showResults) this.loadSearchResults();
    });
  };

  loadSearchResults = () => {
    const { searchAll } = this.props;
    const { searchQuery, selectedSearchType } = this.state;
    searchAll(selectedSearchType, searchQuery).then(res => {
      this.setState({ results: res });
    });
  };

  handleResultClick = (category, item) => {
    const { history, setCurrentBrand } = this.props;
    this.setState({ showResults: false });
    let brandId = category === "brand" ? item.key : item.brand_id;
    const pathRole = computePath();
    let prefixUrl =
      pathRole == "brand" ? `/brand` : `/${pathRole}/brands/${brandId}`;
    if (category !== "brand") {
      setCurrentBrand({ brandId: item.brand_id });
    } else {
      setCurrentBrand({ brandId: item.key });
    }
    if (category == "design" || category == "product") {
      history.push(
        `${prefixUrl}/collections/${item.collection_id}/designs/${
        item.key
        }/?tab=summary`
      );
    }
    if (category == "collection") {
      history.push(`${prefixUrl}/collections/${item.key}/?tab=allDesigns`);
    }
    if (category == "file") {
      history.push(
        `${prefixUrl}/collections/${item.collection_id}/designs/${
        item.design_id
        }/?tab=files`
      );
    }
    if (category == "brand") {
      history.push(`${prefixUrl}/collections`);
    }
  };

  togglePopOverOpen = () => {
    this.setState(state => ({
      isPopoverOpen: !state.isPopoverOpen
    }));
  };

  render() {
    const {
      showResults,
      selectedSearchType,
      results,
      searchQuery,
      isPopoverOpen
    } = this.state;

    const {
      selectedBrand,
      notifications,
      userDetails = {},
      isFactory,
      theme,
      dontShowInLogin,
      noBellIcon,
      noSearchBar,
      location: { pathname }
    } = this.props;

    const { image, name, email, brandId } = userDetails || {};
    let notificationsCount = 0;
    if (notifications) {
      notificationsCount = _.filter(notifications, ({ isRead }) => !isRead)
        .length;
    }

    const lfUsers = [
      LOCO_SUPER,
      FACTORY_ADMIN,
      FACTORY_MANAGER,
      FABRIC_SUPPLIER,
      BDE,
      PRODUCT_DEVELOPMENT,
      PRODUCTION_MERCHANT
    ];
    const isLocofastUser = userDetails && lfUsers.includes(userDetails.role);
    const headerText = getHeaderText(pathname);
    let subHeading =
      headerText === "Collections" &&
      this.props.selectedUserCollection &&
      this.props.selectedUserCollection.name;

    if(pathname.includes('/orders/new')) {
      subHeading = "Create Order";
    }

    return (
      <div className={"header__wrapper flex header__wrapper--" + theme}>
        <div className="header__logo flex">
          <Link to="/" className="header__img">
            <img src={Logo} alt="logo" className="header__img" />
          </Link>
        </div>

        <div className="header__text">
          <div className="title">
            <div className="title__heading">{headerText}</div>
            {subHeading && (
              <div className="title__subheading">
                <img src={ChevronImg} alt="chevron right" />
                {subHeading}
              </div>
            )}
          </div>
          <div className="header_container">
            {(userDetails.isBrand || userDetails.isFactory) &&
              <BrandImage
                id={userDetails.brandId}
                name={userDetails.brand}
                initialLength={2}
                borderRadius="4px"
                showCompleteName={userDetails.isFactory}
              />}
            {userDetails && (
              <div className="popover-body">
                <Popover
                  isOpen={isPopoverOpen}
                  right="0px"
                  top="45px"
                  disableReposition={false}
                  containerStyle={{
                    left: "123px",
                    right: "16px"
                  }}
                  visible={false}
                  content={
                    <div className="profile-popover">
                      <div className="header__user_details">
                        <ProfileAvatar
                          name={name}
                          url={image}
                          size={56}
                        />
                        <div className="user-data">
                          <p className="name">{name}</p>
                          <p className="email">{email}</p>
                        </div>
                      </div>
                      <Link className="logout-btn" to={`${this.props.match.path}settings/profile`}>
                        <span className="f-sz-sm logout-text">Account Settings</span>
                      </Link>
                      <Link className="logout-btn" to="/auth/logout">
                        <span className="f-sz-sm logout-text">Logout</span>
                      </Link>
                    </div>
                  }
                >
                  <ProfileAvatar
                    name={name}
                    url={image}
                  />
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails,
});

export default withRouter(
  connect(
    mapStateToProps,
    { searchAll }
  )(Header)
);
