import React, { Component } from "react";
import { Form, reduxForm } from "redux-form";
import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";

class ReduxFormWrapper extends Component {
  // Add hidden fields
  updateHiddenFields(hiddenFields) {
    hiddenFields.forEach(f => this.props.change(f.name, f.value));
  }
  componentDidMount() {
    if (this.props["hiddenFields"]) this.updateHiddenFields(this.props["hiddenFields"]);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props["hiddenFields"] && !_.isEqual(nextProps["hiddenFields"], this.props["hiddenFields"])) {
      this.updateHiddenFields(nextProps["hiddenFields"]);
    }
  }
  render() {
    const { handleSubmit, change, hiddenFields, form, style, className } = this.props;
    // Add hidden fields
    // if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <PerfectScrollbar
        className={className}
        option={
          this.props.noScroll
            ? {
                suppressScrollX: true,
                suppressScrollY: true
              }
            : {
                suppressScrollX: true
              }
        }
        // className="new-design flex-col"
      >
        <Form
          name={form}
          id={form}
          onSubmit={handleSubmit(values => {
            this.props.onSubmit(values);
          })}
          style={style || {}}
        >
          {this.props.children({
            formName: form,
            changeFormValue: change,
            ...this.props
          })}
        </Form>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm(
  {
    enableReinitialize: true
  },
  mapStateToProps
)(ReduxFormWrapper);
