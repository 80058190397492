import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'normalize.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/scss/global.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import Routes from './routes';
import NetworkService from './NetworkService';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './containers/ErrorBoundaryContainer/ErrorBoundaryContainer';
import history from './utilites/history';
import store from './store/store';
NetworkService.setupInterceptors(store);
window.appHistory = history;
window.locofastGlobals = {
  files: [],
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Router history={history}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Router>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
