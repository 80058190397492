export const KNITS = 1;
export const WOVEN = 2;
export const STATUS_OPTIONS = [
  { label: 'Verified', value: 'verified' },
  { label: 'Unverified', value: 'unverified' },
  { label: 'Deactivated', value: 'deactivated' },
];
export const FABRIC_TYPES = [
  { label: 'Knits', value: '1' },
  { label: 'Woven', value: '2' },
];
export const REQUIREMENT_TYPES = [
  { label: 'Greige', value: '1' },
  { label: 'Yarn Dyed', value: '2' },
  { label: 'Dyed', value: '4' },
  { label: 'Printed', value: '5' },
  { label: 'RFD', value: '3' },
];
export const CATEGORY = [
  { label: 'Cotton', value: '1' },
  { label: 'Rayon', value: '2' },
  { label: 'Polyester', value: '3' },
  { label: 'Denim', value: '57' },
  { label: 'Nylon', value: '58' },
  { label: 'Sustainable', value: '59' },
  { label: 'Linen', value: '60' },
];
export const CATEGORY_TYPES = {
  Cotton: "1",
  Rayon: "2",
};