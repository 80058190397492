import React, { Component } from "react";
import UpdateUserPhoneForm from "./UpdateUserPhoneForm";

export default class UpdateUserPhoneModal extends Component {
  render() {
    const { onSubmit, initialValues } = this.props;
    return (
      <div className="update-user-phone-modal">
        <div className="container">
          <UpdateUserPhoneForm onSubmit={onSubmit} initialValues={initialValues} />
        </div>
      </div>
    );
  }
}
