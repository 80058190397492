import { Field } from 'formik';
import React from 'react';
import Status from '../status';
import style from '../RadioField/style.module.scss';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const RadioButtons = ({ name, label, isRequired = false, options, onChange }) => {
  return (
    <Field name={name}>
      {({ field, meta: { error, touched }, form: { setFieldValue } }) => {
        return (
          <div className={style['input-wrapper']}>
            {label ? (
              <p className={style.label}>
                <span>{label}</span>
                {isRequired && <Status isFilled={!!field?.value} />}
              </p>
            ) : null}
            <Radio.Group
              className={style['radio-buttons']}
              onChange={(e) => onChange(e)}
              value={field.value}>
              {options.map(({ label, value }) => {
                return (
                  <Radio className={style['radio']} value={value}>
                    {label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        );
      }}
    </Field>
  );
};
RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

export default RadioButtons;
