const apiEndpoint = process.env.REACT_APP_API_URL || 'https://app.locofast.in';
const fabricQualitiesApi = process.env.REACT_APP_RECOMMENDATION_API_URL;

module.exports = {
  SERVICE_ENDPOINTS: {
    auth            : `${apiEndpoint}/apiuser`,
    fabric          : `${apiEndpoint}/apifabric`,
    finance         : `${apiEndpoint}/apifinance`,
    collection      : `${apiEndpoint}/apibrand`,
    designs         : `${apiEndpoint}/apiproduct`,
    notificationView: `${apiEndpoint}/api`,
    apiv2           : `${apiEndpoint}/api`,
    swatchLibrary   : `${apiEndpoint}/api/swatch`,
    link            : `${apiEndpoint}/`,
    search          : `${apiEndpoint}/apisearch`,
    fabricQualities : `${fabricQualitiesApi}/product-catalog`,
    runningQualities : `${fabricQualitiesApi}/product/running-quality`,
    liveRateQualities : `${fabricQualitiesApi}/product/live-rate-quality`,
  },
  LOCAL_SERVICE_ENDPOINTS: {
    auth            : `${apiEndpoint}:8080`,
    collection      : `${apiEndpoint}:8081`,
    designs         : `${apiEndpoint}:8082`,
    notificationView: `${apiEndpoint}:1337`,
    apiv2           : `${apiEndpoint}:1337`,
    swatchLibrary   : `${apiEndpoint}:1337/swatch`,
    link            : `${apiEndpoint}/`,
    search          : `${apiEndpoint}:8083`
   }
}
