import React from "react";
import { Row, Col, Input, Divider } from "antd";
import "./styles.scss";
const AddressTemplate = ({ address, customer }) => {
  const {
    addressLine1,
    addressLine2,
    landmark,
    pinCode,
    city,
    state,
    consigneePhone,
    consigneeFirstName = '',
    consigneeLastName = '',
  } = address || {};
  const {
    addressLine1: customerAddressLine1,
    addressLine2: customerAddressLine2,
    landmark: customerLandmark,
    pinCode: customerPinCode,
    city: customerCity,
    state: customerState,
  } = customer || {};
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={addressLine1 || customerAddressLine1}
              placeholder="Address Line 1"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={addressLine2 || customerAddressLine2}
              placeholder="Address Line 2"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              placeholder="Landmark"
              value={landmark || customerLandmark}
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={pinCode || customerPinCode}
              placeholder="Pin Code"
              disabled
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={city || customerCity}
              placeholder="City"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={state || customerState}
              placeholder="State"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={
                (consigneeFirstName || consigneeLastName) &&
                `${consigneeFirstName} ${consigneeLastName}`
              }
              placeholder="Consignee Name"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={consigneePhone}
              placeholder="Consignee Phone Number"
              disabled
            />
          </div>
        </Col>
      </Row>

      <Divider />
    </>
  );
};

export default AddressTemplate;
