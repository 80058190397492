// Helper styles for demo
import React, { useEffect } from "react";
import { Formik } from "formik";

import './styles.scss';

function FormikObserver({ onChange, value }) {
  useEffect(() => {
    onChange(value)
  }, [value])

  return null;
}

FormikObserver.defaultProps = {
  onChange: () => null,
}

const Form = ({
  children,
  initialValues = {},
  validationSchema,
  className = '',
  onValidationChange = () => null,
  onFormChange = (values) => null,
  ...restProps
}) => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...restProps}
    >
      {({ handleSubmit, ...props }) => {
        return (
          <>
            <FormikObserver value={props.isValid} onChange={onValidationChange} />
            <form
              className={`formik ${className}`}
              onSubmit={handleSubmit}
              onChange={onFormChange(props.values)}
            >
              {children({
                ...props
              })}
            </form>
          </>
        );
      }}
    </Formik>
  );

export default Form;
