import { copyBrandFeatures } from "helpers/brandHelpers";
import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  all_brands: [], // TODO: try to move all_brands from array to hash map
  brand_users: {},
  brandFeatures: {},
  mapBrandFeatures: {},
  brandAddresses: {},
};

const BrandReducer = handleActions(
  {
    GET_ALL_BRANDS_INFO: (state, action) => {
      return {
        ...state,
        all_brands: action.payload
      }
    },
    GET_USERS_FOR_BRAND: (state, action) => {
      const { brandId, list } = action.payload;
      return {
        ...state,
        brand_users: {
          ...state.brand_users,
          [brandId]: list
        }
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    },
    CREATE_BRAND: (state, action) => {
      return {
        ...state,
        all_brands: [
          action.payload,
          ...state.all_brands
        ]
      };
    },
    UPDATE_BRAND: (state, action) => {
      const oldBrand = state.all_brands.find(b => b.id == action.payload.id);

      if (oldBrand) {
        oldBrand.name = action.payload.name;
        oldBrand.web_url = action.payload.web_url;
        oldBrand.gst_number = action.payload.gst_number;

        copyBrandFeatures(action.payload, oldBrand);
      }

      return {
        ...state,
      };
    },
    GET_BRAND_FEATURES: (state, action) => {
      return {
        ...state,
        brandFeatures: action.payload,
        mapBrandFeatures: {
          ...state.mapBrandFeatures,
          [action.payload.brandId]: action.payload,
        },
      };
    },
    GET_BRAND_ADDRESSES: (state, action) => {
      const { brandId, addresses } = action.payload;
      return {
        ...state,
        brandAddresses: {
          ...state.brandAddresses,
          [brandId]: addresses,
        },
      };
    },
  },
  INITIAL_STATE
);

export default BrandReducer;
