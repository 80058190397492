import { Switch } from 'antd';
import React, { useState } from 'react';
import style from './style.module.scss';

const ToggleSwitch = ({ id, toggleStatus = false, onToggleSwitch = () => {} }) => {
  const [active, setActive] = useState(toggleStatus);
  return (
    <div className={style.switch}>
      <div>{active ? 'On' : 'Off'}</div>
      <Switch
        key={id}
        onClick={(e) => {
          setActive(e);
          onToggleSwitch(e, setActive);
        }}
        checked={active}
      />
    </div>
  );
};

export default ToggleSwitch;
