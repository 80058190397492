import { useState } from 'react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Field } from 'formik';
import Status from '../../../../src/components/AccountSettings/TopQualities/status';
import { Upload, Button, Progress, message } from 'antd';
import style from './style.module.scss';
import { DEFAULT_FILE_SIZE, checkFileType } from 'utilites/utils';
import { getMediaLink } from 'store/actions/InventoryAction';
import { connect } from 'react-redux';

const UploadMediaWithS3 = ({
  name,
  label,
  isRequired,
  staticFileName,
  boxWidthStyle = '',
  uploadButtonName = 'Click to Upload',
  maxFileSize = DEFAULT_FILE_SIZE,
  acceptFileType = '',
  isDisabled = false,
  getMediaLink,
}) => {
  const [uploadedPercentage, setUploadedPercentage] = useState(0);
  const [fileName, setFileName] = useState('');

  const uploadImage = (options) => {
    const { onSuccess } = options;
    // onSuccess('Ok');
  };
  const handleOnChange = ({ fileList }, setFieldValue, fieldName, setFieldError) => {
    const { size, originFileObj } = fileList[0] || {};
    setFileName(originFileObj?.name);

    if (!checkFileType(originFileObj?.type, acceptFileType)) {
      message.error('Invalid File Type');
      return;
    }
    if (size < maxFileSize) {
      getMediaLink({ file: originFileObj, percent: setUploadedPercentage,setFieldValue,name }).then((res) => {
        setFieldValue(fieldName, { ...fileList[0], ...res });
        setUploadedPercentage(0);
      });
    } else {
      setFieldError(fieldName, `Max file size should be less than ${maxFileSize / 1000} KB`);
    }
  };

  return (
    <Field name={name}>
      {({ field, meta: { error }, form: { setFieldValue, setFieldError } }) => {
        return (
          <div className={style['upload-wrapper']}>
            {label ? (
              <p className={style.label} style={{ marginBottom: 0 }}>
                <span>{label}</span>
                {isRequired && <Status isFilled={!(field.value === null || error)} />}
              </p>
            ) : null}

            <Upload
              multiple={false}
              showUploadList={false}
              customRequest={uploadImage}
              accept={acceptFileType}
              onChange={(e) => handleOnChange(e, setFieldValue, field.name, setFieldError)}
              maxCount={1}
              listType="text"
              disabled={isDisabled}
              name={field.name}>
              {!field.value && uploadedPercentage < 1 && (
                <Button
                  className={`${style['download-btn']} ${boxWidthStyle}`}
                  style={{
                    height: 48,
                    borderRadius: 8,
                    border: 'dashed',
                    borderColor: '#E5E5E5',
                    boxShadow: 'none',
                  }}
                  icon={<UploadOutlined />}
                  type="primary"
                  ghost>
                  {uploadButtonName}
                </Button>
              )}
            </Upload>

            <UploadFileDisplay
              field={field}
              fileName={fileName || staticFileName}
              setFieldValue={setFieldValue}
              uploadedPercentage={uploadedPercentage}
              setUploadedPercentage={setUploadedPercentage}
              boxWidthStyle={boxWidthStyle}
            />

            {error ? <p className={style.error}>{error}</p> : null}
          </div>
        );
      }}
    </Field>
  );
};

const UploadFileDisplay = ({
  field,
  fileName,
  setFieldValue,
  uploadedPercentage,
  setUploadedPercentage,
  boxWidthStyle,
}) => {
  return field?.value ? (
    <div className={`${style['download-box']} ${boxWidthStyle}`}>
      {typeof field.value === 'object' && (
        <div className={style['text-wrapper-download']}>
          <a href={field?.value?.fileUrl} download target="_blank" rel="noreferrer">
            {field?.value?.fileName}
          </a>
        </div>
      )}
      {typeof field.value === 'string' && (
        <a href={field.value} className={style['text-wrapper-download']} download>
          {fileName}
        </a>
      )}
      <Button
        style={{ border: 0 }}
        onClick={() => {
          setFieldValue(field.name, null);
          setUploadedPercentage(0);
        }}
        icon={<DeleteOutlined />}
      />
    </div>
  ) : (
    <div>
      {Boolean(uploadedPercentage) && (
        <div className={`${style['download-box']} ${boxWidthStyle}`}>
          <div className={style['text-wrapper-download']}>{fileName}</div>
          <Progress width={30} type="circle" percent={uploadedPercentage} />
        </div>
      )}
    </div>
  );
};

// export default UploadMediaWithS3;

const mapStateToProps = (state) => ({
  materialData: state.fabricQualities.materialData,
});
const mapDispatchToProps = (dispatch) => ({
  getMediaLink: (data) => dispatch(getMediaLink(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadMediaWithS3);
