import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from "react-redux";
import Button from 'components/UI/Button/Button';
import actions from "store/actions";
import { closeModal } from "store/actions/GlobalActions";
import { AuthRoleIds, USER_ROLES } from 'constants/Auth';

import CloseIcon from "assets/images/Settings/Icon/close.svg";
import UserIcon from "assets/images/Settings/Icon/user.svg";
import CheckIcon from 'assets/images/Settings/Icon/check.svg';
import BackIcon from 'assets/images/Settings/Icon/back-dark-circle.svg';
import BackIconDisabled from 'assets/images/Settings/Icon/back-circle.svg';

import './styles.scss';

const roleOptions = [
  // {
  //   label: "City Head",
  //   value: AuthRoleIds.SALES_HEAD,
  // },
  {
    label: "Supplier Manager",
    value: AuthRoleIds.SUPPLIER_MANAGER,
  },
  {
    label: "Account Manager",
    value: AuthRoleIds.ACCOUNT_MANAGER,
  },
  {
    label: 'Category Manager',
    value: AuthRoleIds.SUPPLY_HEAD,
  }

];

const RoleChangeModal = ({
  userId,
  roleChange,
  verify,
  verifyData,
  closeModal,
  name,
  setIsUpdated,
  role
}) => {

  const [selectedRole, setSelectedRole] = useState('')
  const [active, setActive] = useState(1)


  const getRoles = (role) => {
    return roleOptions.filter(i => +i.value !== +role)
  }

  const moveToNext = () => {
    setActive(2)
    if (userId) {
      verify({
        userId: userId.toString(),
        actionType: 'role-change',
        newRole: selectedRole.toString(),
      })
    }
  }

  const handleRoleChange = () => {
    roleChange({
      userId: userId.toString(),
      actionType: 'role-change',
      newRole: selectedRole.toString(),
    }).then(() => {
      closeModal();
      setIsUpdated(true);
    }).catch(e => {
      console.log('err', e)
    })
  }

  const getErrorMsg = (role, name) => {
    switch (role) {
      case +AuthRoleIds.ACCOUNT_MANAGER:
      case +AuthRoleIds.SALES_HEAD:
        return `Please assign open requests of ${name} to other AMs inorder to change role.`

      case +AuthRoleIds.SUPPLIER_MANAGER:
      case +AuthRoleIds.SUPPLY_HEAD:
        return `Please assign associated suppliers of ${name} to other SMs inorder to change role.`
      default:
        return ''
    }
  }

  const getMsg = (role, toRole) => {
    switch (role) {
      case +AuthRoleIds.ACCOUNT_MANAGER:
      case +AuthRoleIds.SALES_HEAD:
        return 'All the customers (without open requests) will be available to other AMs via Map to me feature.'

      case +AuthRoleIds.SUPPLIER_MANAGER:
        return `Changing role from Supplier Manager to ${toRole}.`

      case +AuthRoleIds.SUPPLY_HEAD:
        return `Changing role from Category Manager to ${toRole}.`
      default:
        return ''
    }
  }

  return (
    <div className='modal-content'>
      <div className="header">
        <h3 className='heading'>Role Change - {name}</h3>
        <button className='close-btn' onClick={closeModal}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
      {
        active === 1 ?
          <div className="content">
            <p>New Role</p>
            <div className='role-options-container'>
              {
                getRoles(role).map(i => {
                  return (
                    <button className={classnames('role-option', selectedRole === i.value && 'role-option-selected')} onClick={() => setSelectedRole(i.value)}>
                      {selectedRole === i.value && <img src={CheckIcon} alt='' />}
                      <p className='label'>{i.label}</p>
                    </button>
                  )
                })
              }
            </div>
          </div> :
          <div className="content">
            <div className='user-row'>
              <div className='user'>
                <div className="user-image-container">
                  <img src={verifyData?.userForAction?.image || UserIcon} alt="" className="user-image" />
                </div>
                <p className='user-name'>{verifyData?.userForAction?.userName}</p>
              </div>

              {
                !verifyData?.canPerform &&
                <div className="role">
                  <p className='role-title'>{USER_ROLES[verifyData?.userForAction?.role]}</p>
                </div>
              }
            </div>
            {
              !verifyData?.canPerform && verifyData?.canPerform !== undefined ?
                <p className='subtitle error'>
                  {getErrorMsg(+verifyData?.userForAction?.role, verifyData?.userForAction?.userName)}
                </p>
                :
                <p className='subtitle'>
                  {getMsg(+verifyData?.userForAction?.role, USER_ROLES[selectedRole])}
                </p>
            }
          </div>
      }

      {
        selectedRole === AuthRoleIds.SALES_HEAD &&
        <p className='subtitle'>
          All the customers along with their open requests and orders will retain with this account
        </p>
      }


      <div className={classnames('footer', selectedRole === AuthRoleIds.SALES_HEAD && 'right-align')}>
        {selectedRole && (
          selectedRole === AuthRoleIds.SALES_HEAD ?
            (
              <div>
                <Button category="blue-bg" onClick={handleRoleChange}>
                  Confirm
                </Button>
              </div>
            )
            :
            <>
              <div className='navigate-options'>
                <button className='back-btn' disabled={active === 1} onClick={() => setActive(1)}>
                  {
                    active === 1 ?
                      <img src={BackIconDisabled} alt='' /> :
                      <img src={BackIcon} alt='' />
                  }
                </button>
                <div className='steps'>
                  <div className={classnames('step', active === 1 && 'step-active', active === 2 && 'step-done')} />
                  <div className={classnames('step', active === 2 && 'step-active')} />
                </div>
              </div>
              {
                active === 2 ?
                  (
                    verifyData?.canPerform &&
                    <Button category="blue-bg" onClick={handleRoleChange} >
                      Confirm
                    </Button>) :
                  <Button category="blue-bg" onClick={moveToNext} >
                    Next
                  </Button>
              }
            </>
        )
        }
      </div>

    </div >
  )
}

const mapStateToProps = state => ({
  verifyData: state.user.userRoleChangeData.data
});

const mapDispatchToProps = dispatch => ({
  roleChange: (params) => dispatch(actions.changeRole(params)),
  verify: (params) => dispatch(actions.verifyRoleChange(params)),
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleChangeModal)
