import React, { useMemo, useCallback } from 'react'
import { default as ReactSelect } from "react-select";
import { ALL_STATES } from 'constants/Global';
import './styles.scss';

const STATE_OPTIONS = ALL_STATES.map(state => ({
  label: state, value: state,
}));

const ReactSelectStyle = {
  control: (provided) => ({
    ...provided,
    border: '1px solid var(--fill-grey-light3)',
    height: '44px',
    borderRadius: '0',
  }),
  indicatorSeparator: () => ({}),
};

const Address = ({
  field,
  field: { name },
  form: { touched, errors, setFieldValue },
  label,
  required,
  className,
  alignment = "vertical",
  ...props
}) => {
  const value = useMemo(() => {
    return field.value || {};
  }, [field.value]);
  const inputChangeHandler = useCallback((newFieldValue, key) => {
    setFieldValue(name, {
      ...value,
      [key]: newFieldValue,
    });
  }, [value, setFieldValue, name]);


  let fullClassName = className || '';

  return (
    <div className={`formik-field input-address ${alignment} ${fullClassName}`}>
      <label htmlFor={name} className="label">
        {label}
        {
          required && <sup>*</sup>
        }
      </label>
      <div className="formik-field__input">
        <div className="row">
          <textarea {...field} {...props}
            rows={4}
            onChange={(e) => inputChangeHandler(e.target.value, 'addressLines')}
            value={value.addressLines}
            className="input address-lines"
          />
        </div>
        <div className="row">
          <input {...field} {...props}
            type="text"
            placeholder="City"
            onChange={(e) => inputChangeHandler(e.target.value, 'city')}
            value={value.city}
            className="input address-city"
          />
          <ReactSelect
            placeholder="State"
            options={STATE_OPTIONS}
            onChange={(value) => inputChangeHandler(value, 'state')}
            value={value.state}
            className="input address-state"
            styles={ReactSelectStyle}
          />
          <input {...field} {...props}
            type="text"
            placeholder="Pincode"
            onChange={(e) => inputChangeHandler(e.target.value, 'pinCode')}
            value={value.pinCode}
            className="input address-pincode"
          />
        </div>
      </div>
      {touched[field.name]
        && typeof (errors[field.name]) === 'object'
        && Object.values(errors[field.name]).length > 0
        && <div className="error">{Object.values(errors[field.name])[0]}</div>}
    </div>
  );
};

export default Address;
