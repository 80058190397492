import React, { Component } from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";
import { withForwardedRef } from "../../../HOCs/withForwardedRef"

import "./Input.scss";
import { omit } from "lodash";

class Input extends Component {
  state = {
    currentLength: 0,
    prefixWidth: 0,
    suffixWidth: 0,
  };

  constructor(props) {
    super(props)
    this.inputField = React.createRef();
    this.prefixField = React.createRef();
    this.suffixField = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value)
      return {
        ...prevState,
        currentLength: nextProps.value.length
      };

    return prevState;
  }

  componentDidMount() {
    if (this.props.autoFocus && this.inputField.current) {
      this.inputField.current.focus();
    }

    this._testPrefixWidth();
  }

  componentDidUpdate() {
    this._testPrefixWidth();
  }

  _testPrefixWidth() {
    const { prefixWidth, suffixWidth } = this.state;
    const nextState = {};

    if (this.prefixField.current && this.prefixField.current.offsetWidth !== prefixWidth) {
      nextState.prefixWidth = this.prefixField.current.offsetWidth;
    }

    if (this.suffixField.current && this.suffixField.current.offsetWidth !== suffixWidth) {
      nextState.suffixWidth = this.suffixField.current.offsetWidth;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState);
    }
  }

  handleChange = (evt, ...args) => {
    this.setState({
      currentLength: evt.target.value.length
    });

    if (typeof (this.props.onChange) === 'function') {
      this.props.onChange(evt);
    }
  };

  render() {
    const {
      label,
      // meta: { touched, error, warning },
      maxLength,
      // import separately so that it doesn't override the default HTML onChange
      onChange,
      forwardedRef,
      gluePrefix,
      glueSuffix,
      className,
      placeholder,
      ...props
    } = this.props;
    const { currentLength, prefixWidth, suffixWidth } = this.state;
    // console.log(error);
    // if (!props.value) {
    //   props.value = "";
    // }

    // EXAMPLE: Direct Custom Input usage
    // <Input
    //   onChange={(evt)=>{console.log(evt)}}
    //   type="text"
    //   name="asd"
    //   label="QWERTY"
    //   // value="12345"
    // />
    return (
      <div className={`input-grp custom-input ${className || ''}`}>
        {label && <label htmlFor={this.props.name} className="f-sz-sm f-color-faded">
          {label}
          {props.required === true && <sup>*</sup>}
        </label>}
        <div
          className={classnames("custom-input__field-box", {
            'has-prefix': this.props.prefixText,
            'has-prefix-glued': this.props.prefixText && this.props.gluePrefix,
            'has-suffix': this.props.suffixText,
            'has-suffix-glued': this.props.suffixText && this.props.glueSuffix,
          })}
          style={{
            ...(!this.props.gluePrefix && prefixWidth > 0 && { '--prefix-width': `${prefixWidth}px` }),
            ...(!this.props.glueSuffix && suffixWidth > 0 && { '--suffix-width': `${suffixWidth}px` }),
          }}
        >
          {
            this.props.prefixText && (
              <span
                ref={this.prefixField}
                className={`custom-input__prefix ${gluePrefix ? 'custom-input__prefix--glue' : ''}`}
              >
                {this.props.prefixText}
              </span>
            )}
          <input
            id={this.props.name}
            {...(omit(this.props, 'prefixText', 'suffixText', 'gluePrefix', 'glueSuffix'))}
            onChange={this.handleChange}
            style={{
              ...this.props.style,
            }}
            ref={ref => {
              if (forwardedRef) {
                if (typeof (forwardedRef) === 'function') {
                  forwardedRef(ref);
                } else if (Object.prototype.hasOwnProperty.call(forwardedRef, 'current')) {
                  forwardedRef.current = ref;
                }
              }
              this.inputField.current = ref;
            }}
          />
          {
            this.props.suffixText && (
              <span
                ref={this.suffixField}
                className={`custom-input__suffix ${glueSuffix ? 'custom-input__suffix--glue' : ''}`}
              >
                {this.props.suffixText}
              </span>
            )
          }
        </div>
        {maxLength && !this.props.disabled ? (
          <span className="input-grp__lower flex f-sz-sm">
            <span className="input-len-count f-color-faded">
              {currentLength}/{maxLength}
            </span>
          </span>

        ) : null}
      </div>
    );
  }
}

export default withForwardedRef(Input);
