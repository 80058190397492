import React from 'react';
import OrgHeader from "components/AccountSettings/OrgSettings/OrgHeader";
import Button from 'components/UI/Button/Button';

const Header = ({
  onOrgTypeSelect,
  handleClick,
  hideActions = false,
  label,
  buttonLabel,
  hideFilter
}) => {
  return <OrgHeader
    heading={label}
    onOrgTypeSelect={onOrgTypeSelect}
    hideActions={hideActions}
    hideFilter={hideFilter}
  >
    <Button
      category="blue-bg"
      onClick={handleClick}
    >
      {buttonLabel}
    </Button>
  </OrgHeader>;
};

export default Header;
