import React from 'react';
import { Row, Col, Input, Divider, Button } from 'antd';
import './styles.scss';
import { UNDEFINED_VALUE } from '../../constants';
import { DataLoader } from '../BasicInfo';
const VirtualPayment = ({ customer, vpa, generateVPA, loading }) => {
  const { gstNumber='', id } = customer;
  const { 
    bankAccountDetails: {
      accountNumber,
      name,
      ifsc,
      swiftCode,
      bankName
    },
    upiDetails: { address }
  } = {
    bankAccountDetails:{
      accountNumber:'',
      name:'',
      ifsc:'',
      bankName:'',
      swiftCode: ''
    },
    upiDetails: {
      address: null
    },
    ...vpa
  };
  const getValue = (value) => {
    return value ? value : UNDEFINED_VALUE;
  };
  const generateVpaDetails = () => {
    generateVPA(+id);
  }
  return ( 
    <>
      <div className="basicInfocontainer">
        <div className="basicHeader">
          <div>Virtual Payment and Account Details</div>
          {(gstNumber && !vpa) && <Button type="link" className="vpa" onClick={generateVpaDetails}>
           {loading? <DataLoader/> : 'Generate VPA'}
          </Button>}
        </div>

        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className="element">
              <label className="label">Accont Number</label>
              <Input
                className="inputFormikClear"
                placeholder="Account Number"
                value={getValue(accountNumber)}
                disabled
              />
            </div>
            <div className="element">
              <label className="label">Account Name</label>
              <Input
                className="inputFormikClear"
                placeholder="Account Name"
                value={getValue(name)}
                disabled
              />
            </div>
            <div className="element">
              <label className="label">{swiftCode ? 'Swift code' : 'IFSC code'}</label>
              <Input
                className="inputFormikClear"
                placeholder={swiftCode ? 'Swift code' : 'IFSC'}
                value={swiftCode ? getValue(swiftCode) : getValue(ifsc)}
                disabled/>
            </div>
          </Col>
          <Col span={12}>
            <div className="element">
              <label className="label">UPI ID</label>
              <Input
                className="inputFormikClear"
                placeholder="upi id"
                value={getValue(address)}
                disabled
              />
            </div>
            <div className="element">
              <label className="label">Bank Name</label>
              <Input
                className="inputFormikClear"
                placeholder="bank name"
                value={getValue(bankName)}
                disabled
              />
            </div>
          </Col>
        </Row>
      </div>
      <Divider />
    </>
  );
};

export default VirtualPayment;
