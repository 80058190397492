import React, { Component } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import "./ResetPassword.scss";

export default class ResetPasswordModal extends Component {
  render() {
    const { onSubmit } = this.props;
    return (
      <div className="reset-password-modal">
        <div className="container">
          <ResetPasswordForm hasSubmitButton={false} onSubmit={onSubmit} />
        </div>
      </div>
    );
  }
}
