import React from 'react';
import { Divider } from 'antd';
import AddressTemplate from './addressTemplate';
import './styles.scss';
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import style from '../../../TopQualities/style.module.scss';
const Address = ({ customer, updateODA }) => {
  const defaultAddress = [];
  const otherAddress = [];
  const { addressLine1, isOda } = customer;
  if (customer?.otherAddresses?.length > 0) {
    let address = customer?.otherAddresses?.forEach((address, index) => {
      if (address?.isDefault) {
        defaultAddress.push(address);
      } else {
        otherAddress.push(address);
      }
    });
  }

  const markODA = (customerId, addressId, isODA) => {
    const data = { orgId: customerId, addressId: addressId, isOda: Number(isODA) };
    updateODA(data);
  };
  return (
    <>
      {customer && (
        <div className="basicInfocontainer">
          <h3 className="basicHeader">Address</h3>
          {addressLine1 ? (
            <div className="addressTitle">
              <p>Registered Address</p>
              <StatusToggler
                label={'Is this address outside delivery area?'}
                onFalseLabel={'No'}
                onTrueLabel={'Yes'}
                style={style}
                condition={isOda}
                onChange={(e) => {
                  markODA(customer.id, customer?.brandAddressId, e);
                }}
              />
            </div>
          ) : null}
          {addressLine1 ? <AddressTemplate address="" customer={customer} /> : <div></div>}
          {defaultAddress?.length > 0 ? (
            <div className="addressTitle">
              <p>Default shipping address</p>
            </div>
          ) : null}
          {defaultAddress.map((address, index) => (
            <div>
              <div className="odaContainer">
                <div className="oda">
                  <StatusToggler
                    label={'Is this address outside delivery area?'}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    style={style}
                    condition={address?.isOda}
                    onChange={(e) => markODA(customer.id, address?.brandAddressId, e)}
                  />
                </div>
              </div>
              <AddressTemplate address={address} key={index} />
            </div>
          ))}
          {otherAddress?.length > 0 ? (
            <div className="addressTitle">
              <p>Other Shipping Address</p>
            </div>
          ) : null}
          {otherAddress.map((address, index) => (
            <div>
              <div className="odaContainer">
                <div className="oda">
                  <StatusToggler
                    label={'Is this address outside delivery area?'}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    style={style}
                    condition={address?.isOda}
                    onChange={(e) => markODA(customer.id, address?.brandAddressId, e)}
                  />
                </div>
              </div>
              <AddressTemplate address={address} key={index} />
            </div>
          ))}
        </div>
      )}
      <Divider />
    </>
  );
};

export default Address;
