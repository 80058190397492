import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import PerfectScrollbar from "react-perfect-scrollbar";

import Modal from "../../components/UI/Modal/Modal";
import actions from "../../store/actions";
import ModalList from "./ModalList.js";
import "./ModalContainer.scss";

class ModalContainer extends Component {
  state = {
    modalContents: [],
    currentModal: null,
    canNavigateBack: false,
    isSuccess: false,
  };

  escFunction(event) {
    if (
      this.props.modal.isOpen &&
      event.keyCode === 27 &&
      !this.props.modal.content.noEsc
    ) {
      this._closeModal()
    }
  }

  componentWillReceiveProps(nextProps, oldProps) {
    if (nextProps.modal !== oldProps.modal) {
      if (nextProps.modal && nextProps.modal.isOpen) {
        this.setState({
          modalContents: [nextProps.modal],
          currentModal: nextProps.modal,
          canNavigateBack: false,
          isSuccess: true,
        });
      } else {

        if (this.state.isSuccess) {
          this._onSuccessFormAction();
        }

        this.setState({
          modalContents: [],
          currentModal: null,
          canNavigateBack: false,
          isSuccess: false,
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  _pushModal = (modal) => {
    const { modalContents } = this.state;

    this.setState({
      modalContents: [...modalContents, modal],
      currentModal: modal,
      canNavigateBack: true,
    });
  }

  _popModal = () => {
    const { modalContents } = this.state;

    modalContents.pop();

    this.setState({
      modalContents: [...modalContents],
      currentModal: modalContents[0],
      canNavigateBack: modalContents.length > 1,
    });
  }

  _openNextModal = (name, props) => {
    this._pushModal({
      isOpen: true,
      content: ModalList[name],
      additionalProps: props,
    });
  };

  _closeModal = () => {
    const { closeModal } = this.props;

    this.setState({ isSuccess: false }, () => {
      closeModal();
    });
  }

  _onSuccessFormAction = (...args) => {
    const { modalContents } = this.state;

    if (!modalContents || modalContents.length <= 0) {
      return;
    }

    if (modalContents && modalContents.length) {
      let lastResult = args;

      for (var ind = modalContents.length - 1; ind >= 0; ind--) {
        const modal = modalContents[ind];
        if (modal && modal.additionalProps && modal.additionalProps.onSuccess) {
          lastResult = modal.additionalProps.onSuccess(...lastResult);
        }
      }
    }
  }

  render() {
    const { dispatch, submitForm } = this.props;
    const { canNavigateBack, currentModal: modal } = this.state;
    let ModalProps;

    if (!modal) {
      return null;
    }

    if (modal.isOpen) {
      ModalProps = {
        content: modal.content,
        closeModal: this._closeModal,
        formAction: val => {
          return Promise.resolve(dispatch(actions[modal.content.submitAction](val)))
            .then(this._onSuccessFormAction);
        },
        additionalProps: modal.additionalProps
      };

      // Add when formName present
      if (
        modal.content &&
        modal.content.formName !== "" &&
        modal.content.formName !== undefined
      ) {
        ModalProps = {
          ...ModalProps,
          remoteSubmit: () => submitForm(modal.content.formName)
        };
      }
    }

    return (
      <div
        className={`modal__container${modal.isOpen ? "" : " hide"}`}
        style={{
          backgroundColor:
            modal.content && modal.content.noShadow
              ? "transparent"
              : "",
          display:
            modal.content && modal.content.noShadow
              ? "contents"
              : ""
        }}
      >
        <PerfectScrollbar>
          {modal.isOpen ? (
            <Modal
              {...ModalProps}
              canNavigateBack={canNavigateBack}
              navigateBack={this._popModal}
              openNextModal={this._openNextModal}
              isCustomStyles={
                modal.content && modal.content.noShadow
              }
            />
          ) : (
              <div />
            )}
        </PerfectScrollbar>
      </div>
    );
  }
}

ModalContainer.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  modal: PropTypes.object
};

const mapStateToProps = state => ({ modal: state.global.modal });
const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(actions.openModal()),
  closeModal: () => dispatch(actions.closeModal()),

  // Create a remote submit dispatcher for redux-forms: https://redux-form.com/7.4.2/examples/remotesubmit/
  submitForm: formName => dispatch(submit(formName)),
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
