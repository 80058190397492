import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Header from './Header/index';
import Table from './Table';
import EmptyScreen from './EmptyScreen';
import {
  getLogisticPartners,
} from 'store/actions/UserActions';
import {
  createNewBrand,
  getUsersForBrand,
  updateBrandDetails,
} from 'store/actions/BrandActions';
import './style.scss';

const LogisticPartners = ({
  logiPartnersList,
  openModal,
  getLogisticPartners,
}) => {
  const hasPartners = useMemo(() => logiPartnersList && logiPartnersList.length > 0, [logiPartnersList]);

  useEffect(() => {
    getLogisticPartners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPartner = useCallback(() => {
    openModal('ADD_NEW_PARTNER', {});
  }, [openModal]);

  const onEditPartner = useCallback((partner) => {
      openModal('EDIT_PARTNER_DETAILS', {
        initialValues: {
          partnerId: partner.id,
          ...partner,
        },
      });
  }, [openModal]);

  return (<div className="settings-partners">
    <Header
      handleClick={onAddPartner}
      hideActions={!hasPartners}
      hideFilter={true}
      label="Logistic Partners"
      buttonLabel='Add Partner'
    />
    {
      hasPartners
        ? <Table
          data={logiPartnersList}
          expandable={false}
          onOrgEdit={onEditPartner}
        />
        : <EmptyScreen
          handleClick={onAddPartner}
          heading="Manage Partners"
          description={<>
            All logistic partners <br />
            are added here
          </>}
        />
    }
  </div>);
};

const mapStateToProps = (state, props) => ({
  logiPartnersList: props.logiPartnersList || state.user.logiPartnersList.data,
  users: props.users || state.user.users.data,
});

const mapDispatchToProps = (dispatch) => ({
  getLogisticPartners: () => dispatch(getLogisticPartners()),
  createCustomer: (data) => dispatch(createNewBrand(data)),
  updateCustomer: (data) => dispatch(updateBrandDetails(data)),
  getBrandUsers: (brandId) => dispatch(getUsersForBrand(brandId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticPartners);
