import React from 'react';
import './index.scss';

export const Horizontal = "horizontal";
export const Vertical = "vertical";

const EmptyScreen = ({
  icon,
  iconSize,
  iconPosition = 'center',
  iconCurved = true, // whether there will be 50% radius on container
  heading,
  description,
  orientation = Vertical,
  cta,
  className = '',
}) => {
  return (
    <div className={`empty-list-container orientation-${orientation} ${iconCurved ? 'curved' : ''} position-${iconPosition} ${className}`}>
      {icon && <div className="img-container" style={{
        ...(iconSize ? {
          width: iconSize,
          height: iconSize,
        } : {}),
      }}>
        {
          typeof (icon) === 'string'
            ? <img src={icon} alt="" />
            : icon
        }
      </div>}
      <div className="content">
        <div className="heading">{heading}</div>
        <div className="description">{description}</div>
        {cta && <div className="cta">
          {cta}
        </div>}
      </div>
    </div >
  );
};

export default EmptyScreen;
