import { handleActions } from "redux-actions";
import ModalList from "../../containers/ModalContainer/ModalList.js";

const INITIAL_STATE = {
  loader: {
    isLoading: false,
    currentLoaders: 0
  },
  uploader: {
    isUploading: false
  },
  modal: {
    isOpen: false,
    content: {
      title: "",
      innerContent: null,
      footer: ""
    }
  },
  flash: []
};

const GlobalReducer = handleActions(
  {
    IS_LOADING: state => {
      // In case Finished loading called already to remove the load screen
      // Used in AddFilesForm.jsx (In Design > Files : Upload File Modal)
      // const currentLoaders =
      //   state.loader.currentLoaders < 0 ? 0 : state.loader.currentLoaders + 1;
      // let isLoading = true;
      // if (currentLoaders === 0) {
      //   isLoading = false;
      // }
      // return {
      //   ...state,
      //   loader: {
      //     ...state.loader,
      //     isLoading: isLoading,
      //     currentLoaders: currentLoaders
      //   }
      // };
      return {
        ...state,
        loader: {
          isLoading: true,
          currentLoaders: 1
        }
      }
    },
    FINISH_LOADING: state => {
      // In case Finished loading called already to remove the load screen
      // Used in AddFilesForm.jsx (In Design > Files : Upload File Modal)
      const currentLoaders = state.loader.currentLoaders - 1;
      return {
        ...state,
        loader: {
          isLoading: currentLoaders <= 0 ? false : true,
          currentLoaders: currentLoaders
        }
      };
    },
    IS_UPLOADING: (state, action) => {
      return {
        ...state,
        uploader: {
          isUploading: true,
          error: '',
          fileName: action.payload.fileName
        }
      }
    },
    FINISH_UPLOADING: (state, action) => {
      return {
        ...state,
        uploader: {
          isUploading: false,
          uploadComplete: true,
          error: action.payload.error,
          pathName: action.payload.pathName
        }
      }
    },
    CLOSE_MODAL: (state) => ({
      ...state,
      modal: {
        isOpen: false
      }
    }),
    OPEN_MODAL: (state, action) => {
      return {
        ...state,
        modal: {
          isOpen: true,
          content: ModalList[action.payload.name],
          additionalProps: action.payload.additionalProps
        }
      };
    },
    SHOW_FLASH_MESSAGE: (state, action) => {
      const newFlashList = [...state.flash, action.payload];
      return {
        ...state,
        flash: newFlashList
          //  Remove duplicate messages
          .filter(
            (v, i, self) => self.findIndex(t => v.message === t.message) === i
          )
      };
    },
    HIDE_FLASH_MESSAGE: (state, action) => {
      return {
        ...state,
        flash: [] //state.flash.slice(1)
      };
    },
    HIDE_PROGRESS: (state) => {
      return {
        ...state,
        uploader: {
          isUploading: false
        }
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default GlobalReducer;
