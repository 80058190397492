import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const OrgRow = ({
  path,
  org,
  gridTemplateColumns,
  rowRenderer = (org) => null,
  queryParams
}) => {
  return <Link style={{color: 'black'}} to={`${path}${org.supplierId}/${queryParams.search ? `?search=${queryParams.search}&page=${queryParams.page}` : ''} `}>
    <div
    className={classnames("org-settings-row")}
  >
    <div
      className="org-settings-row__header"
      style={{
        gridTemplateColumns,
      }}
    >
      {rowRenderer(org)}
    </div>
  </div>
  </Link>;
};

export default OrgRow;
