import { getFileName } from "helpers/getNameFromUrl";
import React from "react";
import "./styles.scss";
const FileUpload = ({
  existingFileName,
  name,
  setFieldValue=false,
  required,
  file,
  setFile,
  accept='',
}) => {
  const onFileChange = (event) => {
    setFieldValue && setFieldValue(name, event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const showFileName = () => {
    if (file) {
      return <span>{file.name}</span>;
    }
    return (
      <span>
        {existingFileName ? getFileName(existingFileName) : "Click to upload"}
      </span>
    );
  };

  return (
    <>
      <label class="custom-file-upload">
        <input type="file" accept={accept} onChange={onFileChange} />
        {showFileName()}
      </label>
      {required && !file && !existingFileName && (
        <span class="error-msg">Required</span>
      )}
    </>
  );
};

export default FileUpload;
