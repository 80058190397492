
export const getLoadingErrorState = (initialData) => ({
  data: initialData,
  error: null,
  loading: false,
});

export const certificationTransformer = (certification) => {
  const obj = {};
  certification.map((val) => {
    obj[val.id] = val.name;
    return null
  });
  return obj;
};