import { Field } from 'formik';
import React, { useState } from 'react';
import Status from '../status';
import { ReactComponent as Check } from 'assets/images/fabricQualities/Check.svg';
import style from './style.module.scss';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';

const RadioField = ({
  name,
  label,
  type,
  placeholder,
  isRequired = false,
  options,
  onSelect,
  isDisabled = false,
  isMultipleAllowed = false,
  boxClasses,
  setOtherDenimColor,
  setMainFeildValue
}) => {
  const [otherSelected, setOthersSelected] = useState(false);
  return (
    <Field name={name}>
      {({ field, meta: { error, touched }, form: { setFieldValue, values } }) => {
        const onMuliSelect = (e) => {
          if (e.target.checked) {
            setFieldValue(field.name, [...values[name], +e.target.value]);
          } else {
            let freshArray = values[name].filter((val) => val !== +e.target.value);
            setFieldValue(field.name, [...freshArray]);
          }
        };
        return (
          <div className={style['input-wrapper']}>
            {label ? (
              <p className={style.label}>
                <span>{label}</span>
                {isRequired && (
                  <Status
                    isFilled={isMultipleAllowed ? field?.value?.length > 0 : field?.value > 0}
                  />
                )}
              </p>
            ) : null}

            {isMultipleAllowed ? (
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                onChange={(e) => onMuliSelect(e)}>
                {options.map((option) => {
                  return (
                    <label key={option.id} className="radioButton">
                      <input
                        type="checkbox"
                        name="fabric"
                        value={option.id}
                        checked={field?.value?.includes(option.id)}
                      />
                      <span>{option.name}</span>
                    </label>
                  );
                })}
              </div>
            ) : (
              <div className={`flex ${boxClasses}`}>
                {options.map(({ id, name }) => (
                  <div
                    className={style[field.value === id ? 'btnClick' : 'btn']}
                    style={isDisabled ? { cursor: 'no-drop', opacity: 0.8 } : { cursor: 'pointer' }}
                    onClick={() => {
                      if (!isDisabled) {
                        setFieldValue(field.name, id);
                        if (name === 'Others') {
                          setOtherDenimColor(true);
                          setOthersSelected(true);
                        } else {
                          setOthersSelected(false);
                          setOtherDenimColor(false);
                          setMainFeildValue('otherDenimColor',null)
                        }
                      }
                      if (field.name === 'productCategory' && !isDisabled)
                        setFieldValue('subCategory', []);
                    }}>
                    <div className={style['box']}>
                      {field.value === id && <Check />}
                      <span>{name}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {touched && error && !otherSelected ? <p className={style.error}>{error}</p> : null}
          </div>
        );
      }}
    </Field>
  );
};
RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

RadioField.defaultProps = {
  options: [],
};
export default RadioField;
