import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Col, Row, Input, Select, Button } from 'antd';
import * as Yup from 'yup';
import style from './style.module.scss';
import { connect } from 'react-redux';
import {
  createNewTopQuality,
  getMaterialData,
  getSupplier,
  updateTopQuality,
} from 'store/actions/FabricQualitiesActions';
import FileUpload from 'components/HOCs/FileUpload';
import Status from '../TopQualities/status';
import { addRunningQualities, getMills as getMillsAction, getRunningQualities } from 'store/actions/UserActions';
import { debounce } from 'lodash';
import actions from 'store/actions';
import store from 'store/store';

const __transformMills = (mills) => {
  if(!mills){
    return []
  }

  return mills.map((mill) => {
    return {
      name: mill.supplierName,
      id: mill.supplierId,
      value: mill.supplierName,
    }
  })
}

const RunningQualitiesForm = ({
  visible = false,
  onClose = () => {},
  getMills,
  mills,
  values,
  setFieldValue,
  addRunningQualitiesApi,
  getRunningQualitiesApi,
  setPage
}) => {
  const [runningQualitiesFile, setRunningQualitiesFile] = useState('');
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    setSuppliers(__transformMills(mills?.data))
  }, [mills?.data])

  const onSelect = (name, data) => {
    setFieldValue('supplierName', name )
    setFieldValue('supplierId', data.id )
  }

  const handleSubmit = () => {
    addRunningQualitiesApi({...values, file: runningQualitiesFile}).then((res) => {
      if(!res.error){
        onClose()
        getRunningQualitiesApi({limit: 10, page: 1})
        setPage(1)
        setFieldValue('supplierId', '')
        setFieldValue('supplierName', '')
        setFieldValue('file', null)
        setRunningQualitiesFile('')
        store.dispatch(
          actions.flash({
            type: "error",
            message: res.payload.data.message,
            duration: 3000
          })
        );
      }
    })
  }

  const debouncedSupplierSearch = useCallback(
    debounce((nextValue) => getMills({search: nextValue}), 350),
    []
  );
  
  return (
    <Drawer
      destroyOnClose
      width={720}
      height={260}
      className={style['runningQuality-drawer']}
      title={'Upload quality'}
      contentWrapperStyle={{height: '287px'}}
      size="large"
      onClose={() => {
        onClose();
      }}
      visible={visible}
    >
      <div>
        <div className='flex justify-between'>
          <div className={`${style['formField']} flex flex-col `}>
            <div className={style['labelWithTick']}>
              Supplier name
              <Status
                isFilled={!!values.supplierName}
              />
            </div>
            <Select
              isSupplier
              name="supplierName"
              label="Supplier"
              placeholder="Search Supplier"
              showSearch
              onSearch={debouncedSupplierSearch}
              onSelect={onSelect}
              options={suppliers}
            />
          </div>

          <div className={`${style['formField']}`}>
            <div className={style['labelWithTick']}>
              Upload CSV file
              <Status
                isFilled={!!values.file}
              />
            </div>
            <div>
              <FileUpload
                file={runningQualitiesFile}
                setFile={setRunningQualitiesFile}
                setFieldValue={setFieldValue}
                name="file"
                accept={'.csv'}
              />
            </div>
          </div>
        </div>
        
        <div className={`${style['footerCtaContainer']} mt-12`}>
          <a
            href={process.env.REACT_APP_RUNNING_QUALITIES_SAMPLE_FORMAT || ''}
            target="_blank"
            className={`${style['formField']} ${style['downloadAnchor']}`}>
              Download sample format
          </a>
          <div className={`${style['formField']}`} onClick={handleSubmit}>
            <Button type='primary' size='large'>Submit</Button>
          </div>
        </div>
      </div>

    </Drawer>
  );
};

const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
  mills: props.users || state.user.mills,
});
const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
  getMills: (data) => dispatch(getMillsAction(data)),
  createNewTopQuality: (data) => dispatch(createNewTopQuality(data)),
  updateTopQuality: (data) => dispatch(updateTopQuality(data)),
  addRunningQualitiesApi: (data) => dispatch(addRunningQualities(data)),
  getRunningQualitiesApi: (data) => dispatch(getRunningQualities(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunningQualitiesForm);
