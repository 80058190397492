import React from 'react';
import BaseEmptyScreen from "components/UI/EmptyScreen"
import Button from 'components/UI/Button/Button';
import { IconPlus } from 'components/UI/Icons';
import CustomersEmpty from 'assets/images/emptyPages/customers-empty.svg';

const EmptyScreen = ({
  handleClick,
  buttonLabel,
  heading,
  description
}) => {
  return <BaseEmptyScreen
    icon={CustomersEmpty}
    heading={heading}
    description={description}
    cta={
      <Button
        category="grid-2 blue-bg"
        type="button"
        onClick={handleClick}
      >
        <IconPlus color="white" />
        <span>{buttonLabel}</span>
      </Button>
    }
  />;
};

export default EmptyScreen;
