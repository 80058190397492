const BRAND_NAME = 'Locofast';

export const PAGE_TITLES = {
  login: () => `Login | ${BRAND_NAME}`,
  forgotPassword: () => `Forgot Password | ${BRAND_NAME}`,
  setNewPassword: () => `Set new password | ${BRAND_NAME}`,

  dashboard: () => `Home | ${BRAND_NAME}`,

  // collections
  collections: () => `Collections | ${BRAND_NAME}`,
  collectionDetails: (collectionName) => `${collectionName} | ${BRAND_NAME}`,

  // designs
  designDetails: (designName) => `${designName} | Summary | ${BRAND_NAME}`,
  designSummary: (designName) => `${designName} | Summary | ${BRAND_NAME}`,
  designQuote: (designName) => `${designName} | Quote | ${BRAND_NAME}`,
  designSampling: (designName) => `${designName} | Sampling | ${BRAND_NAME}`,
  designProduction: (designName) => `${designName} | Production | ${BRAND_NAME}`,
  designMessages: (designName) => `${designName} | Messages | ${BRAND_NAME}`,

  // settings
  settings: () => `Settings | ${BRAND_NAME}`,
  settingsMyProfile: () => `My Profile | ${BRAND_NAME}`,
  settingsBrands: () => `Brands | ${BRAND_NAME}`,
  settingsFactories: () => `Factories | ${BRAND_NAME}`,
  settingsUsers: () => `Users | ${BRAND_NAME}`,
  settingsLFUsers: () => `Locofast Users | ${BRAND_NAME}`,

  quotes: () => `Quotes | ${BRAND_NAME}`,
  sampling: () => `Sampling | ${BRAND_NAME}`,
  production: () => `Production | ${BRAND_NAME}`,
  orders: () => `Orders | ${BRAND_NAME}`,
  messages: () => `Messages | ${BRAND_NAME}`,
  swatchLibrary: () => `Swatch Library | ${BRAND_NAME}`,
  notifications: () => `Notifications | ${BRAND_NAME}`,
  materialLibrary: () => `Material Library | ${BRAND_NAME}`,
  reports: (report) => `${report} | Reports | ${BRAND_NAME}`,
  performance: ()=> `Performance Report | ${BRAND_NAME}`
};
