export const QUOTE_STATUS = {
  WITHDRAWN: -2,
  DRAFT: -1,
  SUBMITTED: 0,
  ACCEPTED: 1,
  RESUBMISSION: 2,
};

export const QUOTE_STATUS_AS_STRING = {
  WITHDRAWN: '-2',
  DRAFT: '-1',
  SUBMITTED: '0',
  ACCEPTED: '1',
  RESUBMISSION: '2',
};

export const QUOTE_STATUS_TO_STRING_FACTORY = {
  '-1': 'Draft',
  '0': 'Shared',
  '1': 'Accepted',
  '2': 'Rejected',
};

export const QUOTE_STATUS_TO_STRING_BRAND = {
  '-2': 'Requests withdrawn',
  '-1': 'Awaited',
  '0': 'Review Pending',
  '1': 'Accepted',
  '2': 'Rejected',
};

export const QUOTE_STATUS_CNAME = {
  '-2': 'requests-withdrawn',
  '-1': 'awaited',
  '0': 'review-pending',
  '1': 'accepted',
  '2': 'rejected',
};

export const REQUEST_OPTIONS = {
  type: {
    '0': 'Open',
    '1': 'Bundled'
  },
  mode: {
    '0': 'CMT',
    '1': 'FOB'
  },
  quantityStr: {
    '0': 'Total quantity',
    '1': 'Size-wise quantity'
  },
  quantity: {
    totalQuantity: '0',
    sizeWiseQuantity: '1',
  },
};

/**
 * MAP_QUOTE_STATUS is used in GET /quotes API for status based filtering
 * NOTE: MAP_QUOTE_STATUS is not related to quote status
 */
export const MAP_QUOTE_FILTER_STATUS = {
  ALL: 0,
  REQUESTED: 1, // Awaited || Requested
  SUBMITTED: 2, // Review-Pending || Submitted
  APPROVED: 3,
  REJECTED: 4,
};

export const MAP_TABS_BRAND = {
  all: {
    name: 'All',
    tabUrl: 'all',
  },
  requested: {
    name: 'Awaited',
    tabUrl: 'awaited',
  },
  submitted: {
    name: 'Review Pending',
    tabUrl: 'review-pending',
  },
  approved: {
    name: 'Approved',
    tabUrl: 'approved',
  },
  rejected: {
    name: 'Rejected',
    tabUrl: 'rejected',
  },
};

export const MAP_TABS_FACTORY = {
  all: {
    name: 'All',
    tabUrl: 'all',
  },
  requested: {
    name: 'Requested',
    tabUrl: 'requested',
  },
  submitted: {
    name: 'Submitted',
    tabUrl: 'submitted',
  },
  approved: {
    name: 'Approved',
    tabUrl: 'approved',
  },
  rejected: {
    name: 'Rejected',
    tabUrl: 'rejected',
  },
};

export const QUOTE_JOURNEY_SUBTITLES = {
  SUBMITTED: "Submitted",
  RECEIVED: "Received",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  AWAITED: "Awaited",
  NOT_REQUESTED: "Pending",
  REQUESTED: "Requested",
  EXPIRED: "Request Expired"
}

export const COMPLETED_JOURNEY = [QUOTE_JOURNEY_SUBTITLES.APPROVED, QUOTE_JOURNEY_SUBTITLES.REJECTED]

export const QUOTE_REQ_EDIT_MODE = {
  NoEdit: 0,
  AddFactories: 1,
  EditDetails: 2,
};

export const STATUS_OPTIONS = [
  { value: "1", label: "Active" },
  { value: "0", label: "InActive" },
]