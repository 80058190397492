import InventoryManagement from 'components/AccountSettings/InventoryManagement';
import EditInventory from 'components/AccountSettings/InventoryManagement/EditInventory';
import { useMemo } from 'react';
import { Route } from 'react-router-dom';
import qs from 'query-string';

const SessionerContainer = (props) => {
  const { token: sessionToken, role: sessionRole } = qs.parse(props.location.search) || {};

  useMemo(() => {
    if (sessionToken) {
      sessionStorage.setItem('sessionToken', sessionToken);
      sessionStorage.setItem('sessionRole', sessionRole);
    }
  }, []);

  return (
    <>
      <Route
        path={'/sessioner/inventory-management/'}
        exact
        render={(routeProps) => <InventoryManagement />}
      />
      <Route
        path={'/sessioner/inventory-management/details/'}
        exact
        render={(routeProps) => <EditInventory />}
      />
    </>
  );
};

export default SessionerContainer;
