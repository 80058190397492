import { Button } from 'antd';
import { INTL_CURRENCY_ID } from 'components/AccountSettings/Customers/constants';
import { blurOnScroll } from 'helpers/formHelper';
import React, { useState } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { required, number } from '../../../../helpers/form-validations';
import Input from '../../../UI/Form/Input/Input';
import './CurrencyFactorModal.scss';

const formName = 'CurrencyForm';

function CurrencyForm({ closeModal, initialValues, updateCurrency, getCurrencyRate }) {
  const [currencyValue, setCurrencyValue] = useState(initialValues);

  const handleSubmit = () => {
    const currencyId = INTL_CURRENCY_ID;
    const valueInInr = currencyValue;
    if (+valueInInr > 0) {
      updateCurrency({ currencyId, valueInInr }).then(() => {
        closeModal();
        getCurrencyRate();
      });
    }
  };

  return (
    <div className="flex-col">
      <Form name={formName} id={formName}>
        <Field
          component={Input}
          validate={[required, number]}
          type="number"
          name="currencyValue"
          label="1 Dollar equals"
          placeholder="Currency Rate"
          rightAddon="Rupees"
          value={initialValues.valueInInr}
          onWheel={blurOnScroll}
          onChange={(e) => setCurrencyValue(e.target.value)}
        />
        <div className="buttonContainer">
          <Button type="link" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default reduxForm({ form: formName }, null)(CurrencyForm);
