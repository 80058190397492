import React, { Component } from "react";
import Input from "../Input/Input";
import './PasswordInput.scss'

class PasswordInput extends Component {
  state = {
    isVisible: false
  };

  handleVisibilityClick() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  render() {
    const { isVisible } = this.state;

    return <Input
      {...this.props}
      type={(isVisible ? "text" : "password")}
      suffixContent={<i onClick={this.handleVisibilityClick.bind(this)}
        className={"fa fa-password " + (isVisible ? "fa-eye" : "fa-eye-slash")}
        style={{
        }}
      ></i>}
      suffixContentWidth="28px"
    />;
  }
}

export default PasswordInput;
