export const LOCO_SUPER = "LOCO_SUPER";
export const CLIENT_SUPER = "CLIENT_SUPER";
export const CLIENT = "CLIENT";
export const FACTORY_ADMIN = "FACTORY_ADMIN";
export const FACTORY_MANAGER = "FACTORY_MANAGER";
export const FABRIC_SUPPLIER = "FABRIC_SUPPLIER";
export const BDE = "BDE";
export const PRODUCT_DEVELOPMENT = "PRODUCT_DEVELOPMENT";
export const PRODUCTION_MERCHANT = "PRODUCTION_MERCHANT";
export const FABRIC_SUPPLIER_ADMIN = "FABRIC_SUPPLIER_ADMIN";
export const FABRIC_CUSTOMER_ADMIN = "FABRIC_CUSTOMER_ADMIN";
export const ACCOUNT_MANAGER = "ACCOUNT_MANAGER";
export const SUPPLIER_MANAGER = "SUPPLIER_MANAGER";
export const SALES_HEAD = "SALES_HEAD";
export const LOGISTICS_MANAGER = 'LOGISTICS_MANAGER'
export const SUPPLY_HEAD = "SUPPLY_HEAD"

export const AUTH_ROLES = {
  1: LOCO_SUPER,
  2: CLIENT_SUPER,
  3: CLIENT,
  4: FACTORY_ADMIN,
  5: FACTORY_MANAGER,
  6: FABRIC_SUPPLIER,
  7: BDE,
  8: PRODUCT_DEVELOPMENT,
  9: PRODUCTION_MERCHANT,
  10: FABRIC_SUPPLIER_ADMIN,
  11: FABRIC_CUSTOMER_ADMIN,
  12: ACCOUNT_MANAGER,
  13: SUPPLIER_MANAGER,
  14: SALES_HEAD,
  15: LOGISTICS_MANAGER,
  16: SUPPLY_HEAD
};

export const USER_ROLES = {
  1: "Admin",
  2: "Brand Admin",
  3: "Brand Manager",
  4: "Factory Admin",
  5: "Factory User",
  6: "Fabric Supply",
  7: "Sales",
  8: "Product Development",
  9: "Production Merchant",
  10: "Fabric Supplier Admin",
  11: "Fabric Customer Admin",
  12: "Account Manager",
  13: "Supplier Manager",
  14: "City Head",
  15: "Logistics Manager",
  16: "Category Manager"
};

export const AuthRoleIds = {
  LOCO_SUPER: 1,
  CLIENT_SUPER: 2,
  CLIENT: 3,
  FACTORY_ADMIN: 4,
  FACTORY_MANAGER: 5,
  FABRIC_SUPPLIER: 6,
  BDE: 7,
  PRODUCT_DEVELOPMENT: 8,
  PRODUCTION_MERCHANT: 9,
  FABRIC_SUPPLIER_ADMIN: 10,
  FABRIC_CUSTOMER_ADMIN: 11,
  ACCOUNT_MANAGER: 12,
  SUPPLIER_MANAGER: 13,
  SALES_HEAD: 14,
  LOGISTICS_MANAGER: 15,
  SUPPLY_HEAD: 16
};
