import React from 'react';
import classNames from 'classnames';
import { ReactComponent as InfoImg } from "../../../assets/images/action/ic-info-black.svg";
import { ReactComponent as ArrowImg } from "../../../assets/images/navigation/arrow-down.svg";
import { ReactComponent as ArrowLongImg } from "../../../assets/images/navigation/ic-arrow-long.svg";
import { ReactComponent as ArrowBroadImg } from "../../../assets/images/navigation/ic-arrow-back.svg";
import { ReactComponent as CheckMarkImg } from "../../../assets/images/action/check-mark.svg";
import { ReactComponent as CloseImg } from "../../../assets/images/action/close.svg";
import { ReactComponent as LocationImg } from "../../../assets/images/image/pin-filled.svg";
import { ReactComponent as FactoryMachineImg } from "../../../assets/images/image/ic_machine.svg";
import { ReactComponent as StarImg } from "../../../assets/img/Quote/star.svg";
import { ReactComponent as SaveImg } from "../../../assets/images/action/save.svg";
import { ReactComponent as SubmitImg } from "../../../assets/images/action/submit.svg";
import { ReactComponent as EditPencilImg } from "../../../assets/images/action/ic-edit-pencil.svg";
import { ReactComponent as PlusImg } from "../../../assets/images/dashboard/plus.svg";
import { ReactComponent as EmailImg } from '../../../assets/images/communication/mail.svg';
import { ReactComponent as PhoneImg } from '../../../assets/images/communication/phone.svg';
import { ReactComponent as HelpImg } from '../../../assets/images/action/ic-nav-help.svg';
import { ReactComponent as FilterImg } from '../../../assets/images/action/filter.svg';
import { ReactComponent as MagnifierImg } from '../../../assets/images/action/ic-search-grey.svg';
import { ReactComponent as CalendarImg } from '../../../assets/images/image/calendar.svg';
import { ReactComponent as AttachFileImg } from '../../../assets/images/editor/ic-attach-file-grey.svg';
import { ReactComponent as ArrowDropDownImg } from '../../../assets/images/navigation/arrow_drop_down_blue.svg';
import { ReactComponent as ExpandMoreImg } from '../../../assets/images/navigation/ic-expand-more.svg';
import { ReactComponent as CopyImg } from '../../../assets/images/action/ic-copy-blue.svg';
import { ReactComponent as AddAvatarImg } from '../../../assets/images/action/add-avatar.svg';
import { ReactComponent as UnlinkImg } from '../../../assets/images/action/ic-unlink.svg';
import { ReactComponent as ArrowChevronImg } from "assets/images/navigation/ic-chevron-right.svg";
import './index.scss';

// available colors(default=black): grey, error
export const IconInfo = ({
  color,
  style,
}) => {
  return <InfoImg
    className={classNames("loco-icon loco-icon--info",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red
export const IconArrow = ({
  color,
  style,
  direction,
}) => {
  return <ArrowImg
    className={classNames("loco-icon loco-icon--arrow",
      color && ('loco-icon--color-' + color),
      direction && ('loco-icon--dir-' + direction),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red
export const IconArrowBroad = ({
  color,
  style,
  direction,
}) => {
  return <ArrowBroadImg
    className={classNames("loco-icon loco-icon--arrow-broad",
      color && ('loco-icon--color-' + color),
      direction && ('loco-icon--dir-' + direction),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red
export const IconArrowLong = ({
  color,
  style,
  direction,
}) => {
  return <ArrowLongImg
    className={classNames("loco-icon loco-icon--arrow-long",
      color && ('loco-icon--color-' + color),
      direction && ('loco-icon--dir-' + direction),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red, blue
export const IconCheckMark = ({
  color,
  style,
}) => {
  return <CheckMarkImg
    className={classNames("loco-icon loco-icon--check-mark",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red, blue
export const IconClose = ({
  color,
  style,
}) => {
  return <CloseImg
    className={classNames("loco-icon loco-icon--close",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red, blue
export const IconLocation = ({
  color,
  style,
}) => {
  return <LocationImg
    className={classNames("loco-icon loco-icon--location",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red, blue
export const IconFactoryMachine = ({
  color,
  style,
}) => {
  return <FactoryMachineImg
    className={classNames("loco-icon loco-icon--factory-machine",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

// available colors(default=black): green, red, blue
export const IconStar = ({
  color,
  style,
}) => {
  return <StarImg
    className={classNames("loco-icon loco-icon--star",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconSave = ({
  color,
  style,
}) => {
  return <SaveImg
    className={classNames("loco-icon loco-icon--save",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconSubmit = ({
  color,
  style,
}) => {
  return <SubmitImg
    className={classNames("loco-icon loco-icon--submit",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconEditPencil = ({
  color,
  style,
}) => {
  return <EditPencilImg
    className={classNames("loco-icon loco-icon--edit-pencil",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconPlus = ({
  color,
  style,
}) => {
  return <PlusImg
    className={classNames("loco-icon loco-icon--plus",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}


export const IconEmail = ({
  color,
  style,
}) => {
  return <EmailImg
    className={classNames("loco-icon loco-icon--email",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconPhone = ({
  color,
  style,
}) => {
  return <PhoneImg
    className={classNames("loco-icon loco-icon--phone",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconHelp = ({
  color,
  style,
}) => {
  return <HelpImg
    className={classNames("loco-icon loco-icon--help",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconFilter = ({
  color,
  style,
}) => {
  return <FilterImg
    className={classNames("loco-icon loco-icon--filter",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconMagnifier = ({
  color,
  style,
}) => {
  return <MagnifierImg
    className={classNames("loco-icon loco-icon--magnifier",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconCalendar = ({
  color,
  style,
}) => {
  return <CalendarImg
    className={classNames("loco-icon loco-icon--calendar",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconAttachFile = ({
  color,
  style,
}) => {
  return <AttachFileImg
    className={classNames("loco-icon loco-icon--attach-file",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconArrowDropDown = ({
  color,
  style,
}) => {
  return <ArrowDropDownImg
    className={classNames("loco-icon loco-icon--arrow-drop-down",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconExpandMore = ({
  color,
  style,
}) => {
  return <ExpandMoreImg
    className={classNames("loco-icon loco-icon--expand-more",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconCopy = ({
  color,
  style,
}) => {
  return <CopyImg
    className={classNames("loco-icon loco-icon--copy",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconAddAvatar = ({
  color,
  style,
}) => {
  return <AddAvatarImg
    className={classNames("loco-icon loco-icon--add-avatar",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconUnlink = ({
  color,
  style,
}) => {
  return <UnlinkImg
    className={classNames("loco-icon loco-icon--unlink",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}

export const IconArrowChevron = ({
  color,
  style,
}) => {
  return <ArrowChevronImg
    className={classNames("loco-icon loco-icon--arrow-chevron",
      color && ('loco-icon--color-' + color),
    )}
    style={style}
  />;
}
