import { Button } from 'antd';
import {
  COUNTRY_TYPE,
  FABRIC_TYPE,
  FABRIC_TYPE_KEY,
  SUPPLIER_CATEGORY_HASH,
  SUPPLIER_CATEGORY_HASH_KEY,
  SUPPLIER_TYPE
} from 'constants/Global';
import { getFileName } from 'helpers/getNameFromUrl';
import moment from 'moment';
import React from 'react';
import Heading from './EditMillModal';
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import style from '../../TopQualities/style.module.scss'

const FlexWrapper = ({ children }) => <div className="flex-wrapper">{children}</div>;

const WithInput = ({
  readOnly = false,
  label,
  style,
  inputStyle = {},
  type = 'text',
  onChange,
  id,
  value,
  isError = false,
  required = false,
  isDocument = false,
  ...props
}) => (
  <div className="ptb-12" style={isDocument ? { flex: 1, width: '50%' } : { flex: 1 }}>
    <div className="label__class pb-5">{label}</div>
    {isDocument ? (
      <Button
        className="inputImageAttachment"
        style={{
          ...inputStyle,
          border: isError ? '1px solid var(--fill-error)' : ''
        }}
        href={value}>
        {`${value ? getFileName(value) : 'Not Available'}`}
      </Button>
    ) : (
      <input
        type="text"
        className={`${isError ? 'inputerror inputImageAttachment' : 'inputImageAttachment'}`}
        style={{
          ...inputStyle,
          border: isError ? '1px solid var(--fill-error)' : ''
        }}
        readOnly={readOnly}
        value={value}
        onChange={(event) => onChange(id, event.target.value)}
        {...props}
      />
    )}
  </div>
);
const markODA = (supplierId, addressId, isODA,updateODA) => {
  const data = { orgId: supplierId, addressId: addressId, isOda: Number(isODA), isSupplier: 1 };
  updateODA(data);
};
const ImageInput = ({ file, label = 'GST Certificate', id, value, required, onChange }) => (
  <div className="ptb-12" style={{ flex: 1 }}>
    <div className="label__class pb-5">{label}</div>
    <input id={id} type="image" type="file" name="myfile" />
  </div>
);

const Sections = ({ millData, contactPerson,updateODA }) => {
  return (
    <>
      {' '}
      <section className="details-card">
        <Heading label="Basic Details" id={millData?.id} edit={true} data={millData} />
        <div className="card-body">
          <div className="w-full">
            <div className="label__class pb-5">Supplier Type</div>
            <div className="wrap">
              <div className="pill" key={millData.supplierType}>
                {SUPPLIER_TYPE[millData.supplierType]}
              </div>
            </div>
          </div>
          <FlexWrapper>
            <WithInput
              label="GSTIN"
              id="gstin"
              value={millData?.gstNumber}
              readOnly={true}
              required
            />

            <WithInput
              label="Contact Person"
              id="owner"
              value={contactPerson}
              readOnly={true}
              required
            />
          </FlexWrapper>
          <FlexWrapper>
            <WithInput
              label="Legal Name (As Registered for GST)"
              id="name"
              value={millData?.companyName}
              readOnly={true}
              required
            />

            <WithInput
              label="GST Certificate"
              id="gstCertificate"
              value={millData?.gstCertificate?.url}
              readOnly={true}
              isDocument={true}
              required
            />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Phone Number"
              value={millData?.contactNumber}
              id="phone"
              readOnly={true}
              required
            />
            <WithInput
              label="Email ID"
              value={millData?.email}
              readOnly={true}
              id="email"
              required
            />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Company Website"
              id="owner"
              value={millData?.website}
              readOnly={true}
              required
            />

            <div style={{ flex: '1' }} />
          </FlexWrapper>
        </div>
      </section>
      <section className="details-card">
        <Heading label="Account details" id={millData?.id} data={millData} />
        <div className="card-body">
          <FlexWrapper>
            <div style={{ flex: '1' }} />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Account Number"
              id="bankAccountNumber"
              value={millData?.accountNumber}
              readOnly={true}
            />
            <WithInput
              label="Account Holder Name"
              id="accountName"
              value={contactPerson}
              readOnly={true}
            />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Cancelled Cheque"
              id="cancelledCheque"
              value={millData?.cancelledCheque?.url}
              readOnly={true}
              isDocument={true}
            />
            <WithInput label="IFSC Code" id="bankIfsc" readOnly={true} value={millData?.ifsc} />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Payment Terms"
              id="paymentTerms"
              value={millData?.paymentTerms}
              readOnly={true}
            />
            <WithInput label="Bank Name" id="bankName" value={millData?.bankName} readOnly={true} />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Annual Turnover (in Crores)"
              id="annualTurnover"
              value={millData?.annualTurnover}
              readOnly={true}
            />
            <WithInput
              label="Annual Turnover Document"
              id="annualTurnoverDocs"
              value={millData?.annualTurnoverDocs?.url}
              readOnly={true}
              isDocument={true}
            />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Payable days (Calendar days)"
              id="creditPeriod"
              value={millData?.creditPeriod}
              readOnly={true}
            />
            <WithInput
              label="Annual Turnover Date"
              id="annualTurnoverDate"
              value={millData.annualTurnoverDate? moment(millData.annualTurnoverDate).format("YYYY-MM-DD") : ''}
              readOnly={true}
            />
          </FlexWrapper>
        </div>
      </section>
      <section className="details-card">
        <Heading label="Specialisation Details" edit={false} />
        <div className="card-body">
          <FlexWrapper>
            <div style={{ flex: '1' }} />
          </FlexWrapper>

          <FlexWrapper>
            <div className="fabricp-pills w-full">
              <div className="label__class pb-5">Fabric Type</div>

              <div className="wrap">
                {+millData?.fabricType !== FABRIC_TYPE_KEY.KNITS_WOVEN ? (
                  <div className="pill">{FABRIC_TYPE[millData.fabricType]}</div>
                ) : (
                  <>
                    <div className="pill">{FABRIC_TYPE[FABRIC_TYPE_KEY.KNITS]}</div>
                    <div className="pill">{FABRIC_TYPE[FABRIC_TYPE_KEY.WOVEN]}</div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="label__class pb-5">Services</div>
              <div className="wrap">
                {millData?.productionTypes?.map((type) => (
                  <div className="pill" key={type.id}>
                    {type.name}
                  </div>
                ))}
              </div>
            </div>
          </FlexWrapper>

          <FlexWrapper>
            <div className="certificate-pills w-full">
              <div className="label__class pb-5">Suitable for</div>
              <div className="wrap">
                {millData?.category !== SUPPLIER_CATEGORY_HASH_KEY.WEAVING_PROCESSING ? (
                  <div className="pill" key={millData.category}>
                    {SUPPLIER_CATEGORY_HASH[millData.category]}
                  </div>
                ) : (
                  <>
                    <div className="pill" key={millData.category}>
                      {SUPPLIER_CATEGORY_HASH[SUPPLIER_CATEGORY_HASH_KEY.WEAVING]}
                    </div>
                    <div className="pill" key={millData.category}>
                      {SUPPLIER_CATEGORY_HASH[SUPPLIER_CATEGORY_HASH_KEY.PROCESSING]}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="label__class pb-5">Loom Type</div>
              <div className="wrap">
                {millData?.loomTypes?.map((type) => (
                  <div className="pill" key={type.id}>
                    {type.name}
                  </div>
                ))}
              </div>
            </div>
          </FlexWrapper>

          <div className="certificate-pills">
            <div className="label__class pb-5">Certifications</div>
            <div className="wrap">
              {millData?.certifications?.length ? (
                millData?.certifications?.map((certificate) => (
                  <div className="pill" key={certificate?.id}>
                    {certificate?.name}
                  </div>
                ))
              ) : (
                <p>No certifications are there</p>
              )}
            </div>
          </div>
          <div className="category-pills">
            <div className="label__class pb-15">Category Type and Width</div>
            {millData?.fabricCategory.map((cate, index) => (
              <div className="pill-container" key={index}>
                {index !== 0 && <br />}
                <div className="pill wrap">{cate?.categoryName}</div>
                <div className="wrap">
                  {cate?.widths?.map((width) => (
                    <div className="pill-2" key={width?.id}>
                      {width?.name} inch
                    </div>
                  ))}
                </div>
                <div className="wrap">
                  {cate?.subCategories?.map((subCate) => (
                    <div className="pill-3 mr-sm" key={subCate?.id}>
                      {subCate?.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="details-card">
        <Heading label="Address" id={millData?.id} data={millData} />
        <div className="card-body">
          <FlexWrapper>
            <div style={{ flex: '1' }} />
          </FlexWrapper>

          <FlexWrapper>
            <WithInput
              label="Address Line 1"
              id="addressLine1"
              value={millData?.addresses[0]?.addressLine1}
              readOnly={true}
            />
            <WithInput
              label="Country"
              id="country"
              readOnly={true}
              value={
                millData?.addresses[0]?.countryType === COUNTRY_TYPE.INDIA
                  ? 'India'
                  : 'International'
              }
            />
          </FlexWrapper>
          <FlexWrapper>
            <WithInput
              label="Address Line 2"
              id="addressLine2"
              value={millData?.addresses[0]?.addressLine2}
              readOnly={true}
            />
            <WithInput
              label="City"
              id="city"
              readOnly={true}
              value={millData?.addresses[0]?.city}
            />
          </FlexWrapper>
          <FlexWrapper>
            <WithInput
              label="Landmark"
              id="landmark"
              readOnly={true}
              value={millData?.addresses[0]?.landmark}
            />
            <WithInput
              label="State"
              id="state"
              readOnly={true}
              value={millData?.addresses[0]?.state}
            />
          </FlexWrapper>
          <FlexWrapper>
            <WithInput
              label="Pin Code"
              id="pincode"
              readOnly={true}
              value={millData?.addresses[0]?.pinCode}
            />
            <div style={{ flex: 1, marginTop: 32 }}>
              <StatusToggler
                label={'Is this address outside delivery area?'}
                onFalseLabel={'No'}
                onTrueLabel={'Yes'}
                style={style}
                condition={millData?.addresses[0]?.isOda}
                onChange={(e) => markODA(millData?.supplierId, millData.addresses[0]?.id, e,updateODA)}
              />
            </div>
          </FlexWrapper>
        </div>
      </section>
    </>
  );
};

export default Sections;
