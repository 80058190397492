import React, { Component } from 'react';
import randomColor from 'randomcolor';
import './ProfileAvatar.scss';
import { colors } from "./colors";

export class ProfileAvatar extends Component {
  state = {
    bgColor: 'var(--fill-primary-medium)'
  };

  static getDerivedStateFromProps(nextProps) {
    const updatedProps = {};

    if (nextProps.alias) {
      updatedProps.bgColor = nextProps.alias;
    } else if (nextProps.id) {
      updatedProps.bgColor = randomColor({
        luminosity: 'dark',
        seed: nextProps.id,
      });
    }

    return updatedProps;
  }

  getInitials(name) {
    const { initialLength = 1 } = this.props;
    return ((name && name.trim()) || 'Unnamed')
      .split(/ +/g)
      .filter((word, index) => word && (index < initialLength))
      .map(word => word[0].toUpperCase())
      .join('');
  }

  render() {
    const { name, url, tooltip, size, staticColor, id } = this.props;
    const { bgColor } = this.state;

    return <div
      className={`profile-avatar avatar ${staticColor ? "static-color" : ""}`}
      style={{
        backgroundColor: (url ? '' : staticColor ? colors(id) : bgColor),
        zoom: (size > 0 ? size / 40 : 1),
      }}
      {...(tooltip ? { 'data-tip': tooltip } : null)}
    >
      {
        url
          ? <div className="img" style={{ backgroundImage: `url(${url})` }}></div>
          : <div className="initials">{this.getInitials(name)}</div>
      }
    </div>;
  }
}
