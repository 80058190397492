import React, { useState } from "react";
import { Drawer, Col, Row } from "antd";
import { Form, Formik } from "formik";
import FieldInput from "../TopQualities/FieldInput";
import Select from "../TopQualities/Select";
import * as Yup from "yup";
import {
  CATEGORY_TYPES,
  COUNTRY_TYPES,
  CUSTOMER_KEYS,
  DEFAULT_DOMESTIC_MARGIN,
  DEFAULT_INTERNATIONAL_MARGIN,
  MAX_WIDTH,
  MIN_WIDTH,
  PRODUCT_TYPES,
} from "../TopQualities/config";
import style from "../TopQualities/style.module.scss";
import { connect } from "react-redux";
import {
  getMaterialData,
} from "store/actions/FabricQualitiesActions";
import {
  createNewLiveQuality,
  updateLiveQuality,
} from "store/actions/LiveRates";
import RadioField from "../TopQualities/RadioField";
import Status from "../TopQualities/status";
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import RadioButtons from "../TopQualities/RadioButtons";
import MultiSelectTextInput from "../TopQualities/MultiselectTextInput";
import { preventNonNumericalInput } from "helpers/formHelper";
import { UPTO_TWO_DECIMAL_REGEX } from "../Customers/CustomerDetailsPage/AnnualTurnover/config";

const LiveQualitiesForm = ({
  isUpdate,
  initialFormData,
  visible = false,
  onClose = () => {},
  getMaterialData,
  createNewLiveQuality,
  updateLiveQuality,
  materialData,
  reload,
}) => {
  const [inputValue, setInputValue] = useState('');
  const validationSchema = Yup.object().shape(
    {
      productCategory: Yup.number().required("Please select any one option"),

      fabricType: Yup.number().required("Please select any one option"),

      loomType: Yup.array().required("Please select any one option").min(1),

      widths: Yup.array().required("Required").min(1),

      epi: Yup.number()
        .positive()
        .min(20, "Minimum value 20")
        .max(200, "Maximum value 200")
        .required("Required"),

      ppi: Yup.number()
        .positive()
        .min(20, "Minimum value 20")
        .max(200, "Maximum value 200")
        .required("Required"),

      x: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, "Format: 30 or 2 / 30")
        .max(4, 'Please enter upto 4 characters')
        .required("Required"),

      y: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, "Format: 30 or 3 / 30")
        .max(4, 'Please enter upto 4 characters')
        .required("Required"),

      requirementType: Yup.string().required("Please select any one option"),
      subCategory: Yup.string().required("Please select any one option"),
      countryType: Yup.string().required("Required"),
      domesticMargin: Yup.number().min(1, 'Minimum margin is 1%')
      .max(30, 'Minimum margin is 30%')
      .when('countryType', {
        is: (value) => value === CUSTOMER_KEYS.Domestic || value === CUSTOMER_KEYS.Both,
        then: Yup.number().required("Required").typeError("Required")
        .test('maxDigitsAfterDecimal', 'Maximum decimal alowed is 2', (number) =>
        UPTO_TWO_DECIMAL_REGEX.test(number),
      ),
        otherwise: Yup.number().nullable(),
      }),
      internationalMargin: Yup.number().min(1, 'Minimum margin is 1%')
      .max(30, 'Minimum margin is 30%')
        .when('countryType', {
        is: (value) => value === CUSTOMER_KEYS.International || value === CUSTOMER_KEYS.Both,
        then: Yup.number().required("Required").typeError("Required")
        .test('maxDigitsAfterDecimal', 'Maximum decimal alowed is 2', (number) =>
          UPTO_TWO_DECIMAL_REGEX.test(number),
        ),
        otherwise: Yup.number().nullable(),
      })
    },
    [
      ["x", "y"],
      ["epi", "ppi"],
    ]
  );

  const onSubmitForm = (values) => {
    const form = {
      productTypeId: values.fabricType,
      productCategoryId: values.productCategory,
      requirementType: Number(values.requirementType),
      loomTypeIds: values.loomType,
      threadCount: {
        x: values.x,
        y: values.y,
      },
      construction: {
        epi: values.epi,
        ppi: values.ppi,
      },
      widths: values?.widths.map(({value}) => value),
      productSubCategoryId: [Number(values.subCategory)],
      customerTypeVisibility: Number(values?.countryType),
      isWhatsappEnabled: values?.isWhatsappNotification ? 1 : 0,
    };
    if(values.countryType === CUSTOMER_KEYS.Domestic) {
      form.domesticMarginPercentage = values.domesticMargin;
    }
    else if(values.countryType === CUSTOMER_KEYS.International) {
      form.internationalMarginPercentage = values.internationalMargin;
    }
    else {
      form.domesticMarginPercentage = values.domesticMargin;
      form.internationalMarginPercentage = values.internationalMargin;
    }
    if (values.fabricType === initialFormData.fabricType)
      delete form.productTypeId;
    if (values.productCategory === initialFormData.productCategory)
      delete form.productCategoryId;
    if (isUpdate && values.requirementType === initialFormData.requirementType)
      delete form.requirementType;
    if (values.loomType === initialFormData.loomType) delete form.loomTypeIds;
    if (values.x === initialFormData.x && values.y === initialFormData.y)
      delete form.threadCount;
    if (
      values.epi === initialFormData.epi &&
      values.ppi === initialFormData.ppi
    )
      delete form.construction;
    if (values.widths === initialFormData.widths) delete form.widths;
    if (values.subCategory === initialFormData.subCategory)
      delete form.productSubCategoryId;
    if(values.countryType === initialFormData.countryType) delete form.customerTypeVisibility;
    if(isUpdate && (values.isWhatsappNotification === initialFormData.isWhatsappNotification)) delete form.isWhatsappEnabled;
    if (values.domesticMargin === initialFormData.domesticMargin) delete form.domesticMarginPercentage;
    if (values.internationalMargin === initialFormData.internationalMargin) delete form.internationalMarginPercentage;
    
    if (isUpdate) {
      form.id = initialFormData.id;
      updateLiveQuality(form).then((res) => {
        if (res) {
          reload();
          onClose();
        }
      });
    } else {
      createNewLiveQuality(form).then((res) => {
        if (res) {
          reload();
          onClose();
        }
      });
    }
  };
  return (
    <Drawer
      destroyOnClose
      width={700}
      className={style["pdc-drawer"]}
      title={isUpdate ? "Edit qualities" : "Add qualities"}
      size="large"
      onClose={() => {
        onClose();
      }}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Formik
        enableReinitialize
        initialValues={{ ...initialFormData }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}
      >
        {({ handleSubmit, touched, errors, dirty, values, setFieldValue, setValues }) => {
         const widthError = values?.widths?.map(({ value }) => +value)
         .some((element) => element < MIN_WIDTH || element > MAX_WIDTH);
         const isLocofastMarginCheckFilled = (value) => {
           switch (value) {
              case CUSTOMER_KEYS.Domestic:
                return !!values.domesticMargin && !errors.domesticMargin;
              case CUSTOMER_KEYS.International:
                return !!values.internationalMargin && !errors.internationalMargin;
              case CUSTOMER_KEYS.Both:
                return !!values.domesticMargin && !errors.domesticMargin
                 && !!values.internationalMargin && !errors.internationalMargin;
              default: return false;
            }
          };
          const disableCondition = !dirty || (dirty && Object.keys(errors).length > 0) || widthError;
          return (
            <Form className={style.form}>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="fabricType"
                    label="Fabric type"
                    options={PRODUCT_TYPES}
                  />
                </Col>

                <div style={{ paddingTop: "3px", width: "50%" }}>
                  <p className="flex justify-between">
                    <span className={style.name}>Thread Count</span>
                    <Status
                      isFilled={
                        !errors?.x &&
                        !!values?.x?.length &&
                        !errors?.y &&
                        !!values?.y?.length
                      }
                    />
                  </p>
                  <div className='flex'>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="x" placeholder="e.g. 120" type="text" />
                    </Col>
                    <div className={style["cross"]}>X</div>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="y" placeholder="e.g. 120" type="text" />
                    </Col>
                  </div>
                </div>
              </Row>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="productCategory"
                    label="Category"
                    options={CATEGORY_TYPES}
                  />
                </Col>

                <div style={{ paddingTop: "3px", width: "50%"}}>
                  <p className="flex justify-between">
                    <span className={style.name}>Construction</span>
                    <Status
                      isFilled={
                        !errors?.epi &&
                        !!values?.epi &&
                        !errors?.ppi &&
                        !!values?.ppi
                      }
                    />
                  </p>
                  <div className='flex'>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="epi" placeholder="EPI" type="number" onKeyPress={preventNonNumericalInput}/>
                    </Col>
                    <div className={style["cross"]}>X</div>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="ppi" placeholder="PPI" type="number" onKeyPress={preventNonNumericalInput}/>
                    </Col>
                  </div>
                </div>
              </Row>

              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <Select
                    isRequired
                    isGlobal
                    fieldKey="subCategoryId"
                    fieldValue="subCategoryName"
                    name="subCategory"
                    label="Sub-Category"
                    placeholder="Select Sub-Category"
                    options={
                      materialData.productSubCategory[
                        String(values?.productCategory)
                      ]
                    }
                  />
                </Col>

                <Col className={style["form-column"]} span={12}>
                <MultiSelectTextInput
                  isRequired
                  name="widths"
                  label="Width"
                  placeholder="Enter Width"
                  type="number"
                  isUnit="inch"
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  minValue={MIN_WIDTH}
                  maxValue={MAX_WIDTH}
                  isError={widthError}
                  />
                </Col>
              </Row>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <Select
                    isGlobal
                    fieldKey="id"
                    fieldValue="name"
                    isRequired
                    name="requirementType"
                    label="Requirement type"
                    placeholder="Select Requirement type"
                    options={materialData.requirementType}
                    isDisabled
                  />
                </Col>

                <Col className={style['form-column']} span={12}>
                  <RadioButtons
                    name="countryType"
                    label="Visible to"
                    isRequired
                    options={COUNTRY_TYPES}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        countryType: e.target.value,
                        ...(!isUpdate && {domesticMargin: DEFAULT_DOMESTIC_MARGIN}),
                        ...(!isUpdate && {internationalMargin: DEFAULT_INTERNATIONAL_MARGIN}),
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="loomType"
                    label="Loom type"
                    options={materialData.loomType}
                    isUpdate={false}
                    isMultipleAllowed
                  />
                </Col>
                {values.countryType && <div className={style.margin}>
                  <div className="flex justify-between">
                  <div className={style["bold-heading"]}>Locofast cash margin</div>
                    <Status
                      isFilled={isLocofastMarginCheckFilled(values.countryType)}
                    />
                  </div>
                  <FieldInput
                    name={values.countryType === CUSTOMER_KEYS.International ? "internationalMargin" : "domesticMargin"}
                    label={`${values.countryType === CUSTOMER_KEYS.International ? "International" : "Domestic"} customers`}
                    placeholder="Enter margin percentage"
                    type="number"
                    isUnit="%"
                  />
                  </div>}
              </Row>
              <Row className={style['form-row']} gutter={16}>
                <Col className={style['form-column']}>
                  <div className="flex flex-col mt-4">
                    <StatusToggler
                      label={'Whatsapp notification'}
                      style={style}
                      onFalseLabel={'No'}
                      onTrueLabel={'Yes'}
                      condition={values.isWhatsappNotification}
                      onChange={() => {
                        setFieldValue('isWhatsappNotification', !values.isWhatsappNotification);
                      }}
                    />
                  </div>
                </Col>
                <Col className={style['form-column']}>
                {(values.countryType === CUSTOMER_KEYS.Both) && (<div className={style.internationalMargin}>
                  <FieldInput
                    name="internationalMargin"
                    label="International customers"
                    placeholder="Enter margin percentage"
                    type="number"
                    isUnit="%"
                  />
                </div>)}
                  </Col>
              </Row>
              <Row className={style["form-row"]} gutter={10}>
              <Col className={style["form-column"]} span={12}>
              </Col>
                    <Col className={style["form-column"]} span={12}>
                      <div className={style["form-button"]}>
                        {isUpdate && (
                          <button
                            type="button"
                            className={style.cancel}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          type="submit"
                          disabled={disableCondition}
                          className={
                            disableCondition ? style.saveDisabled : style.save
                          }
                          style={isUpdate ? { width: "134px" } : { width: "100%" }}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
});
const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
  createNewLiveQuality: (data) => dispatch(createNewLiveQuality(data)),
  updateLiveQuality: (data) => dispatch(updateLiveQuality(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveQualitiesForm);
