export const transformUpdateData = (data) => {
  const { name,
    web_url: webUrl,
    gst_number: gstNumber,
    image,
    id,
    brandAddress,
    featureCollaboration,
    featureDashboard,
    featureFactoryManagement,
    featureLocofastSuppliers,
    featureMaterialLibrary,
    featureNotifications,
    featureSwatchLibrary,
    featureUserManagement,
    featureBrandReports,
    featureTnaApproval,
  } = data;
  const address = brandAddress.map(({ addressLine1, addressLine2, city, state, pinCode }) => ({
    address_line: `${addressLine1} \n ${addressLine2}`,
    city,
    state,
    pinCode
  }));
  return {
    name,
    webUrl,
    gstNumber,
    image: { imgUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_BRAND_LOGO_PATH}/${id}.png`, name: `${id}.png`, size: '0' },
    address,
    featureCollaboration,
    featureDashboard,
    featureFactoryManagement,
    featureLocofastSuppliers,
    featureMaterialLibrary,
    featureNotifications,
    featureSwatchLibrary,
    featureUserManagement,
    featureBrandReports,
    featureTnaApproval,
  }
}

export const getAddressOneLineObj = ({
  addressLine1,
  addressLine2,
  city,
  state,
  pinCode,
}) => ({
  addressLines: (addressLine1 || '') + '\n' + (addressLine2 || ''),
  city,
  state,
  pinCode,
});

export const prependFeature = (features) => !features
  ? features
  : ({
    featureDashboard: features.dashboard,
    featureSwatchLibrary: features.swatchLibrary,
    featureFactoryManagement: features.factoryManagement,
    featureLocofastSuppliers: features.locofastSuppliers,
    featureUserManagement: features.userManagement,
    featureNotifications: features.notifications,
    featureCollaboration: features.collaboration,
    featureMaterialLibrary: features.materialLibrary,
    featureBrandReports: features.brandReports,
    featureTnaApproval: features.tnaApproval,
  });

  /**
   * Copies the brand features from source to destination
   *
   * NOTE: it is also used to initialize the features in a object as 0 (false)
   *
   * @param {object} from Source object
   * @param {object} to Destination object
   */
export const copyBrandFeatures = (from, to) => {
  to.featureCollaboration = from.featureCollaboration || 0;
  to.featureDashboard = from.featureDashboard || 0;
  to.featureFactoryManagement = from.featureFactoryManagement || 0;
  to.featureLocofastSuppliers = from.featureLocofastSuppliers || 0;
  to.featureMaterialLibrary = from.featureMaterialLibrary || 0;
  to.featureNotifications = from.featureNotifications || 0;
  to.featureSwatchLibrary = from.featureSwatchLibrary || 0;
  to.featureUserManagement = from.featureUserManagement || 0;
  to.featureBrandReports = from.featureBrandReports || 0;
  to.featureTnaApproval = from.featureTnaApproval || 0;
};
