import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { IconEditPencil, IconExpandMore } from 'components/UI/Icons';
import Button from 'components/UI/Button/Button';
import history from "utilites/history";

const OrgRow = ({
  path,
  org,
  gridTemplateColumns,
  rowRenderer = (org) => null,
  rowDetailsRenderer = (org) => null,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
  children,
  height,
  width,
  style
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const expandMoreClickHandler = useCallback(() => {
    setExpanded(!isExpanded);
    onOrgExpandChange(!isExpanded);
  }, [isExpanded, onOrgExpandChange]);

  return <div
    className={classnames("org-settings-row", {
      'org-settings-row--expanded': isExpanded,
    })}

    //passing obj as a prop to the history.push()
    onClick={() => history.push({
      pathname: `/factory/settings/customers/${org.id}`,
    })}
    style={style}
  >
    <div
      className="org-settings-row__header"
      style={{
        gridTemplateColumns,
      }}
    >
      {rowRenderer(org)}
    </div>
  </div>;
};

export default OrgRow;
