import React from "react";
import style from "../TopQualities/style.module.scss";
import constructionImg from "assets/images/fabricQualities/Construction.svg";
import threadCountImg from "assets/images/fabricQualities/ThreadCount.svg";
import { Tooltip } from "antd";
import { FABRIC_TYPE, REQUIREMENT_TYPES } from 'constants/Global';
import { FABRIC_TYPES } from '../TopQualities/config';
import { __computeConstructionAndThreadCount } from 'utilites/utils';

export const columnsData = (
    { loomType, suppliers, products },
  ) => {
    return [
        {
          title: "Supplier name",
          dataIndex: "supplierName",
          filters: suppliers,
          render: (supplierName) => {
            if (!supplierName) return "--";
            return supplierName;
          },
        },
        {
          title: "Fabric type",
          dataIndex: "productTypeId",
          render: (value) => {
            return <div className={style["text-wrapper"]}>
              {FABRIC_TYPE[value]}
            </div>;
          },
        },
        {
          title: "Product name",
          dataIndex: "productName",
          filters: products,
          render: (value) => {
            return <div className={style["text-wrapper"]}>{value}</div>;
          },
        },
        {
          title: "Product type",
          dataIndex: "requirementType",
          render: (value) => {
            return <div className={style["text-wrapper"]}>
              {REQUIREMENT_TYPES[value]}
            </div>;
          },
        },
        {
          title: "Loom-type",
          dataIndex: "loomTypeId",
          filters: loomType,
          render: (valueId) => {
            if (!loomType) return "--";
            const loomTypeName = loomType.map((data) => {
              return data.value === valueId && data.text;
            });
            return loomTypeName;
          },
        },
        {
          title: "Specifications",
          render: (value, record) => {
            const { threadCount, construction, width } = record || {};
            return (
              <>
                <div className={style["specification-column"]}>
                  <div>
                    {__computeConstructionAndThreadCount(threadCount.x, threadCount.y)}
                  </div>
                  <div className={style["dot"]}></div>
                  <div>
                    {__computeConstructionAndThreadCount(construction.epi, construction.ppi)}
                  </div>
                </div>
                <div className={style["specification-column"]}>
                  <div>{`${width} inch`}</div>
                </div>
              </>
            );
          },
        },
      ]
  };

  export const initialValues = {
    file: null,
    supplierName: "",
  };
  
  export const initialFormValues = {};
  
  export const transformToForm = (orderData) => {
    return {
      id: orderData.id,
      productName: orderData.productName,
      isDisable: orderData.isDisable,
      weight: orderData.weight ? Number(orderData?.weight) : "",
      fabricType: orderData.productTypeId,
      x: orderData.threadCount ? orderData.threadCount.x : "",
      y: orderData.threadCount ? orderData.threadCount.y : "",
      productCategory: orderData.productCategoryId,
      epi: orderData.construction ? Number(orderData.construction.epi) : null,
      ppi: orderData.construction ? Number(orderData.construction.ppi) : null,
      subCategory: String(orderData.productSubCategoryId),
      productType: String(orderData.requirementType),
      width: Number(orderData.width),
      loomType: orderData.loomTypeId,
      certifications: orderData.certifications,
      price: orderData.price ? orderData.price : "",
      supplier: orderData?.locofastroot?.name ?? "",
      tat: orderData?.tat ?? "",
      supplierId: orderData.supplierId,
    };
  };
  