import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
 inventoryData: [],
 inventoryBaleData: []
};

const InventoryReducer = handleActions(
  {
    GET_INVENTORY_DATA: (state, action) => {
      return {
        ...state,
       inventoryData: action.payload,
      };
    },
    GET_INVENTORY_BALE_DATA: (state, action) => {
      return {
        ...state,
        inventoryBaleData: action.payload,
      };
    },
  },

  INITIAL_STATE,
);

export default InventoryReducer;