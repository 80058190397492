import React from 'react';
import classnames from 'classnames';
import Button from '../../Button/Button';
import './style.scss';

const ModalFooter = ({
  cancel,
  submit,
  hasBorder = false,
  canSubmit = false,
  canCancel = false,
  cancelCategory = "blue-bg-outline",
  submitCategory = "blue-bg",
  onCancel = () => { },
  onSubmit = () => { },
}) => {

  return <div className={classnames("modal-footer", {
    bordered: hasBorder
  })}>
    {cancel && <div className="modal-footer__cta">
      <Button
        type="button"
        category={cancelCategory}
        disabled={!canCancel}
        onClick={onCancel}
      >
        {cancel}
      </Button>
    </div>}
    {submit && <div className="modal-footer__cta">
      <Button
        className="cta-submit"
        category={submitCategory}
        disabled={!canSubmit}
        onClick={onSubmit}
      >
        {submit}
      </Button>
    </div>}
  </div>
};

export default ModalFooter;
