import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { IconEditPencil, IconExpandMore } from 'components/UI/Icons';
import Button from 'components/UI/Button/Button';

const OrgRow = ({
  org,
  gridTemplateColumns,
  rowRenderer = (org) => null,
  rowDetailsRenderer = (org) => null,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
  children,
  expandable = true,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const expandMoreClickHandler = useCallback(() => {
    setExpanded(!isExpanded);
    onOrgExpandChange(!isExpanded);
  }, [isExpanded, onOrgExpandChange]);

  return <div
    className={classnames("partner-settings-row", {
      'partner-settings-row--expanded': isExpanded,
    })}
  >
    <div
      className="partner-settings-row__header"
      style={{
        gridTemplateColumns,
      }}
    >
      {rowRenderer(org)}
      <Button
        className="cta-edit"
        category="img shrink"
        type="button"
        onClick={() => onOrgEdit(org)}
      >
        <IconEditPencil />
      </Button>
      {expandable && <Button
        className="cta-expand"
        category="img shrink"
        type="button"
        onClick={expandMoreClickHandler}
      >
        <IconExpandMore />
      </Button>}
    </div>
    {isExpanded && <div className="partner-settings-row__content">
      {rowDetailsRenderer(org)}
    </div>}
  </div>;
};

export default OrgRow;
