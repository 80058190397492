import React from 'react';
import classnames from 'classnames';
import './style.scss';

export default class Tabs extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: this.props.defaultActiveTabIndex || this.props.children.findIndex(item => item),
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  // Toggle currently active tab
  handleTabClick(tabIndex) {
    this.setState({
      activeTabIndex: tabIndex === this.state.activeTabIndex ? this.props.defaultActiveTabIndex : tabIndex
    });
  }

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps() {
    return React.Children.map(this.props.children, (child, index) => {
      if (!child) {
        return child;
      }

      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex
      });
    });
  }

  // Render current active tab content
  renderActiveTabContent() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div className={classnames("tabs", className)}>
        <div className="tabs-nav">
          {this.renderChildrenWithTabsApiAsProps()}
        </div>
        <div className="tabs-active-content">
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

export const TabPanel = ({ className = '', disabled, isActive, tabIndex, onClick, header }) => {
  return (
    <button
      className={classnames(`tabs-nav__item`, className, {
        'tabs-nav__item--active': isActive,
      })}
      onClick={(event) => {
        event.preventDefault();
        onClick(tabIndex);
      }}
      type="button"
    >
      {header}
    </button>
  )
}
