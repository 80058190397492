import React from 'react';
import classnames from 'classnames';
import ImgPlaceholderFabric from 'assets/images/placeholders/ic_plc_fabric_colored.svg';
import ImgPlaceholderWomen from 'assets/images/placeholders/ic_plc_women_colored.svg';
import ImgSuccessUrl from 'assets/images/communication/ic-success-blue.svg';
import Button from 'components/UI/Button/Button';

const CUSTOMER_TYPE_OPTIONS = [
  {
    value: 2,
    imgUrl: ImgPlaceholderWomen,
    text: 'Construction',
  },
  {
    value: 4,
    imgUrl: ImgPlaceholderFabric,
    text: 'Fabric',
  },
];

const OptionButton = ({
  isSelected,
  disabled,
  onClick = (value) => null,
  option: {
    value,
    imgUrl,
    text,
  },
}) => {
  return <Button
    category="grid-2 shrink ghost"
    className={classnames("option-button", {
      'option-button--selected': isSelected,
      'option-button--disabled': disabled,
    })}
    onClick={() => onClick(value)}
  >
    <div className="option-button__img">
      <img src={imgUrl} alt="placeholder" />
      <img className="option-button__success" src={ImgSuccessUrl} alt="success" />
    </div>
    <span className="option-button__text">{text}</span>
  </Button>;
};

const ChooseTypePanel = ({
  disabled,
  onSelect = (type) => null,
  selectedValue,
}) => {
  return (<div className="choose-type-panel">
    <div className="choose-type-panel__option-list">
      {
        CUSTOMER_TYPE_OPTIONS.map((option) => <OptionButton
          key={option.value}
          option={option}
          isSelected={selectedValue === option.value}
          onClick={disabled ? undefined : onSelect}
          disabled={disabled}
        />)
      }
    </div>
  </div>);
};

export default ChooseTypePanel;
