import React from "react";
import style from "../TopQualities/style.module.scss";
import constructionImg from "assets/images/fabricQualities/Construction.svg";
import threadCountImg from "assets/images/fabricQualities/ThreadCount.svg";
import { Tooltip } from "antd";
import ToggleSwitch from "../TopQualities/ToggleSwitch";
import { CUSTOMER_TYPES, LOOM_TYPES_KEY } from "../TopQualities/config";
export const columnsData = (
  { loomType, productType, requirementType },
  edit,
  reloader,
  updateLiveQualityData
) => [
  {
    title: "Fabric type",
    dataIndex: "productTypeId",
    render: (valueId) => {
      if (!productType) return "-";
      const productTypeName = productType.map((data) => {
        return data.id === valueId && data.name;
      });
      return productTypeName;
    },
  },
  {
    title: "Product name",
    dataIndex: "productName",
    render: (value) => {
      return <div className={style["text-wrapper"]}>{value}</div>;
    },
  },
  {
    title: "Requirement type",
    dataIndex: "requirementType",
    render: (valueId) => {
      if (!requirementType) return "-";
      const requirementTypeName = requirementType.map((data) => {
        return data.id === valueId && data.name;
      });
      return requirementTypeName;
    },
  },
  {
    title: "Loom-type",
    dataIndex: "loomTypeIds",
    render: (value) => {
      const loomTypeText = value.length ? value.map((data, index) => {
          var ans = '';
          ans += LOOM_TYPES_KEY[data] + `${index !== value.length-1 ? ', ' : ''}`;
          return ans;
        })
        : '--';
        
      return (
        <div className={style["text-wrapper"]}>{loomTypeText}</div>
      );
    },
  },
  {
    title: "Specifications",
    render: (value, record) => {
      return (
        <>
          <div className={style["specification-column"]}>
            <Tooltip placement="bottom" title="Thread Count">
              <img src={constructionImg} alt="construction img" />
            </Tooltip>
            <div>{`${record.threadCount ? record.threadCount.x : "--"} x ${
              record.threadCount ? record.threadCount.y : "--"
            }`}</div>
          </div>
          <div className={style["specification-column"]}>
            <Tooltip placement="bottom" title="Construction">
              <img src={threadCountImg} alt="Threadcount img" />
            </Tooltip>
            <div>{`${record.construction ? record.construction.epi : "--"} x ${
              record.construction ? record.construction.ppi : "--"
            }`}</div>
          </div>
        </>
      );
    },
  },
  {
    title: "Width",
    dataIndex: "widths",
    render: (value) => {
      const widthsText = value.length ? value.map((data, index) => {
        var ans = '';
        ans += `${data} in${index !== value.length-1 ? ', ' : ''}`;
        return ans;
      })
      : '--';
      return <div>{widthsText}</div>;
    },
  },
  {
    title: "Visible to",
    dataIndex: "customerTypeVisibility",
    render: (value) => {
      return <div>{value ? CUSTOMER_TYPES[value] :'--'}</div>;
    },
  },
  {
    title: "",
    dataIndex: "",
    render: (value, record) => {
      return (
        <div className={style["actions"]}>
          <div className={style.edit} onClick={() => edit(record)}>
            Edit
          </div>
          <ToggleSwitch
            id={record.id}
            toggleStatus={!Boolean(record.isDisabled)}
            onToggleSwitch={(e, setStatus) => {
              const form = {
                id: record.id,
                isDisabled: !e ? 1 : 0,
              };
              setTimeout(() => {
                updateLiveQualityData(form).then((res) => {
                  if (res) {
                    reloader();
                    setStatus(!Boolean(record.isDisabled));
                  }
                });
              }, '500');
            }}
          />
        </div>
      );
    },
  },
];

export const initialValues = {
  fabricType: null,
  x: "",
  y: "",
  productCategory: null,
  epi: "",
  ppi: "",
  subCategory: [],
  productType: [],
  requirementType: '1',
  widths: [],
  loomType: [],
  countryType: "",
  isWhatsappNotification: false,
  domesticMargin: null,
  internationalMargin: null,
};

export const initialFormValues = {
  weight: "12",
  certifications: ["1", "2", "3"],
  price: "8",
};

export const transformToForm = (orderData) => {
  return {
    id: orderData.id,
    productName: orderData.productName,
    isDisabled: orderData.isDisabled,
    fabricType: orderData.productTypeId,
    x: orderData.threadCount ? orderData.threadCount.x : "",
    y: orderData.threadCount ? orderData.threadCount.y : "",
    productCategory: orderData.productCategoryId,
    epi: orderData.construction ? Number(orderData.construction.epi) : null,
    ppi: orderData.construction ? Number(orderData.construction.ppi) : null,
    subCategory: String(orderData.productSubCategoryId),
    requirementType: String(orderData.requirementType),
    widths: orderData.widths.map((width) => {
      return {
        label: String(width),
        value: String(width),
      }
    }),
    loomType: orderData.loomTypeIds,
    countryType: String(orderData.customerTypeVisibility),
    isWhatsappNotification: Boolean(orderData.isWhatsappEnabled),
    domesticMargin: orderData.domesticMarginPercentage || null,
    internationalMargin: orderData.internationalMarginPercentage || null,
  };
};
