import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import UploadProgress from "./../../components/UI/UploadProgress/UploadProgress";
import UploadSpinner from "./../../components/UI/UploadProgress/UploadSpinner";

import closeIcon from "../../assets/images/navigation/ic-close-light.svg";
import "./UploadProgressContainer.scss";
import actions from "../../store/actions";

class UploadProgressContainer extends Component {

  // {
  //   actionType: "",
  //   type: "default" or "action"   action one is blue in bottom left.
  // }

  render() {
    const { isUploading, fileName, pathName, uploadComplete, error } = this.props.uploader;
    const { hideProgress } = this.props;
      return isUploading ? (
        <div className="upload-progress__container">
            <UploadSpinner />
            <UploadProgress isUploading={isUploading} fileName={fileName}/>
        </div>
      ) : uploadComplete ? (
        <div className="upload-progress__container">
            <UploadProgress uploadComplete={uploadComplete} pathName={pathName} hideProgress = { hideProgress } error={error}/>
        </div>
      ) : null;
  }
}

const mapStateToProps = state => ({
  uploader: state.global.uploader
});

export default connect(
  mapStateToProps,
  {
    hideProgress: actions.hideProgress
  }
)(UploadProgressContainer);
