import React, { Component } from "react";
import "./ToggleButton.scss";

class ToggleButton extends Component {

  render() {
    const {
      label,
      meta: { touched, error, warning },
      meta,
      input,
      field,
      // import separately so that it doesn't override the default HTML onChange
      onChange,
      ...props
    } = this.props;

    return (
      <div className={`input-grp input-grp-toggle-btn ${error === true ? "input-grp--error" : ""}`}>
        <div className="flex">
          <label htmlFor={input.name} className="f-sz-sm f-color-faded">
            {label}
            {props.required === true && <sup>*</sup>}
          </label>
          <div className="input-container" style={{ position: "relative" }}>
            <input
              {...input}
              {...props}
              id={input.name}
              type="checkbox"
              checked={input.value}
            />
            <label htmlFor={input.name}></label>
          </div>
        </div>
        {
          <span className="input-grp__lower flex f-sz-sm">
            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))
            }
          </span>
        }
      </div>
    );
  }
}

export default ToggleButton;
