import React from 'react';
import Status from '../status';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import style from '../FieldInput/style.module.scss';
import CreatableSelect from 'react-select/creatable';

const MultiSelectTextInput = ({
  name,
  label,
  type,
  placeholder,
  isRequired = false,
  isUnit,
  inputValue,
  setInputValue = () => {},
  minValue,
  maxValue,
  isError,
  ...restProps
}) => {
  return (
    <Field name={name}>
      {({ field, meta: { error, touched }, form: { setFieldValue, values } }) => {
        const components = {
          DropdownIndicator: null,
        };
        const createOption = (label) => ({
          label,
          value: label,
        });
        const handleKeyDown = (event) => {
          if (!inputValue) return;
          // eslint-disable-next-line default-case
          switch (event.code) {
            case 'Enter':
            case 'Space':
            case 'Tab':
              if (
                !values[name].map(({ value }) => value).includes(inputValue) &&
                !isNaN(+inputValue)
              ) {
                setFieldValue(field.name, [...values[name], createOption(inputValue)]);
              }
              setInputValue('');
              event.preventDefault();
          }
        };
        return (
          <div className={style['input-wrapper']}>
            {label ? (
              <p className={style.label}>
                <span>{label}</span>
                {isRequired && <Status isFilled={field?.value?.length > 0 && !isError} />}
              </p>
            ) : null}
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setFieldValue(field.name, newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              value={values[name]}
              className={style.multiSelectTextInput}
            />
            {isError ? (
              <p
                className={
                  style.error
                }>{`${label} can be between ${minValue} and ${maxValue} only`}</p>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

MultiSelectTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  placeholder: PropTypes.string,
};

MultiSelectTextInput.defaultProps = {
  type: 'text',
};

export default MultiSelectTextInput;
