import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./InternalSidebar.scss";
const InternalSidebar = ({
  header,
  footer,
  children,
  theme = "default",
  ...restProps
}) => {
  // if (restProps.scrollPosition) {
  //   this._scrollRef.scrollTop = Number(restProps.scrollPosition);
  // }
  return (
    <div
      className={"int-sidebar flex int-sidebar--  absolute-position " + theme}
    >
      {header && <div className="int-sidebar__portion">{header}</div>}
      <div
        option={{ suppressScrollX: true }}
        className="int-sidebar__list flex-col"
      >
        {children}
      </div>
      {footer && <div className="int-sidebar__portion">{footer}</div>}
    </div>
  );
};

export default InternalSidebar;
