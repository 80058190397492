import React from "react";
import { Select as AntdSelect } from "antd";
import Status from "../status";
import { Field } from "formik";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const { Option } = AntdSelect;

const Select = ({
  name,
  label,
  placeholder,
  options,
  defaultValue,
  suffixIcon,
  onSearch,
  onSelect,
  onClear,
  mode,
  isRequired,
  isCustomer = false,
  isLfbId = false,
  isSupplier = false,
  isGlobal = false,
  shouldAllowClear = false,
  isDisabled = false,
  fieldKey,
  fieldValue,
}) => (
  <Field name={name}>
    {({ field, meta: { error, touched }, form: { setFieldValue } }) => {
      return (
        <div className={style["select-wrapper"]}>
          {label ? (
            <p className={style.label}>
              <span>{label}</span>
              {isRequired && <Status isFilled={field?.value?.length > 0} />}
            </p>
          ) : null}

          <AntdSelect
            size="large"
            showArrow
            showSearch
            className={style['selector']}
            allowClear={shouldAllowClear}
            name={field.name}
            value={field.value}
            status={touched && error ? "error" : ""}
            mode={mode}
            bordered={false}
            placeholder={placeholder}
            defaultValue={defaultValue}
            suffixIcon={suffixIcon}
            optionLabelProp={isLfbId && "key"}
            onClear={onClear}
            disabled={isDisabled}
            onSearch={onSearch}
            onChange={(values, index) => {
              if (typeof onSelect === "function") {
                onSelect(values, index);
              }
              return setFieldValue(field.name, values);
            }}
            maxTagCount="responsive"
          >
            {options?.map((item) => {
              let tag;
              if (isCustomer === true) {
                tag = (
                  <Option label={item} key={item.name}>
                    {item.name}
                  </Option>
                );
              } else if (isLfbId === true) {
                tag = (
                  <Option key={`LFB-${item.generatedFabricId}`}>
                    {`LFB-${item.generatedFabricId}  ${item.productName}`}
                  </Option>
                );
              }
              else if (isSupplier === true) {
                tag = (
                //   <Option label={item} key={item.supplierName}>
                //   {item.supplierName}
                // </Option>
                   <Option label={item} key={item.supplierId} value={item.supplierName}>
                    {item.supplierName}
                  </Option>
                );
              }
              else if (isGlobal) {
                tag = (
                  <Option label={item} key={item[fieldKey]}>
                    {item[fieldValue]}
                  </Option>
                );
              } else {
                tag = <Option key={item}>{item}</Option>;
              }
              return tag;
            })}
          </AntdSelect>

          {touched && error ? <p className={style.error}>{error}</p> : null}
        </div>
      );
    }}
  </Field>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.array,
  suffixIcon: PropTypes.node,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  isRequired: PropTypes.bool,
  mode: PropTypes.oneOf(["multiple", false]),
};

Select.defaultProps = {
  options: [],
  defaultValue: [],
  mode: false,
};

export default Select;
