import { certificationTransformer } from 'helpers/reduxHelper';
import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  liveRatesData: {},
  disabledLiveRatesData: {},
  materialData: {},
};

const LiveRatesReducer = handleActions(
  {
    GET_MATERIAL_DATA: (state, action) => {
      const certificationTransformerToObject = certificationTransformer(action.payload.certification)
      return {
        ...state,
        materialData: {
          ...action.payload,
          certificationObject: certificationTransformerToObject,
        },
      };
    },
    GET_LIVE_RATES_DATA: (state, action) => {
      return {
        ...state,
        liveRatesData: action.payload,
      };
    },
    GET_DISABLED_LIVE_RATES_DATA: (state, action) => {
      return {
        ...state,
        disabledLiveRatesData: action.payload,
      };
    },
  },

  INITIAL_STATE,
);

export default LiveRatesReducer;
