import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { getCurrentUserRole } from '../../helpers/AuthHelper';
import {
  CLIENT_SUPER,
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  CLIENT,
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT,
} from '../../constants/Auth';
import actions from '../../store/actions';
import ProfileSettings from './../../components/AccountSettings/ProfileSettings/ProfileSettings';
import ManageUsers from './../../components/AccountSettings/ManageUsers/ManageUsers';
import ManageLfUsers from '../../components/AccountSettings/ManageLfUsers/ManageLfUsers';
import ManageVendors from './../../components/AccountSettings/ManageVendors';

import InternalSidebar from './../../components/UI/InternalSidebar/InternalSidebar';
import PrivateRoute from './../../components/UI/PrivateRoute/PrivateRoute';
import PrivateContainer from './../../components/UI/PrivateContainer/PrivateContainer';
import { ReactComponent as ReactImgPerson } from '../../assets/images/social/profile-dark.svg';
import { ReactComponent as ReactImgHome } from '../../assets/images/image/home-dark.svg';
import { ReactComponent as ReactImgUsers } from '../../assets/images/social/users-dark.svg';
import { ReactComponent as ReactImgBrands } from '../../assets/images/image/ic-brands-dark.svg';
import { ReactComponent as ReactImgFactory } from '../../assets/images/image/ic-factory.svg';
import { ReactComponent as LiveRates } from '../../assets/images/image/live-rates.svg';
import { ReactComponent as ReactImgCube } from '../../assets/running-qualities.svg';
import { ReactComponent as ReactImgCurrency } from '../../assets/images/image/currency_conversion.svg';
import { ReactComponent as WarehouseImg } from '../../assets/warehouse.svg';
import './AccountSettingsContainer.scss';
import Customers from 'components/AccountSettings/Customers';
import Mills from 'components/AccountSettings/Mills';
import EditMill from 'components/AccountSettings/Mills/Edit';
import CustomerDetailsPage from 'components/AccountSettings/Customers/CustomerDetailsPage';
import Logistics from 'components/AccountSettings/CourierPartners';
import TopQualities from 'components/AccountSettings/TopQualities';
import LiveQualities from 'components/AccountSettings/LiveQualities';
import CurrencyFactor from 'components/AccountSettings/CurrencyFactor';
import RunningQualities from 'components/AccountSettings/RunningQualities';
import InventoryManagement from 'components/AccountSettings/InventoryManagement';
import EditInventory from 'components/AccountSettings/InventoryManagement/EditInventory';
// import EditInventory from "components/AccountSettings/EditInventory";

class AccountSettingsContainer extends Component {
  static propTypes = {
    usersList: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateUserDetails: PropTypes.func.isRequired,
    updateUserImage: PropTypes.func.isRequired,
    deleteUserImage: PropTypes.func.isRequired,
  };
  state = {
    sideNavs: [],
    brandFeatures: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.brandFeatures !== prevState.brandFeatures) {
      const { brandFeatures, userDetails } = nextProps;
      const sideNavs = [
        {
          name: 'Customers',
          link: 'customers/',
          icon: ReactImgBrands,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Mills',
          link: 'mills/',
          icon: ReactImgBrands,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Logistics',
          link: 'courier-partners/',
          icon: ReactImgBrands,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Fabric qualities',
          link: 'fabric-qualities/',
          icon: ReactImgFactory,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Running qualities',
          link: 'running-qualities/',
          icon: ReactImgCube,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Live rates',
          link: 'live-qualities/',
          icon: LiveRates,
          userRoles: [LOCO_SUPER],
        },
        (!userDetails.isBrand || brandFeatures.userManagement) && {
          name: 'Users',
          link: 'users/',
          icon: ReactImgUsers,
          userRoles: [CLIENT_SUPER],
        },
        {
          name: 'Users', // Locofast Users
          link: 'lfusers/',
          icon: ReactImgUsers,
          userRoles: [LOCO_SUPER],
        },
        userDetails.isBrand &&
          brandFeatures.materialLibrary && {
            name: 'Vendors',
            link: 'vendors/',
            icon: ReactImgHome,
            userRoles: [CLIENT_SUPER, CLIENT],
          },
        {
          name: ' Currency Factor',
          link: 'currency-factor/',
          icon: ReactImgCurrency,
          userRoles: [LOCO_SUPER],
        },
        {
          name: 'Profile settings',
          link: 'profile/',
          icon: ReactImgPerson,
          userRoles: [
            LOCO_SUPER,
            CLIENT_SUPER,
            CLIENT,
            FACTORY_ADMIN,
            FACTORY_MANAGER,
            PRODUCTION_MERCHANT,
            PRODUCT_DEVELOPMENT,
          ],
        },
      ];

      return {
        brandFeatures,
        sideNavs: sideNavs.filter((item) => item),
      };
    }

    return null;
  }

  componentDidMount() {}

  isCurrentTab(link) {
    return new RegExp(`/[^/]+/${link}`).test(this.props.location.pathname);
  }

  renderSideNavs = () => {
    const { sideNavs } = this.state;
    const renderedSideNavs = sideNavs.map((nav) => (
      <PrivateContainer allowRoles={nav.userRoles} key={nav.link}>
        <Link to={this.props.match.path + nav.link}>
          <div
            className={`account-settings__item f-sz-l f-w-semi-bold ${
              this.isCurrentTab(nav.link) ? 'current_tab' : ''
            }`}>
            {nav.icon ? <nav.icon className="nav-logo" /> : null}
            <div className="nav-name">{nav.name}</div>
          </div>
        </Link>
      </PrivateContainer>
    ));

    const currentUserRole = getCurrentUserRole();
    const firstSideNav = sideNavs.find((nav) => nav.userRoles.includes(currentUserRole));
    const firstLink = (firstSideNav && firstSideNav.link) || 'profile';

    return [renderedSideNavs, firstLink];
  };

  render() {
    const [renderedSideNavs, firstLink] = this.renderSideNavs();
    const allBrands =
      (Array.isArray(this.props.allBrands) &&
        this.props.allBrands.length > 0 &&
        this.props.allBrands) ||
      (Array.isArray(this.props.factoryBrandsList) &&
        this.props.factoryBrandsList.length > 0 &&
        this.props.factoryBrandsList) ||
      [];
    return (
      <div className="account-settings__wrapper flex-col">
        <Helmet
          bodyAttributes={{
            class: 'body_container',
          }}
        />
        <div className="account-settings flex">
          <InternalSidebar theme="users-sidebar">{renderedSideNavs}</InternalSidebar>
          <div className="account-settings__content flex">
            <Route
              exact
              path={this.props.match.path}
              render={(routeProps) => {
                return <Redirect {...routeProps} to={this.props.match.path + firstLink} />;
              }}
            />
            <Route
              path={this.props.match.path + 'profile/'}
              render={(routeProps) => {
                return (
                  <ProfileSettings
                    {...routeProps}
                    openModal={this.props.openModal}
                    changePassword={this.props.changePassword}
                    userDetails={this.props.userDetails}
                    updateUserDetails={this.props.updateUserDetails}
                    updateUserImage={this.props.updateUserImage}
                    deleteUserImage={this.props.deleteUserImage}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path={this.props.match.path + 'users/'}
              userRoles={[CLIENT_SUPER]}
              render={(routeProps) => (
                <ManageUsers
                  {...routeProps}
                  currentUser={this.props.userDetails}
                  deleteUser={this.props.deleteUser}
                  openModal={this.props.openModal}
                  getUsers={this.props.getUsers}
                  usersList={this.props.usersList}
                />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'lfusers/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <ManageLfUsers
                  {...routeProps}
                  currentUser={this.props.userDetails}
                  openModal={this.props.openModal}
                  getLocofastUsers={this.props.getLocofastUsers}
                  addLfUser={this.props.addNewLfUser}
                  linkZohoToUser={this.props.linkZohoToUser}
                  updateLfUser={this.props.updateLfUser}
                  updateLinkZohoToUser={this.props.updateLinkZohoToUser}
                />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'customers/'}
              userRoles={[LOCO_SUPER]}
              exact
              render={(routeProps) => (
                <Customers
                  canGoBack={false}
                  {...routeProps}
                  openModal={this.props.openModal}
                  closeModal={this.props.closeModal}
                />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'mills/'}
              userRoles={[LOCO_SUPER]}
              exact
              render={(routeProps) => (
                <Mills
                  path={this.props.match.path + 'mills/'}
                  canGoBack={false}
                  {...routeProps}
                  openModal={this.props.openModal}
                  closeModal={this.props.closeModal}
                />
              )}
            />
            <PrivateRoute
              path={this.props.match.path + 'customers/:id'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <CustomerDetailsPage {...routeProps} id={routeProps.match.params.id} />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'courier-partners/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <Logistics
                  canGoBack={false}
                  {...routeProps}
                  openModal={this.props.openModal}
                  closeModal={this.props.closeModal}
                />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'mills/:id'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <EditMill {...routeProps} currentUser={this.props.userDetails} />
              )}
            />

            <PrivateRoute
              exact
              path={this.props.match.path + 'vendors/'}
              userRoles={[CLIENT_SUPER, CLIENT]}
              render={(routeProps) => (
                <ManageVendors
                  {...routeProps}
                  currentUser={this.props.userDetails}
                  deleteUser={this.props.deleteUser}
                  openModal={this.props.openModal}
                  getMaterialVendors={this.props.getMaterialVendors}
                  vendors={this.props.materialVendors}
                />
              )}
            />

            <PrivateRoute
              path={this.props.match.path + 'fabric-qualities/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => <TopQualities />}
            />
            <PrivateRoute
              path={this.props.match.path + 'live-qualities/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => <LiveQualities />}
            />
            <PrivateRoute
              path={this.props.match.path + 'running-qualities/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => <RunningQualities />}
            />
            <PrivateRoute
              path={this.props.match.path + 'currency-factor/'}
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <CurrencyFactor {...routeProps} openModal={this.props.openModal} />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: Object.keys(state.auth.list).map((userId) => state.auth.list[userId]),
  userDetails: state.auth.userDetails,
  allBrands: state.brands.all_brands,
  brandFeatures: state.brands.brandFeatures,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (userDetails) => dispatch(actions.getUsers(userDetails)),
  deleteUser: (email) => dispatch(actions.deleteUser(email)),
  getLocofastUsers: (userDetails) => dispatch(actions.getLocofastUsers(userDetails)),
  openModal: (content, additionalProps) => dispatch(actions.openModal(content, additionalProps)),
  closeModal: () => dispatch(actions.closeModal()),
  updateUserDetails: (userDetails) => dispatch(actions.updateUserDetails(userDetails)),
  showFlashMsg: (details) => dispatch(actions.flash(details)),
  getAllBrands: (details) => dispatch(actions.factoryNewGetAllBrands(details)),
  createNewFactory: (details, attachments) =>
    dispatch(actions.createNewFactoryAccount(details, attachments)),
  createNewUserAccount: (details) => dispatch(actions.createNewUserAccount(details)),
  createAssociation: (details) => dispatch(actions.createMerchantAssociations(details)),
  updateAssociation: (details) => dispatch(actions.updateMerchantAssociations(details)),
  getSelectedUserAddresses: (details) => dispatch(actions.getSelectedUserAddresses(details)),
  updateUserAccount: (details) => dispatch(actions.updateUserAccount(details)),
  updateUserImage: (details) => dispatch(actions.updateUserImage(details)),
  deleteUserImage: () => dispatch(actions.deleteUserImage()),
  updateFactoryAccount: (details, attachments) =>
    dispatch(actions.updateFactoryAccount(details, attachments)),
  setFactoriesData: (data) => dispatch(actions.setFactoriesData(data)),
  getAllCities: () => dispatch(actions.getAllCities()),
  signupClientSuper: (details) => dispatch(actions.signupClientSuper(details)),
  addNewLfUser: (details) => dispatch(actions.signupLfUser(details)),
  updateLfUser: (details) => dispatch(actions.updateUser(details)),
  linkZohoToUser: (details) => dispatch(actions.linkZohoToUser(details)),
  updateLinkZohoToUser: (details) => dispatch(actions.updateLinkZohoToUser(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsContainer);
