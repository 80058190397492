export const TIMESTAMP_FORMAT = "MMMM DD, YYYY hh:mma";
export const DATESTAMP_FORMAT = "MMMM DD, YYYY";
export const LOCALIZED_FORMAT = "lll";
export const DATESTAMP__SHORT_FORMAT = "MMM DD, YYYY";
export const TIME_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const TIMESTAMP_SHORT_FORMAT = "MMM DD, YYYY hh:mm A";

export const formatSeconds = seconds => {
  let formattedVal = "";
  let day = Math.floor(seconds / (24 * 3600));
  if (day) {
    formattedVal = `${day}d`;
  }

  seconds = seconds % (24 * 3600);
  let hour = Math.floor(seconds / 3600);
  if (hour) {
    formattedVal = `${formattedVal} ${hour}h`;
  }

  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  if (minutes) {
    formattedVal = `${formattedVal} ${minutes}m`;
  }

  // seconds %= 60;
  // if (seconds) {
  //   formattedVal = `${formattedVal} ${seconds}s`;
  // }
  return formattedVal;
};
