import React, { useCallback } from "react";
import { BrandImage } from "components/UI/BrandImage";
import OrgTable from "../../OrgSettings/OrgTable";

import "./style.scss";
import { ACTIVATION_STATUS, VERIFICATION_STATUS } from "../constants";

const Table = ({
  data,
  users,
  accountManagers,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
  onEditUser = (org, user) => null,
  onUnassignAM = (org, user) => null,
  onAddUser = (org) => null,
  onAssignAM = (org) => null,
}) => {
  const rowRenderer = useCallback((org) => {
    return (
      <>
        <div>{org.name}</div>
        <div>{org.city}</div>
        <div>{org.assignedAccountManagerName}</div>
        <div>{org.openRequests}</div>
        <div>
          {org.activationStatus === ACTIVATION_STATUS.deactivated
            ? "Deactivated"
            : +org.verificationStatus === +VERIFICATION_STATUS.verified
            ? "Verified"
            : "Unverified"}
        </div>
      </>
    );
  }, []);

  return (
    <OrgTable
      className="customer-org-table"
      headers={[
        "CUSTOMER",
        "CUSTOMER LOCATION",
        "ACCOUNT MANAGER",
        "OPEN REQUESTS",
        "STATUS",
      ]}
      data={data}
      gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
      rowRenderer={rowRenderer}
    />
  );
};

export default Table;
