import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Button from 'components/UI/Button/Button';
import actions from "store/actions";
import { AuthRoleIds, USER_ROLES } from 'constants/Auth';
import { closeModal } from "store/actions/GlobalActions";
import UserIcon from "assets/images/Settings/Icon/user.svg";
import CloseIcon from "assets/images/Settings/Icon/close.svg";
import './styles.scss';


const DeactivateModal = ({ userId, deactivate, verify, verifyData, closeModal, name, setIsUpdated }) => {
  useEffect(() => {
    if (userId) {
      verify({
        userId: userId.toString(),
        actionType: 'deactivate'
      })
    }
  }, [userId, verify])

  const handleDeactivate = () => {
    deactivate({
      userId: userId.toString(),
      actionType: 'deactivate'
    }).then(() => {
      closeModal()
      setIsUpdated(true)
    })
  }

  const getErrorMsg = (role, name) => {
    switch (role) {
      case +AuthRoleIds.ACCOUNT_MANAGER:
      case +AuthRoleIds.SALES_HEAD:
        return `Please assign open requests of ${name} to other AMs inorder to deactivate.`

      case +AuthRoleIds.SUPPLIER_MANAGER:
      case +AuthRoleIds.SUPPLY_HEAD:
        return `Please assign associated suppliers of ${name} to other SMs in order to deactivate.`
      default:
        return ''
    }
  }

  const getMsg = (role, toRole) => {
    switch (role) {
      case +AuthRoleIds.ACCOUNT_MANAGER:
      case +AuthRoleIds.SALES_HEAD:
        return 'All the customers (without open requests) will be available to other AMs via Map to me feature.'

      case +AuthRoleIds.SUPPLIER_MANAGER:
      case +AuthRoleIds.SUPPLY_HEAD:
        return `Deactivating ${toRole}.`
      default:
        return ''
    }
  }

  return (
    <div>
      <div className="header">
        <h3 className='heading'>Deactivate - {name}</h3>
        <button className='close-btn' onClick={() => closeModal()}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
      <div className="content">
        <div className='user-row'>
          <div className='user'>
            <div className="user-image-container">
              <img src={verifyData?.userForAction?.image || UserIcon} alt="" className="user-image" />
            </div>
            <p className='user-name'>{verifyData?.userForAction?.userName}</p>
          </div>
          {
            !verifyData?.canPerform &&
            <div className="role">
              <p className='role-title'>{USER_ROLES[verifyData?.userForAction?.role]}</p>
            </div>
          }
        </div>
        {
          !verifyData?.canPerform ?
            <p className='subtitle error'>
              {getErrorMsg(+verifyData?.userForAction?.role, verifyData?.userForAction?.userName)}
            </p>
            :
            <p className='subtitle'>
              {getMsg(+verifyData?.userForAction?.role, USER_ROLES[verifyData?.userForAction?.role])}
            </p>
        }
      </div>
      <div className="footer">
        {
          verifyData?.canPerform &&
          <Button category="blue-bg" onClick={handleDeactivate}>
            Confirm
          </Button>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  verifyData: state.user.userRoleChangeData.data
});

const mapDispatchToProps = dispatch => ({
  deactivate: (params) => dispatch(actions.changeRole(params)),
  verify: (params) => dispatch(actions.verifyRoleChange(params)),
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateModal)
