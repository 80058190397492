import { Dropdown } from 'antd';
import ChevronBottomIcon from './../../../../../assets/images/Settings/Icon/chevron-bottom.svg';
import {
  BALES_STATUS,
  BALES_STATUS_REVERSE,
  BALE_STATUS_DROPDOWN_MENU_OPTIONS as BALE_OPTIONS,
} from '../../base';
import style from './styles.module.scss';

const StatusDropdown = ({ handleStatusChange, record }) => {
  const { id: baleId, status } = record || {};
  const isDropdownVisible = +status === +BALES_STATUS.DISCARD;

  const onClick = ({ key }) => {
    handleStatusChange({ baleId, status: key });
  };

  const items = [...BALE_OPTIONS].filter(({ key }) => key !== status);

  return (
    <div className={style.statusBox}>
      <div className={style[`status${status}`]}>{BALES_STATUS_REVERSE[record.status]}</div>
      {isDropdownVisible && (
        <>
          <Dropdown arrow="true" menu={{ items, onClick }} trigger={['click']}>
            <img className={style.chevron} src={ChevronBottomIcon} alt="bottom chevron" />
          </Dropdown>
        </>
      )}
    </div>
  );
};

export default StatusDropdown;
