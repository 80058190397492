import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PopOver.scss";

class PopOver extends Component {
  state = {
    isVisible: false
  };

  handleClickOnDocument = (e) => {
    if (!this.state.isVisible || !this.popoverContent || !this.popoverNode) {
      return;
    }

    if (this.popoverContent.contains(e.target) || this.popoverNode.contains(e.target)) {
      return;
    }

    this.setIsVisible(false);
  }

  setIsVisible = (isVisible) => {
    if (isVisible) {
      document.addEventListener('mousedown', this.handleClickOnDocument, false);
    } else {
      document.removeEventListener('mousedown', this.handleClickOnDocument, false);
    }

    this.setState({ isVisible });
  }

  render() {
    const {
      extraStyles,
      className,
      bottom,
      top,
      left,
      right,
      content,
      children
    } = this.props;
    const { isVisible } = this.state;
    const popUpContainer_style =
      "pop-over__popup-container" +
      (!isVisible ? " pop-over__popup-container--hide" : "");

    return (
      <div
        className={`pop-over__popup ${className}`}
      >
        <div className={popUpContainer_style}
          ref={node => this.popoverContent = node}
          style={{
            bottom: bottom,
            top: top,
            left: left,
            right: right,
            ...extraStyles
          }}>{content}</div>
        <div
          ref={node => this.popoverNode = node}
          className="pop-over__content-container"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.setIsVisible(!isVisible);
          }}
          onBlur={(e) => {
            // e.stopPropagation();
            // e.preventDefault();
            this.setIsVisible(false);
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

PopOver.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

export default PopOver;
