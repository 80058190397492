export const MANUFACTURER_TYPE_VALUE = 1;
export const TRADER_TYPE_VALUE = 2;
export const MSME_NO = 0;
export const UNDEFINED_VALUE = '--';
export const ACTIVATION_STATUS = {
  activated: 'Y',
  deactivated: 'N',
};
export const VERIFICATION_STATUS = {
  verified: 1,
  unverified: 0,
};
export const COUNTRY_OPTIONS = [
  { label: 'India', value: 'India' },
  { label: 'Bangladesh', value: 'bangladesh' },
  { label: 'Srilanka', value: 'srilanka' },
];

export const STATUS_OPTIONS = [
  { label: 'verified', value: 'verified' },
  { label: 'deactivated', value: 'deactivated' },
];
export const BUSINESS_NATURE = [
  { title: 'Manufacturer', value: MANUFACTURER_TYPE_VALUE },
  { title: 'Trader', value: TRADER_TYPE_VALUE },
];

export const BUSINESS_NATURE_TYPE = {
  Manufacturer: MANUFACTURER_TYPE_VALUE,
  Trader: TRADER_TYPE_VALUE,
};

export const STATUS = {
  verified: 'verified',
  unverified:'unverified',
  deactivated: 'deactivated',
};

export const PAYMENT_BEFORE_DELIVERY = 5;
export const PAYMENT_NATURE = {
  CREDIT: 'credit',
  PBD: 'PBD',
};

export const INTL_CURRENCY_ID = 2;