export const turnOverIndian = [
  {
    label: '0-5 Crores',
    value: '0-5',
  },
  {
    label: '6-10 Crores',
    value: '6-10',
  },
  {
    label: '11-20 Crores',
    value: '11-20',
  },
  {
    label: '21-50 Crores',
    value: '21-50',
  },
  {
    label: '51-100 Crores',
    value: '51-100',
  },
  {
    label: '100+ Crores',
    value: '100+',
  },
];
export const turnOverInternational = [
  {
    label: '0-1 Million',
    value: '0-1',
  },
  {
    label: '1.1-2 Million',
    value: '1.1-2',
  },
  {
    label: '2.1-4 Million',
    value: '2.1-4',
  },
  {
    label: '4.1-6 Million',
    value: '4.1-6',
  },
  {
    label: '6.1-10 Million',
    value: '6.1-10',
  },
  {
    label: '10+ Million',
    value: '10+',
  },
];
export const creditNaturePills = [
  {
    label: 'LC',
    value: '1',
  },
  {
    label: 'Open',
    value: '3',
  },
  {
    label: 'PDC before delivery',
    value: '2',
  },
  {
    label: 'PDC after delivery',
    value: '4',
  },
  {
    label: 'NBFC Credit',
    value: '6',
  },
  {
    label: 'NBFC - Stride',
    value: '7',
  },
  {
    label: 'NBFC - Blacksoil',
    value: '8',
  },
];

export const paymentNature = [
  {
    label: 'Cash',
    value: 'PBD',
  },
  {
    label: 'Cash + Credit',
    value: 'credit',
  },
];
export const creditPeriodPills = ['30', '45', '60', '75', '90'];
export const creditPeriodPillsInternational = ['30', '45', '60', '90', '120'];
export const DEFAULT_CREDIT_NATURE = '1'; // LC
export const DEFAULT_CREDIT_PERIOD = '30'; // 30 Days
export const UPTO_TWO_DECIMAL_REGEX = /^\d+(\.\d{1,2})?$/;
