import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Sidebar from "./../../components/UI/Sidebar/Sidebar";

import "./FactoryDashboardContainer.scss";
import { ReactComponent as SettingsImg } from "assets/images/navigation/settings.svg";
import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import PerformanceContainer from "../PerformanceContainer";
import {
  ACCOUNT_MANAGER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  LOCO_SUPER,
} from "../../constants/Auth";
import {
  getCurrentUserRole,
  refreshTokenIfOld,
} from "../../helpers/AuthHelper";

const REPORTS_DEFAULT_VIEWERS =
  process.env.REACT_APP_REPORTS_DEFAULT_VIEWERS?.split(",");

class FactoryDashboardContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAllCollections: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    if (!REPORTS_DEFAULT_VIEWERS?.includes(props.userDetails?.email)) {
      this.props.getUsers();
    }

    refreshTokenIfOld(this.props.refreshToken);
    const { userDetails } = this.props;

    let sidebarUpperMenu = [
      {
        name: "Settings",
        id: "settings",
        img: SettingsImg,
        link: "/factory/settings/",
      },
    ];

    if (
      getCurrentUserRole() === FACTORY_ADMIN ||
      getCurrentUserRole() === FACTORY_MANAGER
    ) {
      sidebarUpperMenu = [
        {
          name: "Settings",
          id: "settings",
          img: SettingsImg,
          link: "/factory/settings/",
        },
      ];
    }

    this.state = {
      upperMenu: sidebarUpperMenu,
      userRole: getCurrentUserRole(),
    };
  }

  componentWillReceiveProps(nextProps) {}

  openSendNotificationModal = () => {
    this.props.openModal("SEND_NOTIFICATION_MODAL", {
      hiddenFields: [
        {
          name: "notification",
          value: window.lf.notifs[this.props.match.params.design_id],
        },
      ],
    });
  };

  render() {
    const { upperMenu } = this.state;
    const {
      setCurrentBrand,
      currentBrand,
      notifications,
      setNotificationsRead,
      userDetails,
    } = this.props;

    return (
      <div className="brand-dash">
        <Header
          isFactory={
            this.props.userDetails.role === LOCO_SUPER ||
            this.props.userDetails.role === FACTORY_ADMIN ||
            this.props.userDetails.role === FACTORY_MANAGER
          }
          openModel={() =>
            this.props.openModal("NOTIFICATION_MODAL", {
              userDetails: this.props.userDetails,
              isFactory: true,
              brandId: currentBrand && currentBrand.brandIid,
            })
          }
          brandId={currentBrand && currentBrand.brandId}
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          notifications={notifications}
          setNotificationsRead={setNotificationsRead}
          userDetails={userDetails}
          noBellIcon={this.props.userDetails.role === ACCOUNT_MANAGER}
          noSearchBar={this.props.userDetails.role === ACCOUNT_MANAGER}
        />
        <div
          className="brand-dash__main" // this.props.modal.isOpen
        >
          <Sidebar
            openSendNotificationModal={this.openSendNotificationModal}
            upperItems={upperMenu}
            userDetails={this.props.userDetails}
          />

          {/* // TODO : Add Messages Tab */}

          <div className="brand-dash__content ">
            <Route
              path="/factory/settings/"
              render={(routeProps) => {
                return <AccountSettingsContainer {...routeProps} />;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
  modal: state.global.modal,
  usersList: state.auth.list,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) =>
    dispatch(actions.closeModal(content, additionalProps)),
  getUsers: () => dispatch(actions.getUsers()),
  factoryGetDetails: (details) => dispatch(actions.factoryGetDetails(details)),
  getCollectionsByBrandId: (details) =>
    dispatch(actions.getCollectionsByBrandId(details)),
  setCurrentBrand: (v) => dispatch(actions.setCurrentBrand(v)),
  getBrandFeatures: () => dispatch(actions.getBrandFeatures),
  refreshToken: () => dispatch(actions.refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactoryDashboardContainer)
);
