import { PropTypes } from "prop-types";
import React, { useState } from "react";
import moment from "moment";
import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Popover } from 'antd';

import { ProfileAvatar } from "components/UI/ProfileAvatar";
import Button from "components/UI/Button/Button";

import TeamAvatars from "./Team/Avatars";

import editIcon from "./../../../assets/images/image/ic-edit-grey.svg";
import userIcon from "./../../../assets/images/Settings/Icon/user.svg";
import ChevronBottomIcon from "./../../../assets/images/Settings/Icon/chevron-bottom.svg";
import disableIcon from "./../../../assets/images/Settings/Icon/disable.svg";
import { ReactComponent as UsersIcon } from "./../../../assets/images/social/ic-users.svg";
import { USER_ROLES, AuthRoleIds } from "./../../../constants/Auth";

const renderBaseRow = ({
  userData,
  manageTeamMembers,
  viewTeamMembers,
  handleEditClick,
  handleDeactivateClick,
  handleRoleChangeModal,
  setSelectedUser,
  setShowEdit,
  showEdit,
}) => {
  const {
    image,
    name,
    email,
    phone,
    role,
    lastLogin,
    id,
    managedUsers = [],
  } = userData;

  const roleContent = (
    <div className='role-content'>
      <button className='role-content-item' onClick={() =>handleRoleChangeModal(name, role, id)}>
        <img src={userIcon} alt='' />
        <p>Role Change</p>
      </button>
      <button className='role-content-item deactivate-item' onClick={() => handleDeactivateClick(name, role, id)}>
        <img src={disableIcon} alt='' />
        <p>Deactivate</p>
      </button>
    </div>
  );

  return (
    <React.Fragment>
      <td className={`user__profile relative ${showEdit && 'overlay-effect'}`}
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <div className="user__profile-row relative">
          <div className="img-container">
            <ProfileAvatar
              name={name}
              initialLength={2}
              staticColor
              id={id}
              url={image}
            />
          </div>
          <div>
            <p>{name}</p>
          </div>
        </div>
      </td>
      <td className={`email-td relative ${showEdit && 'overlay-effect'}`}
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <div className='relative email-content'>
          <p>{email}</p>
          <p className="p-grey">{phone}</p>
          {
            showEdit &&
            <div
              className="lfusers-content__edit"
              onClick={handleEditClick}
            >
              <img src={editIcon} alt="edit icon" />
              <span>Edit</span>
            </div>}
        </div>
      </td>

      <td className='team__members'>
        {managedUsers.length > 0 ? (
          <TeamAvatars
            assignedUsers={managedUsers}
            viewTeamMembers={(position) =>
              viewTeamMembers(managedUsers, { id, name }, position, id, role)
            }
            managerId={id}
            role={role}
          />
        ) : (+role === AuthRoleIds.SALES_HEAD || +role === AuthRoleIds.SUPPLY_HEAD) ? (
          <Button
            category="grid-2 black-ghost"
            type="button"
            onClick={manageTeamMembers}
            className="lfusers-content__add-members"
          >
            <UsersIcon />
            Add members
          </Button>
        ) : (
          "--"
        )}
      </td>

      <td className="user__activity">
        <div>
          <p>{lastLogin ? moment(lastLogin).fromNow(true) : <span>N/A</span>}</p>
        </div>
      </td>

      <td className='role-td'>
        <Popover content={roleContent}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement='bottom' trigger="click">
          <div className='role-row'>
            <p onClick={() => {
              setSelectedUser({
                selectedId: id,
                selectedName: name,
                selectedRole: role
              });
            }}>{USER_ROLES[role]}</p>
            <img src={ChevronBottomIcon} alt='' />
          </div>
        </Popover>
      </td>

    </React.Fragment >
  );
};

const RowRenderer = ({
  openModal,
  updateLfUser,
  updateUserZohoDetails,
  linkZohoToUser,
  userData,
  loggedInUserDetails,
  setIsUpdated,
  ...props
}) => {
  const { email, firstName, lastName, name, phone, role, whatsappNumber, id, maxActiveQuery } = userData;
  const [selectedUser, setSelectedUser] = useState({ selectedId: '', selectedRole: '', selectedName: '' })
  const [showEdit, setShowEdit] = useState(false)

  const { selectedId, selectedRole, selectedName } = selectedUser
  const dataObject = {
    email,
    firstName,
    lastName,
    name,
    phone,
    role,
    id,
    whatsappNumber,
    brandId: loggedInUserDetails.brandId,
    maxActiveQuery,
  };

  const handleClick = () => {
    openModal("UPDATE_LF_USER", {
      userData: dataObject,
      updateLfUser,
      canEditRole: false,
    });
  };

  const handleDeactivateClick = (name, role, id) => {
    openModal("ROLE_DEACTIVATE", {
      userId: id,
      setIsUpdated: setIsUpdated,
      name: name
    });
  };

  const handleRoleChangeModal = (name, role, id) => {
    openModal('ROLE_CHANGE', {
      userId: id,
      setIsUpdated: setIsUpdated,
      name: name,
      role: role,
    });
  };
  return (
    <tr className="pos-relative lfusers-content__item">
      {renderBaseRow({
        ...props,
        userData,
        handleEditClick: handleClick,
        handleDeactivateClick,
        handleRoleChangeModal,
        setSelectedUser,
        setShowEdit,
        showEdit,
      })}
    </tr>
  );
};

const ManageLfUsersTable = ({
  usersList,
  updateLfUser,
  openModal,
  linkZohoToUser,
  updateLinkZohoToUser,
  loggedInUserDetails,
  setIsUpdated
}) => {
  const viewTeamMembers = (assignedUsers, teamLead, position, managerId, role) => {
    openModal("VIEW_TEAM_MEMBERS", {
      teamLead,
      assignedUsers,
      styles: position,
      title: (
        <span>
          {teamLead.name}'s team
          <span className="count">{assignedUsers.length}</span>
        </span>
      ),
      managerId,
      role,
      isSupplyHead: +role === AuthRoleIds.SUPPLY_HEAD
    });
  };

  const manageTeamMembers = (managerId, role) => {
    openModal("MANAGE_TEAM_MEMBERS", {
      title: "Create team",
      managerId,
      isSupplyHead: +role === AuthRoleIds.SUPPLY_HEAD
    });
  };

  return (
    <div className="lfusers-content">
      <table className="lfusers-content__container">
        <thead className=" lfusers-content__header">
          <tr>
            <th>Name</th>
            <th>Contact</th>
            <th>Team</th>
            <th className='activity'>Last Activity</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {usersList.map((user) => (
            <RowRenderer
              openModal={openModal}
              updateLfUser={updateLfUser}
              userData={user}
              updateUserZohoDetails={updateLinkZohoToUser}
              linkZohoToUser={linkZohoToUser}
              loggedInUserDetails={loggedInUserDetails}
              viewTeamMembers={viewTeamMembers}
              manageTeamMembers={() => manageTeamMembers(user.id, user.role)}
              setIsUpdated={setIsUpdated}
              key={user.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

ManageLfUsersTable.propTypes = {
  usersList: PropTypes.array.isRequired,
  updateLfUser: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default ManageLfUsersTable;
