import axios from 'axios';
import { isEmpty } from 'lodash';
import { createAction } from 'redux-actions';
import { get_service_endpoint } from 'ServiceEndpoints';
import {flash} from '../actions/CommonActions'
import { closeModal } from './GlobalActions';
import { getLogisticPartners } from './UserActions';
const GET_PARTNER_DETAILS = createAction("GET_PARTNER_DETAILS");
const RESET_PARTNER_DETAILS = createAction("RESET_PARTNER_DETAILS");

const fabric = get_service_endpoint("fabric");

export const getPartnerDetails = ( id ) => dispatch => {
    return axios
      .get(`${fabric}/courier-partners/${id}`)
      .then(res => {
        dispatch(GET_PARTNER_DETAILS(res.data));
        return res;
      }).catch(err => {
        dispatch(flash({
          message: err.msg
        }))
      })
  }

export const resetPartnerDetails = () => dispatch => {
  dispatch(RESET_PARTNER_DETAILS())
}

  export const updateLogiPartner = details => dispatch => {
    let request = {
      ...details,
      isActive: +details.isActive
    }

    Object.keys(request).forEach((key) => {
      if (request[key] === ""){
        delete request[key]
      }
    })

    return axios.patch(`${fabric}/courier-partners/${details.id}`, request)
      .then(res => {
        dispatch(flash({ message: 'Logistic Partner details updated' }));
        dispatch(closeModal());
        dispatch(getLogisticPartners())
        return res.data;
      })
      .catch(err => {
        dispatch(
          flash({
            message: "Could not update Logistic Partner"
          })
        )
      })
  }

  export const createNewLogiPartner = details => dispatch => {
    let request = {
      ...details,
      isActive: +details.isActive
    }
    
    Object.keys(request).forEach((key) => {
      if (request[key] === ""){
        delete request[key]
      }
    })

    return axios
      .post(`${fabric}/courier-partners`, request)
      .then(res => {
        dispatch(flash({ message: "Logistic partner created successfully" }));
        dispatch(closeModal());
        dispatch(getLogisticPartners())
        return res.data;
      })
      .catch(err => {
        dispatch(
          flash({
            message: "Could not create logistic partner"
          })
        );
      });
  };