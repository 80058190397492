export const __computeConstructionAndThreadCount = (val1, val2) => {
  return val1 ? `${val1} x ${val2}` : '--';
};

export const DATE_TIME_RAW = 'Do MMMM YYYY, hh:mm A';

const removeSpecialCharacter = (value) => value?.trim()?.replace(/[^a-zA-Z ]/g, '');
export const checkFileType = (uploadedFileType, accetableFileType) => {
  const matchingFileTypes = accetableFileType
    .split(',')
    .filter((fileType) => uploadedFileType.includes(removeSpecialCharacter(fileType)));
  return Boolean(matchingFileTypes.length);
};
export const DEFAULT_FILE_SIZE = 5000000; // Bytes