import React from 'react';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import RunningQualityForm from './Drawer.jsx';
import * as Yup from 'yup'

import { columnsData, initialValues, transformToForm } from './base';
import style from './style.module.scss';
import {
  getMaterialData,
} from 'store/actions/FabricQualitiesActions.js';
import { connect } from 'react-redux';
import { getRunningQualities, getRunningQualitiesFilters } from 'store/actions/UserActions.js';
import { Formik } from 'formik';
import { LOOM_TYPES } from '../TopQualities/config.js';

const RunningQualities = ({
  getMaterialDataApi,
  getRunningQualitiesApi,
  getRunningQualitiesFiltersApi,
  runningQualities,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({ ...initialValues });
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const { data, filters } = runningQualities;

  const reloader = () => {
    setLoader(!loader);
  };
  
  useEffect(() => {
    getRunningQualitiesApi({limit: 10, page})
    getRunningQualitiesFiltersApi()
  }, []);

  const edit = (record) => {
    const form = transformToForm(record);
    setFormData(form);
    setIsUpdate(true);
    setIsFormVisible(true);
  };

  const transformSuppliers = (data, type) => {
    if(type === 'suppliers'){
      return data?.length ? data.map(obj => {
        return {
          text: obj.supplierName,
          value: obj.supplierId,
        }
        }) : []
    } else if(type === 'loomTypes'){
      return data?.length ? data.map(obj => {
        return {
          text: obj.name,
          value: obj.id
        }
      }) : []
    } else {
      return data?.length ? data.map(obj => {
        return {
          text: obj.productName,
          value: obj.productId
        }
      }) : []
    }
  }

  const handleChange = (...event) => {
    const [pagination, filter, sort] = event || [];
    const filtersObj = {}
    filtersObj.productIds = filter.productName;
    filtersObj.supplierIds = filter.supplierName;
    filtersObj.loomTypeIds = filter.loomTypeId;

    getRunningQualitiesApi({limit: 10, page: pagination.current, filters: filtersObj})
  };


  const materialData = {
    loomType: transformSuppliers(LOOM_TYPES, 'loomTypes'),
    suppliers: transformSuppliers(filters?.suppliers, 'suppliers'),
    products: transformSuppliers(filters?.products),
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <div className={style.page}>
      <div className={style.head}>
        <div className={style.headline}>Running qualities</div>
        <button
          className="btn btn--blue-bg"
          onClick={() => {
            setFormData({});
            setIsUpdate(false);
            setIsFormVisible(true);
          }}
        >
          Upload Qualties
        </button>
      </div>
      <div className='relative'>
        {
         Boolean(data?.paginate?.totalRecords) && <div className={style['table-count']}>
          Total results: {data?.paginate?.totalRecords}
        </div>
        }
        <Table
          columns={columnsData(materialData, edit, reloader)}
          dataSource={data.records}
          pagination={{
            position: ['topRight'],
            defaultPageSize: 10,
            total: data.paginate?.totalRecords,
            size: 'small',
            showSizeChanger: false,
          }}
          onChange={handleChange}
        />
      </div>
      <div>
      <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
          validateOnMount={true}
        >
          {({
            errors,
            values,
            setFieldValue,
          }) => (
            <RunningQualityForm
            isUpdate={isUpdate}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            initialFormData={formData}
            setPage={setPage}
            visible={isFormVisible}
            onClose={() => {
              setIsFormVisible(false);
            }}
            reload={reloader}
            />
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  runningQualities: state.user.runningQualities,
  materialData: state.fabricQualities.materialData,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialDataApi: () => dispatch(getMaterialData()),
  getRunningQualitiesApi: (data) => dispatch(getRunningQualities(data)),
  getRunningQualitiesFiltersApi: (data) => dispatch(getRunningQualitiesFilters(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunningQualities);
