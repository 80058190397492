import React from "react";
import { Input } from "antd";
import Status from "../status";
import { Field } from "formik";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const FieldInput = ({
  name,
  label,
  type,
  placeholder,
  isRequired = false,
  isUnit,
  onKeyPress = () => {},
  ...restProps
}) => (
  <Field name={name}>
    {({ field, meta: { error, touched }, x }) => {
      return (
        <div className={style["input-wrapper"]}>
          {label ? (
            <p className={style.label}>
              <span>{label}</span>
              {isRequired && <Status isFilled={!error && !!field?.value} />}
            </p>
          ) : null}
          <div className={style["box"]}>
            <Input
              status={touched && error ? "error" : ""}
              type={type}
              className={isUnit ? style.secondinput : style.input}
              placeholder={placeholder}
              onWheel={(event) => event.currentTarget.blur()}
              onKeyPress={onKeyPress}
              {...field}
              {...restProps}
            />
            {isUnit && <div className={style["unit"]}>{isUnit}</div>}
          </div>
          {touched && error ? <p className={style.error}>{error}</p> : null}
        </div>
      );
    }}
  </Field>
);

FieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.oneOf(["text", "password", "number"]),
  placeholder: PropTypes.string,
};

FieldInput.defaultProps = {
  type: "text",
};

export default FieldInput;
