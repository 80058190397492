import { Col, Drawer, Row } from 'antd';
import RadioField from 'components/AccountSettings/TopQualities/RadioField';
import {
  CATEGORY_TYPES,
  DENIM_COLOR_TYPES,
  DENIM_COLOR_TYPES_REVERSE,
  DYED_TYPES,
  PRINTED_TYPES,
  PRODUCTION_SUB_TYPES,
  PRODUCT_TYPES,
  REQUIREMENT_TYPES_TABS,
  RFD_TYPES,
  WEAVE_PATTERN,
  WEIGHT_TABS,
} from 'components/AccountSettings/TopQualities/config';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { getMaterialData } from 'store/actions/FabricQualitiesActions';
import { connect } from 'react-redux';
import Select from 'components/AccountSettings/TopQualities/Select';
import FieldInput from 'components/AccountSettings/TopQualities/FieldInput';
import Status from 'components/AccountSettings/TopQualities/status';
import {
  MAX_WIDTH,
  MIN_WIDTH,
  QUERY_CATEGORY,
  UPTO_TWO_DECIMAL_REGEX,
  WEIGHT_VALUES_CAP,
  WEIGHT_VALUES_REVERSE,
  initialValues,
} from '../base';
import * as Yup from 'yup';
import { createNewSKU } from 'store/actions/InventoryAction';
import UploadMediaWithS3 from 'components/HOCs/UploadMediaWithS3';

const InventoryManagementForm = ({ visible, onClose, materialData, createNewSKU, reload }) => {
  const validationSchema = Yup.object().shape(
    {
      fabricType: Yup.number().required('Please select any one option'),
      loomType: Yup.number().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.number().required('Please select any one option'),
        otherwise: Yup.number().optional().nullable(),
      }),
      width: Yup.number().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.number().positive().max(150, 'Maximum value 150').required('Required'),
        otherwise: Yup.number().optional().nullable(),
      }),
      weight: Yup.number().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.number()
          .min(40, 'Please enter a value between 40-800 GSM')
          .max(800, 'Please enter a value between 40-800 GSM')
          .required('Required'),
        otherwise: Yup.number().optional().nullable(),
      }),
      epi: Yup.number().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.number()
          .positive()
          .min(20, 'Minimum value 20')
          .max(200, 'Maximum value 200')
          .required('Required'),
        otherwise: Yup.number().optional().nullable(),
      }),
      ppi: Yup.number().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.number()
          .positive()
          .min(20, 'Minimum value 20')
          .max(200, 'Maximum value 200')
          .required('Required'),
        otherwise: Yup.number().optional().nullable(),
      }),
      x: Yup.string().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.string()
          .matches(/^(\d+)(\s?\/\s?\d+)?$/, 'Format: 30 or 3 / 30')
          .max(4, 'Please enter upto 4 characters')
          .required('Required'),
        otherwise: Yup.string(),
      }),
      y: Yup.string().when('requirementType', {
        is: (value) => value && value != '6',
        then: Yup.string()
          .matches(/^(\d+)(\s?\/\s?\d+)?$/, 'Format: 30 or 3 / 30')
          .max(4, 'Please enter upto 4 characters')
          .required('Required'),
        otherwise: Yup.string(),
      }),
      requirementType: Yup.string().required('Please select any one option'),
      subCategory: Yup.mixed().when('requirementType', {
        is: (value) => value !== '6',
        then: Yup.string().required('Please select any one option'),
        otherwise: Yup.mixed()
          .test(
            'is-empty-array-or-string',
            'subCategory must be an empty array or a string when requirementType is 6',
            (value) => typeof value === 'string' || (Array.isArray(value) && value.length === 0),
          )
          .required(
            'subCategory is required and must be an empty array or a string when requirementType is 6',
          ),
      }),

      weavePattern: Yup.string().when('requirementType', {
        is: (value) => value && value === '6',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      denimColor: Yup.string().when('requirementType', {
        is: (value) => value && value === '6',
        then: Yup.string()
          .required('Required')
          .min(1, 'Please enter 1-10 characters for color')
          .max(10, 'Please enter 1-10 characters for color'),
        otherwise: Yup.string(),
      }),
      otherDenimColor: Yup.string().when('denimColor', {
        is: (value) => value && value === '6',
        then: Yup.string()
          .required('Required')
          .min(1, 'Please enter 1-10 characters for color')
          .max(10, 'Please enter 1-10 characters for color'),
        otherwise: Yup.string().optional().nullable(),
      }),
      noOfColors: Yup.number()
        .min(1, 'Enter a value between 1 to 15')
        .max(15, 'Enter a value between 1 to 15')
        .when('requirementType', {
          is: (requirementType) => ['2', '5'].includes(requirementType),
          then: Yup.number().required('Required').typeError('Required'),
          otherwise: Yup.number().optional().nullable(),
        }),
      // color: Yup.string().when('requirementType', {
      //   is: (requirementType) =>
      //     [
      //       PRODUCTION_SUB_TYPES.YarnDyed,
      //       PRODUCTION_SUB_TYPES.Dyed,
      //       PRODUCTION_SUB_TYPES.Printed,
      //     ].includes(requirementType),
      //   then: Yup.string()
      //     .required('Required')
      //     .min(1, 'Please enter 1-100 characters for color')
      //     .max(100, 'Please enter 1-100 characters for color'),
      //   otherwise: Yup.string(),
      // }),
      finishingType: Yup.string().when('requirementType', {
        is: (requirementType) => requirementType === PRODUCTION_SUB_TYPES.RFD,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      colorIntensity: Yup.string().when('requirementType', {
        is: (value) => value && value === PRODUCTION_SUB_TYPES.Dyed,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      printingMethod: Yup.string().when('requirementType', {
        is: (value) => value && value === PRODUCTION_SUB_TYPES.Printed,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      denimWeight: Yup.number().when('requirementType', {
        is: (value) => value && value === '6',
        then: Yup.number()
          .min(3, 'Please enter a value between 3-20 Ounce')
          .max(20, 'Please enter a value between 3-20 Ounce')
          .required('Required')
          .test('maxDigitsAfterDecimal', 'Maximum decimal allowed is 2', (number) =>
            UPTO_TWO_DECIMAL_REGEX.test(number),
          ),
        otherwise: Yup.number(),
      }),
      minWidth: Yup.number().when('requirementType', {
        is: (value) => value && value === '6',
        then: Yup.number()
          .required('Required')
          .min(20, 'Width can be between 20 to 200 Inches')
          .max(200, 'Width can be between 20 to 200 Inches')
          .test((value, context) => {
            if (context.parent.maxWidth == context.parent.minWidth) {
              return context.createError({
                message: 'Min and max width cannot be same',
              });
            } else if (context.parent.maxWidth < context.parent.minWidth) {
              return context.createError({
                message: 'The minimum width cannot be greater than maximum width',
              });
            }
            return true;
          }),
        otherwise: Yup.number().optional().nullable(),
      }),
      maxWidth: Yup.number().when('requirementType', {
        is: (value) => value && value === '6',
        then: Yup.number()
          .required('Required')
          .min(20, 'Width can be between 20 to 200 Inches')
          .max(200, 'Width can be between 20 to 200 Inches')
          .test((value, context) => {
            if (context.parent.maxWidth == context.parent.minWidth) {
              return context.createError({
                message: 'Min and max width cannot be same',
              });
            }
            return true;
          }),
        otherwise: Yup.number().optional().nullable(),
      }),
    },
    [
      ['x', 'y'],
      ['epi', 'ppi'],
    ],
  );
  const onSubmitForm = (values) => {
    const isDenim = ['6'].includes(values.requirementType);
    const isYarnDyedOrPrinted = [
      PRODUCTION_SUB_TYPES.YarnDyed,
      PRODUCTION_SUB_TYPES.Printed,
    ].includes(values.requirementType);
    const isProcessing = [
      PRODUCTION_SUB_TYPES.RFD,
      PRODUCTION_SUB_TYPES.Dyed,
      PRODUCTION_SUB_TYPES.Printed,
    ].includes(values.requirementType);
    const subcategory =
      values.requirementType != '6'
        ? materialData.productSubCategory[values.productCategory].find(
            (item) => item.subCategoryId === +values.subCategory,
          )
        : null;
    const productCategoryIdOfDenim = String(
      materialData?.productCategory.find((cat) => cat.parentCategoryName === '100% Cotton')
        ?.parentCategoryId,
    );
    const getProductionAttributeId = () => {
      switch (values.requirementType) {
        case PRODUCTION_SUB_TYPES.RFD:
          return values.finishingType;
        case PRODUCTION_SUB_TYPES.Dyed:
          return values.colorIntensity;
        case PRODUCTION_SUB_TYPES.Printed:
          return values.printingMethod;
        default:
          return null;
      }
    };

    const form = {};
    form.name = isDenim
      ? `${
          values?.otherDenimColor
            ? values?.otherDenimColor
            : DENIM_COLOR_TYPES_REVERSE[values.denimColor]
        } 100% Cotton Denim`
      : `${QUERY_CATEGORY[values.productCategory]} ${subcategory.subCategoryName}`;
    if (isYarnDyedOrPrinted || values.requirementType === PRODUCTION_SUB_TYPES.Dyed) {
      form.color = values.color;
    } else if (isDenim) {
      form.color = values?.otherDenimColor
        ? values.otherDenimColor
        : DENIM_COLOR_TYPES_REVERSE[values.denimColor];
    }
    if (isYarnDyedOrPrinted) {
      form.numberOfColors = values.noOfColors;
    }
    if (isProcessing) {
      form.productionSubTypeAttributeId = getProductionAttributeId();
    }
    if (isDenim) {
      form.minWidth = values.minWidth;
      form.weaveTypeId = values.weavePattern;
      form.weight = Number(values.denimWeight);
      form.isBeforeWash = values.weightSpecification === '1' ? 1 : 0;
      form.weightUnitId = WEIGHT_VALUES_REVERSE.Ounce;
    } else {
      form.fabricType = values.fabricType;
      form.loomType = values.loomType;
      form.threadcount = JSON.stringify({ x: values.x, y: values.y });
      form.construction = JSON.stringify({ epi: values.epi, ppi: values.ppi });
      form.weight = Number(values?.weight);
      form.weightUnitId = WEIGHT_VALUES_REVERSE.GSM;
    }
    if (values?.skuImage) {
      form.skuImage = values.skuImage?.fileUrl;
    }
    form.width = isDenim ? values.maxWidth : values?.width;
    form.productionSubType = +values.requirementType;
    form.productionType = isProcessing ? 2 : 1;
    form.unit = 2;
    form.fabricType = values.fabricType;
    form.categoryComposition = JSON.stringify([
      {
        categoryId: isDenim ? productCategoryIdOfDenim : values.subCategory,
        percentage: '100',
      },
    ]);
    createNewSKU(form).then((res) => {
      reload();
      onClose();
    });
  };

  const getLabel = (requirementType) => {
    switch (true) {
      case ['2'].includes(requirementType):
        return 'Yarn dyed fabric';

      case ['3', '4', '5'].includes(requirementType):
        return 'Finished fabric';

      default:
        return '';
    }
  };
  const [otherDenimColor, setOtherDenimColor] = useState(false);
  return (
    <Drawer
      destroyOnClose
      open={visible}
      title={'Create SKU'}
      onClose={() => {
        onClose();
      }}
      width={700}
      bodyStyle={{ paddingBottom: 80, overflow: 'hidden' }}>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}>
        {({ errors, dirty, values, setFieldValue }) => {
          const widthError = values?.widths
            ?.map(({ value }) => +value)
            .some((element) => element < MIN_WIDTH || element > MAX_WIDTH);
          const disableCondition =
            !dirty || (dirty && Object.keys(errors).length > 0) || widthError;
          return (
            <>
              <Form>
                <div
                  style={{
                    maxHeight: 'calc(100vh - 150px)',
                    overflowY: 'auto',
                    scrollbarWidth: 'none', // For Firefox
                    msOverflowStyle: 'none', // For Internet Explorer and Edge
                  }}>
                  <Row className={styles['form-row']} gutter={36}>
                    <Col className={styles['form-column']}>
                      <RadioField
                        isRequired={true}
                        name="fabricType"
                        label="Fabric type"
                        options={PRODUCT_TYPES}
                      />
                    </Col>
                    <Col className={styles['form-column']}>
                      <RadioField
                        isRequired={true}
                        name="requirementType"
                        label="Requirement type"
                        options={REQUIREMENT_TYPES_TABS}
                        boxClasses={styles.requirement}
                      />
                    </Col>
                  </Row>
                  {values?.requirementType === PRODUCTION_SUB_TYPES.YarnDyed ? (
                    <Row className={styles['form-row']} gutter={36}>
                      <Col className={styles['form-column']}>
                        <FieldInput
                          isRequired={true}
                          name="noOfColors"
                          label="Number of colors"
                          placeholder="Enter number of colors"
                          type="number"
                          isUnit="Color"
                        />
                      </Col>
                      <Col className={styles['form-column']}>
                        <FieldInput
                          name="color"
                          label="Color"
                          placeholder="Enter pantone color code"
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {values?.requirementType === PRODUCTION_SUB_TYPES.RFD ? (
                    <Row className={styles['form-row']} gutter={36}>
                      <Col className={styles['form-column']}>
                        <RadioField
                          isRequired={true}
                          name="finishingType"
                          label="Finishing type"
                          options={RFD_TYPES}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {values?.requirementType === PRODUCTION_SUB_TYPES.Dyed ? (
                    <Row className={styles['form-row']} gutter={36}>
                      <Col className={styles['form-column']}>
                        <RadioField
                          isRequired={true}
                          name="colorIntensity"
                          label="Color intensity"
                          options={DYED_TYPES}
                        />
                      </Col>
                      <Col className={styles['form-column']}>
                        <FieldInput
                          name="color"
                          label="Color"
                          placeholder="Enter pantone color code"
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {values?.requirementType === PRODUCTION_SUB_TYPES.Printed ? (
                    <Row className={styles['form-row']} gutter={36}>
                      <Col className={styles['form-column']}>
                        <RadioField
                          isRequired={true}
                          name="printingMethod"
                          label="Printing method"
                          options={PRINTED_TYPES}
                          boxClasses={styles.requirement}
                        />
                      </Col>
                      <Col className={styles['form-column']}>
                        <FieldInput
                          isRequired={true}
                          name="noOfColors"
                          label="Number of colors"
                          placeholder="Enter number of colors"
                          type="number"
                          isUnit="Color"
                        />
                      </Col>
                      <Col className={styles['form-column']}>
                        <FieldInput
                          name="color"
                          label="Color"
                          placeholder="Enter pantone color code"
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {values.requirementType != '6' ? (
                    <>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']}>
                          <RadioField
                            isRequired={true}
                            name="productCategory"
                            label="Category"
                            options={CATEGORY_TYPES}
                          />
                        </Col>
                        <Col className={styles['form-column']} span={12}>
                          <Select
                            isRequired
                            isGlobal
                            fieldKey="subCategoryId"
                            fieldValue="subCategoryName"
                            name="subCategory"
                            label="Sub-Category"
                            placeholder="Select Sub-Category"
                            options={
                              materialData.productSubCategory[String(values?.productCategory)]
                            }
                          />
                        </Col>
                      </Row>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']} span={12}>
                          <div>
                            <p className="flex justify-between" style={{ marginBottom: 8 }}>
                              <span className={styles.name}>
                                {getLabel(values?.requirementType)}{' '}
                                {['2', '3', '4', '5'].includes(values?.requirementType)
                                  ? 'thread count'
                                  : 'Thread count'}
                              </span>
                              <Status
                                isFilled={
                                  !errors?.x &&
                                  !!values?.x?.length &&
                                  !errors?.y &&
                                  !!values?.y?.length
                                }
                              />
                            </p>
                            <div className="flex">
                              <FieldInput name="x" placeholder="e.g. 120" type="text" />
                              <div className={styles['cross']}>X</div>
                              <FieldInput name="y" placeholder="e.g. 120" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col className={styles['form-column']} span={12}>
                          <div>
                            <p className="flex justify-between" style={{ marginBottom: 8 }}>
                              <span className={styles.name}>
                                {' '}
                                {getLabel(values?.requirementType)}{' '}
                                {['2', '3', '4', '5'].includes(values?.requirementType)
                                  ? 'construction'
                                  : 'Construction'}
                              </span>
                              <Status
                                isFilled={
                                  !errors?.epi && !!values?.epi && !errors?.ppi && !!values?.ppi
                                }
                              />
                            </p>
                            <div className="flex">
                              <FieldInput name="epi" placeholder="EPI" type="number" />
                              <div className={styles['cross']}>X</div>
                              <FieldInput name="ppi" placeholder="PPI" type="number" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']} span={12}>
                          <FieldInput
                            isRequired={true}
                            name="width"
                            label={`${getLabel(values?.requirementType)} ${
                              ['2', '3', '4', '5'].includes(values?.requirementType)
                                ? 'width'
                                : 'Width'
                            }`}
                            placeholder="Enter Width"
                            type="number"
                            isUnit="inch"
                          />
                        </Col>
                        <Col className={styles['form-column']} span={12}>
                          <FieldInput
                            isRequired
                            name="weight"
                            label={`${getLabel(values?.requirementType)} ${
                              ['2', '3', '4', '5'].includes(values?.requirementType)
                                ? 'weight'
                                : 'Weight'
                            }`}
                            placeholder="Enter Weight"
                            type="number"
                            isUnit="GSM"
                          />
                        </Col>
                      </Row>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']} span={12}>
                          <RadioField
                            isRequired={true}
                            name="loomType"
                            label="Loom type"
                            options={materialData.loomType}
                            isUpdate={false}
                            boxClasses={styles.requirement}
                          />
                        </Col>
                        <Col
                          className={styles['form-column']}
                          style={{ marginBottom: 0 }}
                          span={12}>
                          <>
                            <div>
                              <UploadMediaWithS3
                                uploadButtonName="Attach a picture"
                                name="skuImage"
                                label="Add picture"
                                acceptFileType=".pdf, .png, .jpg, .jpeg"
                              />
                            </div>
                          </>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {values.requirementType === '6' ? (
                    <>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']}>
                          <RadioField
                            isRequired={true}
                            name="denimColor"
                            label="Denim color "
                            options={DENIM_COLOR_TYPES}
                            boxClasses={styles.requirement}
                            setOtherDenimColor={setOtherDenimColor}
                            setMainFeildValue={setFieldValue}
                          />
                          {otherDenimColor ? (
                            <FieldInput
                              name="otherDenimColor"
                              label=""
                              placeholder="Enter color"
                              type="string"
                            />
                          ) : null}
                        </Col>
                        <Col className={styles['form-column']}>
                          <p className={styles.name}>Weight</p>
                          <Field name="weightSpecification" required>
                            {({ field }) => (
                              <div className={styles['weightSpecification']}>
                                {WEIGHT_TABS.map((tab) => {
                                  const isTabSelected = field.value == tab.id;
                                  return (
                                    <div
                                      className={`${styles['toggleBox']} ${
                                        isTabSelected ? styles['selected'] : styles['unselected']
                                      }`}
                                      style={{ width: '-webkit-fill-available' }}
                                      onClick={() => {
                                        setFieldValue(field.name, tab.id);
                                      }}
                                      type="button"
                                      key={tab.id}>
                                      {tab.name}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Field>
                          <div style={{ marginTop: 24 }}>
                            <FieldInput
                              isRequired={true}
                              name="denimWeight"
                              label=""
                              placeholder="Enter weight"
                              type="number"
                              isUnit="Ounce"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col className={styles['form-column']}>
                          <div className="flex" style={{ justifyContent: 'space-between' }}>
                            <p>Width</p>
                            <Status isFilled={!errors.minWidth && !errors.maxWidth} />
                          </div>
                          <div className="flex" style={{ alignItems: 'flex-start' }}>
                            <FieldInput
                              name="minWidth"
                              label="Min "
                              placeholder="Enter weight"
                              type="number"
                              isUnit="Inch"
                            />
                            <div style={{ marginRight: 8 }} />
                            <FieldInput
                              name="maxWidth"
                              label="Max"
                              placeholder="Enter weight"
                              type="number"
                              isUnit="Inch"
                            />
                          </div>
                        </Col>
                        <Col className={styles['form-column']}>
                          <RadioField
                            isRequired={true}
                            name="weavePattern"
                            label="Weave pattern"
                            options={WEAVE_PATTERN}
                            boxClasses={styles.requirement}
                          />
                        </Col>
                      </Row>
                      <Row className={styles['form-row']} gutter={36}>
                        <Col
                          className={styles['form-column']}
                          style={{ marginBottom: 0 }}
                          span={12}>
                          <>
                            <div style={{ marginBottom: 90 }}>
                              <UploadMediaWithS3
                                uploadButtonName="Attach a picture"
                                name="skuImage"
                                label="Add picture "
                                acceptFileType=".pdf, .png, .jpg, .jpeg"
                              />
                            </div>
                          </>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </div>
                <div className={styles['form-button']} style={{ marginTop: 0 }}>
                  <button
                    type="submit"
                    disabled={disableCondition}
                    className={disableCondition ? styles.saveDisabled : styles.save}
                    style={false ? { width: '134px' } : { width: '50%' }}>
                    Create
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Drawer>
  );
};
const mapStateToProps = (state) => ({
  materialData: state.fabricQualities.materialData,
});
const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
  createNewSKU: (data) => dispatch(createNewSKU(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagementForm);
