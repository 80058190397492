import * as Auth from "./AuthActions";
import * as Common from "./CommonActions";
import * as Global from "./GlobalActions";
import * as BrandActions from "./BrandActions";
import * as LogiPartnerActions from "./LogisticActions";
import * as user from "./UserActions";
import * as fabricQualities from "./FabricQualitiesActions";
import * as liveRateQualities from "./LiveRates";
import * as inventoryManagement from './InventoryAction'
export default {
  ...Common,
  ...Auth,
  ...Global,
  ...BrandActions,
  ...LogiPartnerActions,
  ...user,
  ...fabricQualities,
  ...liveRateQualities,
  ...inventoryManagement,
};
