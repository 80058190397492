import React, { useEffect, useMemo, useState } from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import Table from './Table';
import EmptyScreen from './EmptyScreen';
import { getMills, getSupplierManagers } from 'store/actions/UserActions';
import { PageHeader, Pagination, Input, Button, Modal, Radio, Divider, Select } from 'antd';
import './style.scss';
import history from 'utilites/history';
import { Field } from 'formik';
import { CATEGORY, FABRIC_TYPES, REQUIREMENT_TYPES, STATUS_OPTIONS } from './constants';

const Mills = ({
  mills,
  allSupplierManagers,
  getMills,
  getSupplierManagers,
  path,
  ...restProps
}) => {
  const queryParams = qs.parse(restProps.location.search);
  const { Search } = Input;
  const { Option } = Select;
  const [totalMills, setTotalMills] = useState(0);
  const [currentPage, setCurrentPage] = useState(+queryParams.page || 1);
  const hasMills = useMemo(() => mills && mills.data.length > 0, [mills]);
  const [searchInput, setSearchInput] = useState(queryParams.search || '');
  const [isSearchData, setIsSearchedData] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [fabric, setFabric] = useState([]);
  const [services, setServices] = useState([]);
  const [category, setCategory] = useState([]);
  const [supplierManagerId, setSupplierManagerId] = useState([]);
  useEffect(() => {
    getMills({
      limit: 10,
      offset: ((+queryParams.page || currentPage) - 1) * 10,
      search: queryParams.search ?? '',
    });
    getSupplierManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTotalMills(mills?.data[0]?.totalSuppliersCount?.count);
  }, [mills.data]);
  const handleChange = (pageNumber) => {
    getMills({
      limit: 10,
      offset: (pageNumber - 1) * 10,
      search: searchInput,
      status,
      fabric,
      services,
      category,
      supplierManagerId,
    });
    setCurrentPage(pageNumber);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleSearch = (value) => {
    return value;
  };
  const onFabricChange = ({ target: { value, checked } }) => {
    if (checked) {
      setFabric([...fabric, value]);
    } else {
      let freshArray = fabric.filter((val) => val !== value);
      setFabric([...freshArray]);
    }
  }
  const onServicesChange = ({ target: { value, checked } }) => {
    if (checked) {
      setServices([...services, value]);
    } else {
      let freshArray = services.filter((val) => val !== value);
      setServices([...freshArray]);
    }
  }
  const onCategoryChange = ({ target: { value, checked } }) => {
    if (checked) {
      setCategory([...category, value]);
    } else {
      let freshArray = category.filter((val) => val !== value);
      setCategory([...freshArray]);
    }
  };
  const filterCancelHandler = (isModalClosed) => {
    setStatus('');
    setFabric([]);
    setServices([]);
    setCategory([]);
    setSupplierManagerId([]);
    if(isModalClosed){
    setIsModalVisible(false);
    }
  }
  const handleOk = () => {
    getMills({
      status,
      fabric,
      services,
      category,
      supplierManagerId,
    });
    setIsModalVisible(false);
  };
  function onSearchInput(value) {
    getMills({ search: value ?? searchInput });
    history.push(`/factory/settings/mills/?search=${value ?? searchInput}`);
    setCurrentPage(1);
  }
  return (
    <div className="settings-mills">
      <PageHeader
        className="site-page-header"
        title="Mills"
        extra={[
          <>
            <Search
              placeholder="Input search text"
              allowClear
              enterButton
              className="search-input"
              value={searchInput}
              onPressEnter={() => onSearchInput()}
              onSearch={onSearchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <Button key="1" type="primary" onClick={showModal} className="filter">
              Filter
            </Button>
          </>,
          <Modal
            title="Filters"
            visible={isModalVisible}
            onCancel={() => filterCancelHandler(true)}
            width={500}
            okText="Apply"
            cancelText="Clear"
            className="supplier-modal modal-postion"
            footer={[
              <Button key="submit" className="clear" onClick={() => filterCancelHandler(false)}>
                Clear
              </Button>,
              <Button key="submit" className="apply" onClick={() => handleOk()}>
                Apply
              </Button>,
            ]}
          >
            <div className="row">
              <span className="title">
                <h3>Status</h3>
              </span>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                onChange={({ target: { value } }) => setStatus(value)}
              >
                {STATUS_OPTIONS.map((option) => (
                  <label key={option.value} className="radioButton">
                    <input
                      type="radio"
                      name="status"
                      value={option.value}
                      checked={status === option.value}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="row">
              <span className="title">
                <h3>Fabric type</h3>
              </span>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                onChange={(e) => onFabricChange(e)}
              >
                {FABRIC_TYPES.map((option) => (
                  <label key={option.value} className="radioButton">
                    <input
                      type="checkbox"
                      name="fabric"
                      value={option.value}
                      checked={fabric.includes(option.value)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="row">
              <span className="title">
                <h3>Services</h3>
              </span>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                onChange={(e) => onServicesChange(e)}
              >
                {REQUIREMENT_TYPES.map((option) => (
                  <label key={option.value} className="radioButton">
                    <input
                      type="checkbox"
                      name="services"
                      value={option.value}
                      checked={services.includes(option.value)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="row">
              <span className="title">
                <h3>Category</h3>
              </span>
              <div
                role="group"
                className="checkbox-group"
                aria-labelledby="checkbox-group"
                onChange={(e) => onCategoryChange(e)}
              >
                {CATEGORY.map((option) => (
                  <label key={option.value} className="radioButton">
                    <input
                      type="checkbox"
                      name="category"
                      value={option.value}
                      checked={category.includes(option.value)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <span className="title">
                <h3>Select supplier manager</h3>
              </span>
              <Select
                showSearch
                placeholder="Select supplier manager"
                onChange={setSupplierManagerId}
                onSearch={(e) => handleSearch(e)}
                optionFilterProp="children"
                className="dropdown"
                mode="multiple"
                value={supplierManagerId}
              >
                {allSupplierManagers?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            ,
          </Modal>,
        ]}
      />
      {hasMills ? (
        <>
          <Table
            data={mills.data}
            path={path}
            queryParams={{ ...queryParams, page: currentPage }}
            onChange={handleChange}
          />
          <div className="millsPagination">
            {!isSearchData && (
              <Pagination
                defaultCurrent={currentPage}
                total={totalMills}
                onChange={handleChange}
                current={currentPage}
              />
            )}
          </div>
        </>
      ) : (
        <EmptyScreen />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  mills: props.users || state.user.mills,
  allSupplierManagers: props.allSupplierManagers || state.user.supplierManagers?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getMills: (data) => dispatch(getMills(data)),
  getSupplierManagers: () => dispatch(getSupplierManagers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mills);
