import React, { Component } from 'react';
import CurrencyForm from './CurrencyForm';
import './CurrencyFactorModal.scss';

export default class CurrencyFactorModal extends Component {
  render() {
    const { onSubmit, initialValues, closeModal, updateCurrency, getCurrencyRate } = this.props;
    return (
      <div className="currency-modal">
        <div className="container">
          <CurrencyForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            closeModal={closeModal}
            updateCurrency={updateCurrency}
            getCurrencyRate={getCurrencyRate}
          />
        </div>
      </div>
    );
  }
}
