import thunk from "redux-thunk";
import Reducers from "./reducers";
import { createStore, applyMiddleware, compose } from "redux";

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose;

export default createStore(Reducers, composeEnhancers(applyMiddleware(thunk)));