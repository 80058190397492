import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Divider, Modal, DatePicker } from 'antd';
import { Formik, Form, Field } from 'formik';
import { Select as AntdSelect } from 'antd';
import * as Yup from 'yup';
import './styles.scss';
import {
  paymentNature,
  DEFAULT_CREDIT_NATURE,
  DEFAULT_CREDIT_PERIOD,
  creditNaturePills,
  creditPeriodPills,
  UPTO_TWO_DECIMAL_REGEX,
  creditPeriodPillsInternational,
} from './config';
import { getFileName } from 'helpers/getNameFromUrl';
import FileUpload from 'components/HOCs/FileUpload';
import { PAYMENT_BEFORE_DELIVERY, PAYMENT_NATURE } from '../../constants';
import moment from 'moment';
import { blurOnScroll } from 'helpers/formHelper';

const ANNUAL_TURNOVER = (isInternational) =>
  Yup.object().shape({
    advanceRequired: Yup.string().required('Required'),
    annualTurnover: Yup.number().when('paymentNature', {
      is: (value) => !isInternational && value === PAYMENT_NATURE.CREDIT,
      then: Yup.number()
        .required('Required')
        .typeError('Required')
        .test('maxDigitsAfterDecimal', 'Maximum decimal alowed is 2', (number) =>
          UPTO_TWO_DECIMAL_REGEX.test(number),
        )
        .positive()
        .max(50000),
      otherwise: Yup.number()
        .test(
          'maxDigitsAfterDecimal',
          'Maximum decimal alowed is 2',
          (number) => (number && UPTO_TWO_DECIMAL_REGEX.test(number)) || !number,
        )
        .nullable()
        .positive()
        .max(50000),
    }),
    annualTurnoverDate: Yup.date().when('paymentNature', {
      is: (value) => !isInternational && value === PAYMENT_NATURE.CREDIT,
      then: Yup.date().required('Required'),
      otherwise: Yup.date().nullable().notRequired(),
    }),
    customerAgreement: Yup.mixed().notRequired(),
    paymentNature: Yup.string().notRequired(),
    annualTurnoverDocs: Yup.mixed().when('paymentNature', {
      is: (value) => !isInternational && value === PAYMENT_NATURE.CREDIT,
      then: Yup.mixed().required('Required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    creditPeriod: Yup.array().when('paymentNature', {
      is: (value) => value === PAYMENT_NATURE.CREDIT,
      then: Yup.array().required('Required'),
      otherwise: Yup.array().notRequired(),
    }),
  });
const AnnualTurnover = ({ customer, updateAnnualTurnover }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [fileAT, setFileAT] = useState('');
  const [fileCA, setFileCA] = useState('');
  const [error, setError] = useState(false);
  const { Option } = AntdSelect;
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = (values, isValid, isSubmitting) => {
    if (isValid && !isSubmitting) {
      updateAnnualTurnover({
        id: customer?.id,
        params: values,
        gstCertificate: customer?.gstCertificate,
      });
      setIsModalVisible(false);
    }
  };
  const handleCancel = (setValues, initialValues) => {
    setValues({ ...initialValues });
    setFileAT('');
    setFileCA('');
    setIsModalVisible(false);
  };
  const getCreditNature = (credit) => {
    let creditNatureObj = creditNaturePills.filter((item) => +item.value === +credit);
    return creditNatureObj[0]?.label;
  };
  const getCreditPeriods = (period) => {
    const result = [];
    for (let i = 0; i < period?.length; i++) {
      result.push(period[i] === 0 ? 'Cash' : `${period[i]} days`);
    }
    return result;
  };
  const {
    isInternational,
    customerAgreement,
    annualTurnover,
    annualTurnoverDocs,
    creditNature,
    creditPeriod,
    advanceRequired,
    annualTurnoverDate,
  } = customer;
  useEffect(() => {
    setInitialValues({
      customerAgreement: customerAgreement || '',
      annualTurnover: Number(annualTurnover) || null,
      annualTurnoverDocs: annualTurnoverDocs || '',
      paymentNature:
        creditNature !== PAYMENT_BEFORE_DELIVERY ? PAYMENT_NATURE.CREDIT : PAYMENT_NATURE.PBD,
      creditPeriod: creditPeriod?.map(String) || [],
      creditNature: creditNature || '',
      advanceRequired: advanceRequired || '',
      annualTurnoverDate: annualTurnoverDate ? moment(annualTurnoverDate) : null,
    });
  }, [
    isInternational,
    customerAgreement,
    annualTurnover,
    annualTurnoverDocs,
    creditNature,
    creditPeriod,
    advanceRequired,
    annualTurnoverDate,
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    setStartDate(moment().subtract(2, 'years'));
    setEndDate(moment());
  }, []);
  return (
    <>
      <div className="basicInfocontainer">
        <Formik
          initialValues={initialValues}
          validationSchema={ANNUAL_TURNOVER(isInternational)}
          onSubmit={(values, { setSubmitting }) => {}}
          enableReinitialize
        >
          {({
            dirty,
            values,
            errors,
            isValid,
            touched,
            isSubmitting,
            setFieldValue,
            setValues,
          }) => {
            const { annualTurnover, annualTurnoverDocs, annualTurnoverDate } = values;
            if (
              !isInternational &&
              values.paymentNature === PAYMENT_NATURE.CREDIT &&
              !(annualTurnover && annualTurnoverDocs && annualTurnoverDate)
            ) {
              setError(true);
            } else {
              setError(false);
            }
            return (
              <Form>
                <>
                  <div className="basicHeader">
                    <div> Annual Turnover and Payment Terms</div>
                    <Button type="link" onClick={showModal}>
                      Edit
                    </Button>
                    <Modal
                      title="Edit Annual Turnover and Payment Terms"
                      visible={isModalVisible}
                      onOk={() => handleOk(values, isValid, isSubmitting)}
                      onCancel={() => handleCancel(setValues, initialValues)}
                      width={500}
                      okText="Save"
                      okButtonProps={{
                        disabled: !dirty || Object.keys(errors).length > 0,
                      }}
                      cancelButtonProps={{ style: { display: 'none' } }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Customer Agreement</label>
                            <br />
                            <FileUpload
                              file={fileAT}
                              setFile={setFileAT}
                              name="customerAgreement"
                              existingFileName={customer?.customerAgreement}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className="element">
                            <label className="label">Annual Turnover (in Crores)</label>
                            <Field
                              name="annualTurnover"
                              className="advanceRequiredInput turnover"
                              type="number"
                              onWheel={blurOnScroll}
                            />
                            {errors.annualTurnover && (
                              <p className="error">{errors.annualTurnover}</p>
                            )}
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Annual Turnover Document</label>
                            <br />
                            <FileUpload
                              name="annualTurnoverDocs"
                              file={fileCA}
                              setFile={setFileCA}
                              existingFileName={customer?.annualTurnoverDocs || null}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Annual Turnover Date</label>
                            <br />
                            <Field name="annualTurnoverDate">
                              {({ field, form: { setFieldValue, setFieldTouched } }) => {
                                return (
                                  <div className="date-wrapper">
                                    <DatePicker
                                      className="date"
                                      placeholder="Select Date"
                                      value={field.value}
                                      disabledDate={(current) => {
                                        return (
                                          current.isSameOrBefore(startDate, 'day') ||
                                          current.isAfter(endDate, 'day')
                                        );
                                      }}
                                      onChange={(event) => {
                                        setFieldTouched(field.name, true);
                                        setFieldValue(field.name, event);
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                        </Col>
                        <Divider />

                        <div className="payment-label">Payment Terms</div>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div className="element">
                              <label className="label">Advance Required Percentage</label>
                              <Field name="advanceRequired" className="advanceRequiredInput" />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="element">
                              <label className="label">Payment Nature</label>
                              <Field
                                as="select"
                                name="paymentNature"
                                className="paymentTermsInput"
                                onChange={({ target: { value } }) => {
                                  setFieldValue('paymentNature', value);
                                  if (value === PAYMENT_NATURE.CREDIT) {
                                    setFieldValue('creditPeriod', [DEFAULT_CREDIT_PERIOD]);
                                    setFieldValue('creditNature', DEFAULT_CREDIT_NATURE);
                                  } else {
                                    setFieldValue('creditNature', PAYMENT_BEFORE_DELIVERY);
                                  }
                                }}
                              >
                                {paymentNature.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </Field>
                              {error && (
                                <p className="error">
                                  Please upload turnover details for converting into credit
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        {values.paymentNature === PAYMENT_NATURE.CREDIT && (
                          <Row gutter={16}>
                            <Col span={12}>
                              <div className="element">
                                <label className="label">Credit Period</label>
                                <br />
                                <Field name="creditPeriod">
                                  {({
                                    field,
                                    meta: { error, touched },
                                    form: { setFieldValue },
                                  }) => {
                                    return (
                                      <AntdSelect
                                        className="creditPeriod"
                                        size="large"
                                        showArrow
                                        showSearch
                                        name={field.name}
                                        value={field.value}
                                        mode="multiple"
                                        bordered={false}
                                        disabled={values?.paymentNature === 'PBD'}
                                        onChange={(values, index) => {
                                          return setFieldValue(field.name, values);
                                        }}
                                        maxTagCount="responsive"
                                      >
                                        {(isInternational === 1
                                          ? creditPeriodPillsInternational
                                          : creditPeriodPills
                                        ).map((item) => (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        ))}
                                      </AntdSelect>
                                    );
                                  }}
                                </Field>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="element">
                                <label className="label">Credit Nature</label>
                                <br />
                                <Field
                                  as="select"
                                  name="creditNature"
                                  className="paymentTermsInput"
                                  disabled={values?.paymentNature === 'PBD'}
                                >
                                  {creditNaturePills.map((item, index) => (
                                    <option key={index} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </Modal>
                  </div>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <div className="element">
                        <label className="label">Customer Agreement</label>
                        <br />

                        <Button href={customer?.customerAgreement}>{`${
                          customer?.customerAgreement
                            ? getFileName(customer?.customerAgreement)
                            : 'Not Available'
                        }`}</Button>
                      </div>
                      <div className="element">
                        <label className="label">Annual Turnover (in Crores)</label>
                        <Input
                          className="inputFormikClear"
                          disabled
                          value={customer?.annualTurnover}
                        />
                      </div>
                      <div className="element docs">
                        <label className="label">Annual Turnover Document</label>
                        <br />

                        <Button href={customer?.annualTurnoverDocs}>{`${
                          customer?.annualTurnoverDocs
                            ? getFileName(customer?.annualTurnoverDocs)
                            : 'Not Available'
                        }`}</Button>
                      </div>
                      <div className="element">
                        <label className="label">Annual Turnover Date</label>
                        <Input
                          className="inputFormikClear"
                          disabled
                          value={
                            annualTurnoverDate
                              ? moment(annualTurnoverDate).format('YYYY-MM-DD')
                              : null
                          }
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <p className="payment-label">Payment Terms</p>
                      <Row>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Advance Required %</label>
                            <Input
                              className="inputFormikClear"
                              value={customer?.advanceRequired || ''}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Payment Nature</label>
                            <Input
                              className="inputFormikClear"
                              disabled
                              value={
                                customer?.creditNature !== PAYMENT_BEFORE_DELIVERY
                                  ? 'Cash + Credit'
                                  : 'Cash'
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Credit periods</label>
                            <Input
                              className="inputFormikClear"
                              disabled
                              value={
                                customer?.creditNature !== PAYMENT_BEFORE_DELIVERY
                                  ? getCreditPeriods(customer?.creditPeriod)
                                  : ''
                              }
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="element">
                            <label className="label">Credit Nature</label>
                            <Input
                              className="inputFormikClear"
                              value={getCreditNature(customer?.creditNature) || ''}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Divider />
    </>
  );
};

export default AnnualTurnover;
