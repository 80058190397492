import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Button from "../../UI/Button/Button";
import { required } from "./../../../helpers/form-validations";
import Input from "./../../UI/Form/Input/Input";
import "./Signup.scss";

const formName = "Signup";

class Signup extends Component {
  render() {
    const {
      handleSubmit
      // pristine, reset, submitting
    } = this.props;

    return (
      <div className="signup__wrapper">
        <Link to="#" onClick={() => this.props.history.goBack()}>
          <Button className="f-w-bold" category="rounded faded">
            <i class="fa fa-arrow-left" /> &nbsp;&nbsp; Go Back
          </Button>
        </Link>
        <h1 className="signup__title">Create a new brand admin for Locofast</h1>
        <form
          onSubmit={val => {
            handleSubmit(val);
            this.props.history.goBack();
          }}
        >
          <Field
            name="fullName"
            component={Input}
            type="text"
            label="Full Name"
            placeholder="Firstname Lastname"
          />
          <Field
            name="companyName"
            component={Input}
            type="text"
            label="Company Name"
            placeholder="Your Company Name"
          />
          <Field
            name="phoneNumber"
            component={Input}
            prefixText="+91 -"
            type="tel"
            label="Phone Number"
            maxLength="10"
          />
          <Field
            name="workEmail"
            component={Input}
            type="email"
            label="Email"
            placeholder="eg. yourname@yourcompany.com"
          />
          <span className="signup__toc flex f-color-faded f-sz-sm">
            <Field name="toc" id="toc" component="input" type="checkbox" validate={[required]} />
            <label htmlFor="toc">
              I hereby agree with the Terms of Service and Privacy Policy of Locofast.
            </label>
          </span>
          <br />
          <Button className="f-w-bold" category="large">
            Signup
          </Button>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: formName
})(Signup);
