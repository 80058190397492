import React, { Component } from "react";
import AddEditUserForm from "./AddEditUserForm";

export default class AddEditUserModal extends Component {
  render() {
    const { onSubmit, initialValues } = this.props;
    return (
      <div className="add-edit-user-modal">
        <div className="container">
          <AddEditUserForm onSubmit={onSubmit} initialValues={initialValues} />
        </div>
      </div>
    );
  }
}
