import AlertImg from "../../assets/images/communication/ic-alert.svg";
import InviteUsersForm from "../../components/AccountSettings/InviteUsers/InviteUsersForm";
import UserDetails from "../../components/AccountSettings/UserDetails/UserDetails";
import FirstLogin from "../../components/Authentication/FirstLogin/FirstLogin";
import manageLfUsersAddUser from "../../components/AccountSettings/ManageLfUsers/AddUserModal";
import BrandDetailsModal from "./../../components/AccountSettings/BrandSignup/BrandDetailsModal";
import PartnerDetailsModal from "./../../components/AccountSettings/CourierPartners/PartnerModals/PartnerDetailsModal.jsx";
import BrandUserModal from "./../../components/AccountSettings/BrandSignup/AddUserModal";
import ResetPasswordModal from "../../components/AccountSettings/ResetPassword/ResetPasswordModal";
import UpdateUserPhoneModal from "../../components/AccountSettings/ProfileSettings/UpdateUserPhoneModal";
import AddEditUserModal from "../../components/AccountSettings/ManageUsers/AddEditUserModal";
import AddEditVendorModal from "components/AccountSettings/ManageVendors/AddEditVendor";
import AddCustomerModal from "components/AccountSettings/Customers/AddCustomerModal";
import OrgSelectUserModal from "components/AccountSettings/OrgSettings/OrgSelectUserModal";
import CurrencyFactorModal from "components/AccountSettings/CurrencyFactor/CurrencyModal/CurrencyFactorModal";

// Sales head team management
import TeamMembers from "components/AccountSettings/ManageLfUsers/Team/TeamMembers";
import ManageMembers from "components/AccountSettings/ManageLfUsers/Team/ManageMembers";

// settings/users
import DeactivateModal from "components/AccountSettings/ManageLfUsers/DeactivateModal";
import RoleChangeModal from "components/AccountSettings/ManageLfUsers/RoleChangeModal";

export default {
  DELETE_COLLECTION: {
    title: "Delete Collection",
    innerContent: "Are you sure you want to delete this collection ?",
    footer: "Delete Collection",
    formName: "",
    submitAction: "deleteCollection",
  },
  DELETE_DESIGN: {
    titleImg: AlertImg,
    title: "Delete Design?",
    className: "alert-modal",
    innerContent:
      "Once the design is deleted, all details associated with the design will be deleted.",
    footer: "Delete Design",
    formName: "",
    withCancel: true,
    submitAction: "deleteDesign",
  },
  ARCHIVE_DESIGN: {
    title: "Archive Design",
    innerContent: "Are you sure you want to archive this design ?",
    footer: "Archive Design",
    formName: "",
    submitAction: "updateDesignStatus",
  },
  RESTORE_DESIGN: {
    title: "Restore Design",
    innerContent: "Are you sure you want to restore this design ?",
    footer: "Restore Design",
    formName: "",
    submitAction: "updateDesignStatus",
  },
  INVITE_USERS: {
    title: "Invite Users",
    innerContent: InviteUsersForm,
    footer: "Send Invite(s)",
    formName: "InviteUsers",
    submitAction: "inviteUsers",
    modalType: "right-full-height",
  },
  USER_DETAILS: {
    title: "User Details",
    innerContent: UserDetails,
    footer: null,
    formName: null,
    submitAction: null,
    modalType: "right", //right-full-height
  },
  FIRST_LOGIN: {
    title: null,
    innerContent: FirstLogin,
    footer: "CONTINUE TO DASHBOARD",
    formName: "FirstLogin",
    submitAction: "updateUserDetails",
    noEsc: true,
  },
  ADD_LF_USER: {
    title: "Add User",
    innerContent: manageLfUsersAddUser,
    footer: "Save",
    formName: "",
    submitAction: "getLocofastUsers",
    modalType: "v2",
  },
  UPDATE_LF_USER: {
    title: "Edit User",
    innerContent: manageLfUsersAddUser,
    footer: "Save",
    formName: "",
    submitAction: null,
    modalType: "v2",
  },
  ADD_NEW_PARTNER: {
    title: "Add Partner",
    formName: "AddPartner",
    innerContent: PartnerDetailsModal,
    footer: null,
    modalType: "v2 brand-details",
  },
  CURRENCY_FACTOR: {
    title: "Update currency factor",
    formName: "CurrencyForm",
    innerContent: CurrencyFactorModal,
    footer: null,
    modalType: "v2 brand-details",
  },
  EDIT_PARTNER_DETAILS: {
    title: "Edit Partner",
    formName: "AddPartner",
    innerContent: PartnerDetailsModal,
    footer: "Update",
    submitAction: "updateLogiPartner",
    modalType: "v2 brand-details",
  },
  ADD_NEW_BRAND: {
    title: "Add Brand",
    formName: "AddBrand",
    innerContent: BrandDetailsModal,
    footer: "Save",
    submitAction: "createNewBrand",
    modalType: "v2 brand-details",
  },
  EDIT_BRAND_DETAILS: {
    title: "Edit Brand",
    formName: "AddBrand",
    innerContent: BrandDetailsModal,
    footer: "Update",
    submitAction: "updateBrandDetails",
    modalType: "v2 brand-details",
  },
  ADD_BRAND_USER: {
    title: "Add User",
    formName: "AddUser",
    innerContent: BrandUserModal,
    footer: "Save",
    submitAction: "createBrandUser",
    modalType: "v2",
  },
  EDIT_BRAND_USER: {
    title: "Edit User",
    formName: "EditUser",
    innerContent: BrandUserModal,
    footer: "Update",
    submitAction: "updateBrandUser",
    modalType: "v2",
  },
  CHANGE_PASSWORD: {
    title: "Change password",
    formName: "ResetPasswordForm",
    innerContent: ResetPasswordModal,
    footer: "Save Changes",
    submitAction: "changePassword",
    modalType: "v2",
  },
  UPDATE_USER_PHONE: {
    title: "Edit mobile number",
    formName: "UpdateUserPhoneForm",
    innerContent: UpdateUserPhoneModal,
    footer: "Save Changes",
    submitAction: "updateUserPhone",
    modalType: "v2",
  },
  SET_USER_PHONE: {
    title: "Add mobile number",
    formName: "UpdateUserPhoneForm",
    innerContent: UpdateUserPhoneModal,
    footer: "Save Changes",
    submitAction: "updateUserPhone",
    modalType: "v2",
  },
  ADD_USER: {
    title: "Add user",
    formName: "AddEditUserForm",
    innerContent: AddEditUserModal,
    footer: "Add User",
    submitAction: "createUser",
    modalType: "v2",
  },
  EDIT_USER: {
    title: "Edit user",
    formName: "AddEditUserForm",
    innerContent: AddEditUserModal,
    footer: "Save changes",
    submitAction: "updateUser",
    modalType: "v2",
  },
  ADD_VENDOR: {
    title: "Add vendor",
    formName: "AddEditVendor",
    innerContent: AddEditVendorModal,
    footer: "Add Vendor",
    submitAction: "postMaterialVendors",
    modalType: "v2",
  },
  EDIT_VENDOR: {
    title: "Edit vendor",
    formName: "AddEditVendor",
    innerContent: AddEditVendorModal,
    footer: "Save changes",
    submitAction: "editMaterialVendors",
    modalType: "v2",
  },
  ADD_CUSTOMER: {
    title: "Add Customer",
    innerContent: AddCustomerModal,
    footer: null,
    submitAction: null,
  },
  EDIT_CUSTOMER: {
    title: "Edit Customer",
    innerContent: AddCustomerModal,
    footer: null,
    submitAction: null,
  },
  ASSIGN_ACCOUNT_MANAGER: {
    title: "Add Account Manager",
    innerContent: OrgSelectUserModal,
    footer: null,
    submitAction: null,
  },
  UNASSIGN_ACCOUNT_MANAGER: {
    title: "Remove Account Manager",
    innerContent:
      "This will remove the account manager, are you sure you want to proceed?",
    footer: "Proceed",
    formName: "",
    submitAction: "assignUsersToOrg",
  },
  VIEW_TEAM_MEMBERS: {
    innerContent: TeamMembers,
    footer: null,
    formName: null,
    submitAction: null,
    className: "view-team-modal",
  },
  MANAGE_TEAM_MEMBERS: {
    innerContent: ManageMembers,
    footer: null,
    formName: null,
    submitAction: null,
    className: "manage-team-modal",
  },
  ROLE_DEACTIVATE: {
    innerContent: DeactivateModal,
    title: null,
    footer: null,
    formName: null,
    submitAction: null,
    className: "deactivate-user-modal",
  },
  ROLE_CHANGE: {
    innerContent: RoleChangeModal,
    title: null,
    footer: null,
    formName: null,
    submitAction: null,
    className: "role-change-modal",
  },
};
