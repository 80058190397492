import React, {useEffect, useState} from "react";
import "./style.scss";
import { connect } from "react-redux";
import * as Yup from "yup";
import BasicInfo from "./BasicInfo";
import AnnualTurnover from "./AnnualTurnover";
import PerfectScrollBar from "react-perfect-scrollbar";
import VirtualPayment from "./VirtualPayment";
import Address from "./Address";
import CustomerDetailHeader from "./CustomerDetailHeader";
import {
  getAccountManagers,
  getCustomers,
  getCustomerByIdFabric,
  vpaDetails,
  updateAccountManager,
  deactivateCustomer,
  updateBasicInfo,
  updateAnnualTurnover,
  refreshGST,
  generateVPA,
  updateMSMEStatus,
  updateOdaStatus
} from "store/actions/UserActions";
const CustomerDetailsPage = ({
  id,
  getCustomerByIdFabric,
  vpaDetails,
  updateBasicInfo,
  getAccountManagers,
  currentUser,
  deactivateCustomer,
  updateAccountManager,
  customersByID,
  vpaByID,
  updateAnnualTurnover,
  refreshGST,
  updateMSME,
  updateODA,
  generateVPA,
  vpaRequest,
  refreshRequest,
  msmeRequest,
}) => {
  const [listOfAM, setListOfAM] = useState([]);

  useEffect(() => {
    getCustomerByIdFabric({ id: +id });
    vpaDetails({id: +id});
  }, [id]);

  useEffect(() => {
    getAccountManagers().then(
      (res) => {
        setListOfAM(res?.payload);
      },
      [getAccountManagers]
    );
  }, [id, getAccountManagers]);
  return (
    <div>
      <CustomerDetailHeader
        customer={customersByID}
        listOfAM={listOfAM}
        currentUser={currentUser}
        deactivateCustomer={deactivateCustomer}
        updateAccountManager={updateAccountManager}
      />
      <PerfectScrollBar className="scrollbar_view">
        <BasicInfo customer={customersByID} updateBasicInfo={updateBasicInfo} refreshGST={refreshGST} refreshRequest={refreshRequest} updateMSME={updateMSME} msmeRequest={msmeRequest}/>
        <AnnualTurnover
          customer={customersByID}
          updateAnnualTurnover={updateAnnualTurnover}
        />
        <VirtualPayment customer={customersByID} vpa={vpaByID} generateVPA={generateVPA} loading={vpaRequest}/>
        <Address customer={customersByID}  updateODA={updateODA}/>
      </PerfectScrollBar>
    </div>
  );
};
const mapStateToProps = (state) => ({
  customersByID: state.user.customerByID.data,
  currentUser: state.auth.userDetails,
  vpaByID: state.user.vpaByID.data.vpaDetails,
  refreshRequest: state.user.refreshGST,
  msmeRequest: state.user.msmeRequest,
  vpaRequest: state.user.generatedVPA?.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomers({ withCount: 1 })),
  getCustomerByIdFabric: (id) => dispatch(getCustomerByIdFabric(id)),
  vpaDetails: (id) => dispatch(vpaDetails(id)),
  getAccountManagers: () => dispatch(getAccountManagers()),
  updateAccountManager: (data) => dispatch(updateAccountManager(data)),
  deactivateCustomer: (data) => dispatch(deactivateCustomer(data)),
  updateBasicInfo: (data) => dispatch(updateBasicInfo(data)),
  updateAnnualTurnover: (data) => dispatch(updateAnnualTurnover(data)),
  refreshGST: (data) => dispatch(refreshGST(data)),
  updateMSME: (data) => dispatch(updateMSMEStatus(data)),
  updateODA: (data) => dispatch(updateOdaStatus(data)),
  generateVPA: (data) => dispatch(generateVPA(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage);
