import React, { useState } from "react";
import { Field, FieldArray } from "redux-form";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import Downshift from "downshift";
import find from 'lodash/find';
import { required } from './../../../helpers/form-validations';
import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";
import Trash from "../../../assets/images/action/ic-delete.faded.svg";
import Form from "./../../UI/Form/Form";
import Button from './../../UI/Button/Button';
import "./AddUserModal.scss";


const FormRow = ({ children, label, required = false, style }) => (
  <div className={`flex addUserModal__input_grp`} style={{ ...style }}>
    <div>
      {label}
      {required && <sup>*</sup>}
    </div>
    {children}
  </div>
);

const FormRowWithoutLabel = ({ children, label, required }) => (
  <div className={`flex addUserModal__input_grp_no_label`}>
    {children}
  </div>
)


const renderField = ({ input, label, type, meta: { touched, error, warning }, disable = false }) => (
  <React.Fragment>
    <input {...input} placeholder={label} type={type} disabled={disable} />
    {touched && ((error && <span style={{ color: 'var(--fill-error' }}>{error}</span>) || (warning && <span>{warning}</span>))}
  </React.Fragment>
)



const ToggleField = (field) => {

  // <Toggle checked={field.input.value} onChange={field.input.onChange} icons={false} />

  const enabled = field.input.value;

  // const switchClasses = classnames(`switch switch--default`, className, "flex");

  // const togglerClasses = classnames("switch-toggle", `switch-toggle--${enabled ? "on" : "off"}`);

  const switchClasses = classnames(`switch switch--default switch--custom`, "flex");

  const togglerClasses = classnames("switch-toggle", `switch-toggle--${enabled ? "on" : "off"}`);
  return (
    <React.Fragment>
      <input type="checkbox" {...field.input} className="addUserModal__invisible_checkbox" />
      <div className={switchClasses} >
        <div className={togglerClasses} />
      </div>
    </React.Fragment>
  )
}

const AddUserModal = ({
  initialValues = {
    managers: [{}]
  },
  editMode,
  additionalProps = [],
  ...props
}) => {

  const required = value => value ? undefined : 'This field is required'

  const managersList = additionalProps.filter((manager) => !find(initialValues.managers, { managerId: manager.value }));

  const [availableManagers, setAvailableManagers] = useState(managersList);


  const handleChange = (id) => {
    const updatedList = availableManagers.filter(item => item.value != id)
    setAvailableManagers(updatedList);
  }

  const removeManager = id => {
    setAvailableManagers([...availableManagers, id])
  }

  // if user is in new user mode or is_enabled is not set,
  // then initialize is_enabled to true by default
  if (!editMode || initialValues.is_enabled == undefined) {
    initialValues.is_enabled = true;
  }

  if (initialValues.role === '2') {
    if (initialValues.is_superadmin === 1) {
      initialValues.role = '21'
    } else {
      initialValues.role = '20';
    }
  }

  return (
    <Form
      {...props}
      initialValues={initialValues ? initialValues : { managers: [{}] }}
      form={editMode ? "EditUser" : "AddUser"}
      className="addUserModal__scroll"
    >
      {({ changeFormValue, ...formProps }) => (
        <div className="addUserModal">
          <div className={editMode ? '' : 'hide'}>
            <FormRow label="User status(Active)" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Field name="is_enabled" component={ToggleField} type="text" />
            </FormRow>
          </div>
          <div className="fields-two">
            <FormRow label="First name" required>
              <Field name="first_name" component={renderField} validate={[required]} type="text" placeholder="Eg. John" />
            </FormRow>
            <FormRow label="Last name">
              <Field name="last_name" component={renderField} type="text" placeholder="Eg. Doe" />
            </FormRow>
          </div>
          <FormRow label="Email ID" required>
            <Field
              disabled={editMode}
              name="email"
              component={renderField}
              validate={[required]}
              type="email"
              placeholder="Eg. john.doe@company.com" />
          </FormRow>
          <FormRow label="Mobile Number">
            <Field name="phone" component={renderField} type="text" placeholder="Eg. 9834834728" />
          </FormRow>
          <FormRow label="User role" required style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="flex">
              <Field name="role" validate={[required]} component={RadioInput} type="radio" id="Super Admin" value="21" label="Super Admin" />
              <Field name="role" validate={[required]} component={RadioInput} type="radio" id="Admin" value="20" label="Admin" />
              <Field name="role" validate={[required]} component={RadioInput} type="radio" id="Manager" value="3" label="Manager" />
            </div>
          </FormRow>
          <FieldArray
            name="managers"
            allManagers={additionalProps}
            managersList={availableManagers}
            component={AccountManager}
            removeManager={removeManager}
            handleChange={handleChange}
          />
        </div>
      )}
    </Form>
  );
};

const RadioInput = ({ input, label, meta: { touched, error, warning }, ...props }) => (<div
  className="loco__radio loco__radio--left-align"
  style={{ border: "none", height: "30px", padding: "8px" }}
>
  <input
    {...input}
    {...props}
    name="role"
    type="radio"
  />
  <label for={label}>{label}</label>
</div>
)

const DownshiftSelect = ({ input, allManagers, options, handleChange, value }) => {
  let initialselectedItem = "";
  if (input.value) {
    initialselectedItem = find(allManagers, { value: input.value });
  }
  return (
    <Downshift
      itemToString={item => item.label}
      style={{ fontSize: "12px", fontWeight: 600 }}
      onChange={selection => {
        input.onChange(selection.value);
        handleChange(selection.value);
      }}
      initialSelectedItem={initialselectedItem}
    >

      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            className="downshift"
            type="none"
            style={{ width: '95%' }}
          >
            <span className="flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxWidth: "100%",
                  width: '100%',
                  fontWeight: '600',
                  color: 'var(--fill-locofast-black)'
                }}
                className="select-input-transparent"
                readOnly={true}
                disabled={true}
                {...getInputProps()}
                {...input}
                value={selectedItem && selectedItem.label}
                placeholder="Select"
              />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginTop: '4px' }}
              />
            </span>

            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <div>
                  <PerfectScrollbar>
                    {options
                      .map((item, index) => {
                        return (
                          <div
                            {...getItemProps({
                              className:
                                `in-selection collection-collab__label_left_aigned flex ${selectedItem == item.label ? "selected" : ""}`,
                              index,
                              item,
                              key: item.label
                            })}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  )
}

const AccountManager = ({ fields, meta: { error, submitFailed }, onChange, handleChange, managersList, removeManager, value, allManagers }) => {
  return (
    <div>
      <div
        style={{
          padding: '11px 0'
        }}
      >Account Manager</div>
      {
        fields.map((managers, index) => (
          <FormRowWithoutLabel key={index}>
            <div className="addUserModal__address_group">
              <div className="flex_row">
                <FormRowWithoutLabel>
                  <Field
                    name={`${managers}.managerId`}
                    component={DownshiftSelect}
                    options={managersList}
                    handleChange={handleChange}
                    allManagers={allManagers}
                  />
                </FormRowWithoutLabel>
                <img src={Trash} alt="" onClick={() => { fields.remove(index) }} />
              </div>
            </div>
          </FormRowWithoutLabel>
        ))
      }
      <div>
        <Button
          category="btn"
          type="button"
          className="addAddress"
          onClick={() => fields.push({})}
        >+ Add more</Button>
      </div>
    </div>
  )
}

export default AddUserModal;
