import style from './style.module.scss';

import React, { useEffect } from 'react';
import { getCurrencyRate, updateCurrency } from 'store/actions/UserActions';
import { connect } from 'react-redux';
import moment from 'moment';
import { DATE_TIME_RAW } from 'utilites/utils';

const CurrencyFactor = ({ openModal, getCurrencyRate, currencyRate, updateCurrency }) => {
  const { valueInInr: currencyValue, modifiedOn } = currencyRate || {};

  const handlClick = () => {
    openModal('CURRENCY_FACTOR', {
      initialValues: { currencyValue },
      updateCurrency,
      getCurrencyRate,
    });
  };

  useEffect(() => {
    getCurrencyRate();
  }, []);

  return (
    <div className={style.page}>
      <div className={style.head}>
        <div className={style.headline}>Currency factor</div>
        <button className="btn btn--blue-bg" onClick={handlClick}>
          Update Currency Factor
        </button>
      </div>
      <div className={style.container}>
        <p className={style.dollertext}>1 Dollar equals</p>
        {currencyValue && <p className={style.conversionRate}>{`${currencyValue} Rupees`}</p>}
        <p className={style.lastUpdate}>
          Last updated on
          <span className={style.dateFormate}>
            {` ${moment(modifiedOn).format(DATE_TIME_RAW)}`}
          </span>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencyRate: state?.user?.currencyConversionRate?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrencyRate: () => dispatch(getCurrencyRate()),
  updateCurrency: (data) => dispatch(updateCurrency(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyFactor);
