import React, { useMemo } from "react";
import { ReactComponent as ImgCheck } from "assets/images/navigation/ic-check-green.svg";
import "./styles.scss";

const ProgressSteps = ({ panels, activeIndex, handleClick = () => { } }) => {

  // hide panels which are set to false (isVisible)
  const filteredPanels = useMemo(() => panels.filter(panel => panel.isVisible !== false), [panels]);

  return (
    <div className="progress-steps">

      {/* Show header only if there are more than 1 panels */}
      {filteredPanels.length > 1 && <div className="bar__wrapper bar_top">
        {filteredPanels.map((panel, index) => (
          <div className={`bar__item ${activeIndex === index ? "active" : ""} ${panel.disabled ? 'disabled' : ''}`}>
            <div
              className="bar__label"
              onClick={() => {
                if (!panel.disabled) handleClick(index);
              }}
            >
              <span
                className={`step-num a ${
                  activeIndex > index ? "bar-active" : ""
                  }`}
              >
                {activeIndex > index ? (
                  <ImgCheck className="check" />
                ) : (
                    index + 1
                  )}
              </span>
              {panel.heading}
            </div>
          </div>
        ))}
      </div>}

      <div className="bar__content">
        {panels[activeIndex].content}
      </div>
    </div>
  );
};

export default ProgressSteps;
