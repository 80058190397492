import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { flash } from "./CommonActions";
import { closeModal, openModal } from "./GlobalActions";
import history from "../../utilites/history";
import isEqual from "lodash/isEqual";

import {
  EXCLUDE_MAPPED,
  USERS_LIST_FILTERS,
  ALL_LF_ROLES,
} from "constants/Filters";

const ep = get_service_endpoint("auth");
const userEp = get_service_endpoint("apiv2");

const LOGIN_SUCCESS = createAction("LOGIN_SUCCESS");
const SIGNUP_SUCCESS = createAction("SIGNUP_SUCCESS");
export const LOGOUT = createAction("LOGOUT");
const REFRESH_TOKEN_SUCCESS = createAction("REFRESH_TOKEN_SUCCESS");

const FORGOT_PASSWORD_RESET = createAction("FORGOT_PASSWORD_RESET");
const FORGOT_PASSWORD_REQUEST = createAction("FORGOT_PASSWORD_REQUEST");
const FORGOT_PASSWORD_SUCCESS = createAction("FORGOT_PASSWORD_SUCCESS");
const FORGOT_PASSWORD_FAILURE = createAction("FORGOT_PASSWORD_FAILURE");

const VALIDATE_CREATE_PASSWORD_TOKEN_REQUEST = createAction(
  "VALIDATE_CREATE_PASSWORD_TOKEN_REQUEST"
);
const VALIDATE_CREATE_PASSWORD_TOKEN_SUCCESS = createAction(
  "VALIDATE_CREATE_PASSWORD_TOKEN_SUCCESS"
);
const VALIDATE_CREATE_PASSWORD_TOKEN_FAILURE = createAction(
  "VALIDATE_CREATE_PASSWORD_TOKEN_FAILURE"
);

const CREATE_PASSWORD_REQUEST = createAction("CREATE_PASSWORD_REQUEST");
const CREATE_PASSWORD_SUCCESS = createAction("CREATE_PASSWORD_SUCCESS");
const CREATE_PASSWORD_FAILURE = createAction("CREATE_PASSWORD_FAILURE");

const GET_USER_LIST = createAction("GET_USER_LIST");

const CREATE_USER = createAction("CREATE_USER");
const UPDATE_USER = createAction("UPDATE_USER");
const DELETE_USER = createAction("DELETE_USER");

const UPDATE_LOGGED_IN_USER_DETAILS = createAction(
  "UPDATE_LOGGED_IN_USER_DETAILS"
);
const UPDATE_LOGGED_IN_USER_IMAGE = createAction("UPDATE_LOGGED_IN_USER_IMAGE");
const DELETE_LOGGED_IN_USER_IMAGE = createAction("DELETE_LOGGED_IN_USER_IMAGE");

const GET_LF_USERS = createAction("GET_LF_USERS");
const ADD_LF_USER = createAction("ADD_LF_USER");
const EDIT_LF_USER = createAction("EDIT_LF_USER");

export const signupSuperLocoUser = (creds) => (dispatch) => {
  // console.log(creds);
  return axios
    .post(`${ep}/locosuper`, {
      username: "superLoco",
      encpass: "locosuperuser098787289",
      phone: creds.phoneNumber,
      name: creds.fullName,
      email: creds.workEmail,
    })
    .then((res) => {
      console.log("SUPER_LOGIN");
    });
};

export const signupClientSuper = (creds) => (dispatch) => {
  const { name, web_url, gst_number, address, image } = creds;
  const lfRequest = {
    name,
    web_url,
    gst_number,
  };
  // console.log(creds);
  return axios
    .post(`${ep}/clientsuper`, lfRequest)
    .then((res) => {
      dispatch(SIGNUP_SUCCESS());
      dispatch(flash({ message: "Signup successfull" }));
      if (res.locofastRootId) {
        const FormData = new FormData();
        FormData.append("brandId", res.locofastRootId);
        FormData.append("brandAddress", address);
        FormData.append("brandLogo", image);
        axios
          .post(`${userEp}/brand/`, FormData)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            console.log(err);
            flash({
              message: "Could not upload logo",
            });
          });
      }
      return res.data;
    })
    .catch((err) => {
      dispatch(
        flash({
          message: "Could not create brand",
        })
      );
    });
  // .then(() => window.appHistory.push("/brand/settings/profile"));
};

export const login = (creds) => (dispatch) => {
  // if (creds.email === "superLoco") return superLogin(creds)(dispatch);
  return axios
    .post(`${ep}/login`, {
      email: creds.email,
      password: creds.password,
    })
    .then((res) => {
      dispatch(LOGIN_SUCCESS(res.data));
      dispatch(
        flash({
          message: "Login successful!",
        })
      );
      return res.data;
    })
    .then((data) => {
      if (data.name === null) {
        dispatch(openModal("FIRST_LOGIN"));
      }
    })
    .catch((err) => {
      dispatch(
        flash({
          message: "Please enter correct email and/or password.",
        })
      );
    });
};

export const signup = (creds) => (dispatch) => {
  // console.log(creds);
  return axios
    .post(`${ep}/signup`, { email: creds })
    .then(() =>
      dispatch(
        flash({
          message: `Successfully signed up ${creds}`,
        })
      )
    )
    .catch((err) => {
      return Promise.reject();
    });
};

export const inviteUsers = (formVals) => (dispatch) => {
  formVals["noClose"] = formVals["noClose"] === false ? false : true;
  formVals.emails = formVals.emails ? formVals.emails : [];
  return axios
    .all(formVals.emails.map((cred) => dispatch(signup(cred))))
    .then(() =>
      dispatch(
        flash({
          message: "Invited all users successfully !",
        })
      )
    )
    .then(() => {
      dispatch(SIGNUP_SUCCESS());
      if (formVals["noClose"]) dispatch(closeModal());
      dispatch(getUsers());
    })

    .catch(() => {
      if (formVals["noClose"]) dispatch(closeModal());
      dispatch(getUsers());
      dispatch(
        flash({
          message: "There were some errors with all/some invites.",
          duration: 2500,
        })
      );
    });
};

export const resetForgotPassword = () => (dispatch) => {
  dispatch(FORGOT_PASSWORD_RESET());
};

export const forgotPassword =
  ({ email }) =>
  (dispatch) => {
    dispatch(FORGOT_PASSWORD_REQUEST({ email }));

    return axios
      .post(`${ep}/forgotPasswordEmail`, {
        email,
      })
      .then((res) => {
        dispatch(FORGOT_PASSWORD_SUCCESS());
      })
      .catch((res) => {
        const error =
          (res.data && res.data.error && res.data.error.message) ||
          "Error occurred while validating the link. Please try again later.";
        dispatch(FORGOT_PASSWORD_FAILURE(error));
      });
  };

export const validateCreatePasswordToken =
  ({ token }) =>
  (dispatch) => {
    dispatch(VALIDATE_CREATE_PASSWORD_TOKEN_REQUEST({ token }));

    return axios
      .get(`${ep}/validateToken`, {
        params: { token },
      })
      .then((res) => {
        const { name, email, brandId } = res.data;
        dispatch(
          VALIDATE_CREATE_PASSWORD_TOKEN_SUCCESS({ name, email, brandId })
        );
      })
      .catch((res) => {
        const error =
          (res.data && res.data.error && res.data.error.message) ||
          "Error occurred while validating the link. Please try again later.";
        dispatch(VALIDATE_CREATE_PASSWORD_TOKEN_FAILURE(error));
      });
  };

export const createPassword =
  ({ token, password, confirmPassword }) =>
  (dispatch) => {
    dispatch(CREATE_PASSWORD_REQUEST());

    return axios
      .put(`${ep}/password`, {
        token,
        password,
        confirmPassword,
      })
      .then((res) => {
        dispatch(CREATE_PASSWORD_SUCCESS());
      })
      .catch((res) => {
        const error =
          (res.data && res.data.error && res.data.error.message) ||
          "Error occurred while creating the password. Please try again later.";
        dispatch(CREATE_PASSWORD_FAILURE(error));
      });
  };

export const changePassword =
  ({ oldPassword, newPassword, confirmPassword }) =>
  (dispatch) => {
    return axios
      .post(`${ep}/resetPassword`, {
        currentPassword: oldPassword,
        newPassword,
        confirmPassword,
      })
      .then(() => {
        dispatch(
          UPDATE_LOGGED_IN_USER_DETAILS({
            password_updated_on: new Date().toString(),
          })
        );
        dispatch(
          flash({
            message: "Successfully updated user password.",
          })
        );
      })
      .then(() => dispatch(closeModal()));
  };

export const updateUserPhone =
  ({ phone }) =>
  (dispatch) => {
    return axios
      .put(`${ep}/user/phone`, {
        phone,
      })
      .then((res) => {
        dispatch(closeModal());
        dispatch(UPDATE_LOGGED_IN_USER_DETAILS({ phone: phone }));
        dispatch(
          flash({
            message: "Successfully updated user phone.",
          })
        );
      });
  };

export const getUsers = (userDetails) => (dispatch) => {
  return axios
    .get(`${ep}/users`, { params: userDetails })
    .then((res) => res.data)
    .then((data) => {
      dispatch(GET_USER_LIST({ list: data }));
    });
};

export const logout = () => (dispatch) => {
  return axios
    .get(`${ep}/logout`)
    .then((res) => dispatch(LOGOUT()))
    .then(() => {
      history.push("/auth/login");
    })
    .catch((resp) => {
      if (resp.status === 401) {
        history.push("/auth/login");
      }
    });
};

// export const deleteUser = creds => dispatch => {
//   return axios
//     .delete(`${ep}/deleteuser`, { data: creds })
//     .then(res => dispatch(DELETE_USER()))
//     .then(res => dispatch(getUsers()))
//     .then(() => dispatch(flash({ message: "User deleted successfully." })));
// };

// updateUserDetails updates the signed in user's info
export const updateUserDetails = (userDetails) => (dispatch) => {
  // console.log(userDetails);
  return axios
    .put(`${ep}/user/namephone`, {
      id: userDetails.id,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      email: userDetails.email,
      phone: userDetails.phone,
    })
    .then((res) =>
      dispatch(
        UPDATE_LOGGED_IN_USER_DETAILS({
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          name:
            userDetails.first_name +
            (userDetails.last_name ? ` ${userDetails.last_name}` : ""),
          phone: userDetails.phone,
        })
      )
    )
    .then(() =>
      dispatch(
        flash({
          message: "Successfully updated user details.",
        })
      )
    )
    .then(() => dispatch(closeModal()));
};

export const updateUserImage = (userDetails) => (dispatch) => {
  const imageFile = userDetails.profileimage;
  const data = new FormData();
  data.append("profileimage", imageFile);

  return axios
    .post(`${ep}/userImage`, data)
    .then((res) => {
      const { image } = res.data.resp.msgResp;
      dispatch(
        UPDATE_LOGGED_IN_USER_IMAGE({
          image,
        })
      );
    })
    .then(() =>
      dispatch(
        flash({
          message: "Profile Image updated",
        })
      )
    );
};

export const deleteUserImage = () => (dispatch) => {
  return axios
    .delete(`${ep}/userImage`)
    .then((res) => {
      dispatch(DELETE_LOGGED_IN_USER_IMAGE());
    })
    .then(() =>
      dispatch(
        flash({
          message: "Profile Image Removed",
        })
      )
    );
};

export const getLocofastUsers =
  (userRoles, params = {}) =>
  (dispatch) => {
    let roles = userRoles;
    if (isEqual(roles, [ALL_LF_ROLES])) {
      // "0" denotes all(Admin., Fabric suplier, BDE)
      roles = USERS_LIST_FILTERS.roles;
    }
    return axios
      .get(`${userEp}/user/lfusers`, {
        params: { roles: JSON.stringify(roles), ...params },
      })
      .then((res) => {
        if (params[EXCLUDE_MAPPED]) {
          return res.data;
        } else {
          dispatch(GET_LF_USERS(res.data));
        }
        return res.data;
      });
  };

export const signupLfUser = (userDetails) => (dispatch) => {
  const userData = {
    ...userDetails,
    first_name: userDetails.firstName,
    last_name: userDetails.lastName,
    role: userDetails.role.toString(),
  };

  delete userDetails.firstName;
  delete userDetails.lastName;

  if (userData.maxActiveQuery) {
    userData.max_active_query = userData.maxActiveQuery;
    delete userData.maxActiveQuery;
  }
  return axios
    .post(`${ep}/signup`, userData)
    .then((res) => res.data)
    .then((result) => {
      dispatch(
        flash({
          message: `New user added`,
        })
      );
      dispatch(
        ADD_LF_USER({
          id: result,
          ...userData,
          name: `${userData.firstName} ${userData.lastName || ""}`,
        })
      );
      dispatch(closeModal());
      return result;
    })
    .catch((err) => {
      return Promise.reject();
    });
};

export const updateLfUser = (userDetails) => (dispatch) => {
  return axios
    .patch(`${userEp}/user/lfusers`, userDetails)
    .then((res) => res.data)
    .then((result) => {
      dispatch(
        flash({
          message: `User updated`,
        })
      );
      const { email, name, phone, role, id } = result;
      dispatch(EDIT_LF_USER({ email, name, phone, role, id }));
      dispatch(closeModal());
      return result;
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

export const linkZohoToUser = (details) => (dispatch) => {
  return axios
    .post(`${userEp}/users/zohodetails`, details, {
      params: { isLoader: true },
    })
    .then((res) => res.data)
    .then((result) => result)
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

export const updateLinkZohoToUser = (details) => (dispatch) => {
  return axios
    .patch(`${userEp}/users/zohodetails`, details, {
      params: { isLoader: true },
    })
    .then((res) => res.data)
    .then((result) => result)
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

export const createUser = (user) => (dispatch) => {
  const request = {
    first_name: user.first_name,
    last_name: user.last_name || "",
    phone: user.phone,
    email: user.email,
    role: user.role,
    is_enabled: "" + +(user.is_enabled === undefined ? true : user.is_enabled),
  };
  return axios
    .post(`${ep}/signup`, request)
    .then((res) =>
      dispatch(
        CREATE_USER({
          ...request,
          id: res.data,
          name: `${request.first_name} ${request.last_name}`,
        })
      )
    )
    .then(() =>
      dispatch(
        flash({
          message: `Successfully added a new user "${user.first_name}".`,
        })
      )
    )
    .then(() => dispatch(closeModal()));
};

export const updateUser = (user) => (dispatch) => {
  const { isLfUser, firstName, lastName, maxActiveQuery, ...details } = user;
  return axios
    .put(`${ep}/users/${user.id}`, {
      ...details,
      role: `${details.role}`,
      is_enabled: "" + (+user.is_enabled || 1),
    })
    .then((res) => {
      if (isLfUser) {
        const {
          email,
          phone,
          role,
          id,
        } = details;
        dispatch(
          EDIT_LF_USER({
            email,
            firstName,
            lastName,
            name: `${firstName}${lastName ? ` ${lastName}` : ""}`,
            phone,
            role,
            id,
            maxActiveQuery,
          })
        );
      } else {
        dispatch(UPDATE_USER(user));
      }
    })
    .then(() =>
      dispatch(
        flash({
          message: `Successfully updated user "${user.first_name}".`,
        })
      )
    )
    .then(() => dispatch(closeModal()));
};

// Refreshes the authentication token
export const refreshToken = () => (dispatch) => {
  return axios
    .get(`${ep}/refreshtoken`)
    .then((res) => dispatch(REFRESH_TOKEN_SUCCESS(res.data)))
    .then(() => dispatch(closeModal()));
};
