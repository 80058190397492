import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "ServiceEndpoints";
import { flash } from "../actions/CommonActions";
const GET_LIVE_RATES_DATA = createAction("GET_LIVE_RATES_DATA");
const GET_DISABLED_LIVE_RATES_DATA = createAction("GET_DISABLED_LIVE_RATES_DATA");

const liveRateQualities = get_service_endpoint("liveRateQualities");

export const getLiveRatesData = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`${liveRateQualities}`, { params });
    if (params.isDisabled) {
      dispatch(GET_DISABLED_LIVE_RATES_DATA(res.data.data));
    } else {
      dispatch(GET_LIVE_RATES_DATA(res.data.data));
    }
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      })
    );
  }
};

export const createNewLiveQuality = (requestData) => async (dispatch) => {
  try {
    const res = await axios.post(`${liveRateQualities}`, requestData);
    dispatch(flash({ message: res.data.message }));
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      })
    );
  }
};

export const updateLiveQuality = (requestData) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${liveRateQualities}`,
      requestData
    );
    dispatch(flash({ message: res.data.message }));
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      })
    );
  }
};
